import { useTranslation } from 'react-i18next';
import { ICondition } from '../../models/condition';
import AppTable from '../AppTable/AppTable';

interface MultiPriceConditionInfoProps {
  conditions: ICondition[];
}

const MultiPriceConditionInfo: React.FC<MultiPriceConditionInfoProps> = props => {
  const { t } = useTranslation('price-condition');

  const { conditions } = props;

  const toNumber = (str: unknown) => (new Number(str))

  return (
    <div className="multi-price-condition__info">
      {conditions && (
        <AppTable
          rows={conditions.map(c => ({
            ...c,
            id: String(c.id),
            upe: toNumber(c.upe).toFixed(2),
            ekp: toNumber(c.ekp).toFixed(2) + ' €',
            discount_percentage: toNumber(c.discount_percentage).toFixed(2) + ' %',
            rest: toNumber(c.rest).toFixed(2) + ' €',
            milage_duration: c.milage + ' km' + '/' + c.duration + ' ' + t('months'),
            km_peryear: Math.round((c.milage * 12) / c.duration) + ' ' + t('year'),
          }))}
          headers={[
            {
              key: 'upe',
              header: t('upe'),
            },
            {
              key: 'ekp',
              header: t('ekp'),
            },
            {
              key: 'discount_percentage',
              header: t('discount_percentage'),
            },
            {
              key: 'rest',
              header: t('rest'),
            },
            {
              key: 'milage_duration',
              header: t('milage/duration'),
            },
            {
              key: 'km_peryear',
              header: t('km_per_year'),
            },
          ]}
          size="normal"
        />
      )}
    </div>
  );
};

export default MultiPriceConditionInfo;
