import './PickUpReturnAppointment.scss';
import { DatePicker, DatePickerInput, Dropdown } from 'carbon-components-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDateTime } from '../vehicleAppointment/VehicleAppointment';
import { ICustomer } from '../../../../common/models/purchase';
import { ITimeSlot } from '../../../../common/models/dealer';

interface IPickUpREturnAppointmentProps {
  customer: ICustomer;
  location: string;
  date: string;
  time: string | undefined;
  action: 'pick-up' | 'return';
  timeSlots: ITimeSlot[];
  estimatedDate: Date | null;
  onUpdateDateTime: (dateTime: IDateTime) => void;
  updatePrimaryButtonDisabled?: (disabled: boolean) => void;
}

interface IParsedTimeSlot {
  id: string;
  label: string;
  disabled: boolean;
}

const PickUpReturnAppointment: React.FC<IPickUpREturnAppointmentProps> = props => {
  const { t } = useTranslation('vehicle-appointments');
  const { customer, location, date, time, action, estimatedDate, timeSlots, onUpdateDateTime } = props;
  const pickerLabel = action === 'pick-up' ? t('selected_pickup_date') : t('selected_return_date');
  const timeLabel = action === 'pick-up' ? t('pickup_period') : t('return_period');

  const [selectedDate, setDate] = useState<string>(date);
  const [rawDate, setRawDate] = useState<Date | null>(estimatedDate);

  const generateTimeSlots = (): string[] => {
    const formatMinutes = (minutes: number): string => {
      if (minutes < 10) {
        return `0${minutes}`;
      }

      return minutes.toString();
    };
    const toTimeString = (value: number): string => {
      const hours = value / 60;
      const intHours = Math.floor(hours);
      const minutes = (hours - intHours) * 60;
      const intMinutes = Math.round(minutes);
      return `${formatMinutes(intHours)}:${formatMinutes(intMinutes)}`;
    };

    const getDay = (): number => {
      const day = rawDate ? rawDate.getDay() : -1;

      if (day === -1) return 0;

      if (day === 0) return 6; //replace Sunday from zero with 6

      return day - 1;
    };

    return timeSlots
      .filter(ts => ts.active && ts.dayOfWeek === getDay())
      .map(ts => {
        const fromParts = ts.from.split(':');
        const untilParts = ts.until.split(':');

        if (fromParts.length < 2 || untilParts.length < 2) {
          return { from: 0, until: 0 };
        }

        return {
          from: parseInt(fromParts[0]) * 60 + parseInt(fromParts[1]),
          until: parseInt(untilParts[0]) * 60 + parseInt(untilParts[1]),
        };
      })
      .flatMap(ts => {
        const values = [];
        const step = 30; //30 minutes
        for (let i = ts.from; i <= ts.until; i += step) {
          values.push(i);
        }

        return values.map(toTimeString);
      })
      .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
  };

  const parsedTimeSlots = generateTimeSlots().map(ts => ({ id: `time-${ts.replace(':', '-')}`, label: ts, disabled: false }));

  const getTimeOnLoad = () => {
    const selected = parsedTimeSlots.find(t => t.label === time);

    if (selected) {
      return selected;
    }

    const slot = parsedTimeSlots.find(t => !t.disabled);
    return slot;
  };

  const [selectedTime, setTime] = useState<IParsedTimeSlot | undefined>(getTimeOnLoad());

  const onTimeChange = (data: IParsedTimeSlot | null | undefined) => {
    if (data) {
      setTime(data);
      onUpdateDateTime({ date: selectedDate, time: data ? data.label : undefined });
    }
  };

  const onDateChange = (dates: Date[], currentDateString: string) => {
    setDate(currentDateString);
    setRawDate(dates[0]);
    onUpdateDateTime({ date: currentDateString, time: selectedTime ? selectedTime.label : undefined });
  };

  return (
    <div className="pick-up-appointment">
      <div className="pick-up-appointment__subtitle fp--label-01">{action === 'pick-up' ? t('confirm_handover_date') : t('confirm_return_date')}</div>
      <div className="appointment--info">
        <div className="client--label">{t('customer')}</div>
        <div className="client--name">{customer.contactName}</div>

        <div className="client--label">{t('phone')}</div>
        <div className="client--name">{customer.phone}</div>

        <div className="location--label">{t('pickup_location')}</div>
        <div className="location--name">{location}</div>
      </div>
      <div className="appointment pick-up__appointment">
        <div className={'appointment--date'}>
          <div className="appointment--date__info fp--body-short-01 ">
            <DatePicker dateFormat="d.m.Y" datePickerType="single" onChange={(d, c) => onDateChange(d, c)}>
              <DatePickerInput id="pick-up-date-picker" placeholder="dd.mm.yyyy" labelText={pickerLabel} type="text" value={selectedDate} />
            </DatePicker>
          </div>
        </div>
        <div className={'appointment--time'}>
          <div className="appointment--time__info fp--body-short-01 ">
            {parsedTimeSlots.length > 0 ? (
              <Dropdown
                ariaLabel="Dropdown"
                id="carbon-dropdown-example"
                items={parsedTimeSlots}
                label=""
                titleText={timeLabel}
                selectedItem={selectedTime}
                onChange={v => onTimeChange(v.selectedItem)}
              />
            ) : (
              <div>
                <label className="bx--label">{timeLabel}</label>
                <div>{t('branch_closed')}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickUpReturnAppointment;
