import { useTranslation } from 'react-i18next';
import ReleaseItems from '../../common/components/ReleaseItems/ReleaseItems';
import { IMarketplaceVehicle, IOfferVehicle, IVehicleListItem, VehiclesStatus } from '../../common/models/vehicles';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { enableHideTemplateAnimation, filterVehiclesData, getVehiclesState, IVehicleFilterParams } from '../../redux/reducers/VehiclesSlice';
import { IVehiclesDetailsProps } from '../vehicleDetails/VehicleDetails';
import { OverlayPages } from '../../common/components/Overlay/overlayPages';
import { show } from '../../redux/reducers/OverlaySlice';
import './Release.scss';
import { useEffect, useState } from 'react';
import AppModal from '../../common/components/AppModal/AppModal';
import ReleaseVehicleTemplate from '../vehicles/components/ReleaseVehicleTemplate/ReleaseVehicleTemplate';
import { getDealerInfo } from '../../redux/reducers/AuthenticationSlice';
import { releaseVehicleTemplate } from '../../redux/reducers/ReleaseVehicleTemplateSlice';
import MarketplaceItems from '../../common/components/MarketplaceItems/MarketplaceItems';
import AppButton from '../../common/components/AppButton/AppButton';
import EnvUtil from '../../utils/EnvUtil';
import { IRefresh } from '../../utils/RefreshUtil';

export interface IReleaseModalProps {
  isOpen: boolean;
  title: string;
  idTemplate: number;
}

const emptyReleaseModal: IReleaseModalProps = {
  isOpen: false,
  title: '',
  idTemplate: 0,
};

const Release: React.FC = () => {
  const { t } = useTranslation('vehicles');
  const dealer = useAppSelector(getDealerInfo);
  const state = useAppSelector(getVehiclesState);
  const dispatch = useAppDispatch();
  const { vehicles } = state;
  const refresh: IRefresh = {
    intervalId: undefined,
    time: (EnvUtil.get('REFRESH_INTERVAL') as unknown as number) || 0,
  };

  const [releaseModalPros, showReleaseModal] = useState<IReleaseModalProps>(emptyReleaseModal);

  useEffect(() => {
    const params: IVehicleFilterParams = {
      filter: {},
      statusList: [VehiclesStatus.MARKETPLACE, VehiclesStatus.RELEASE],
    };
    dispatch(filterVehiclesData(params)).catch(console.error);

    if (refresh.time > 0 && refresh.intervalId === undefined) {
      refresh.intervalId = setInterval(() => {
        dispatch(filterVehiclesData(params)).catch(console.error);
      }, refresh.time);
    }

    return () => {
      if (refresh.time > 0 && refresh.intervalId !== undefined) {
        clearInterval(refresh.intervalId as unknown as number);
      }
    };
  }, []);

  const onReleaseVehicleTemplate = (idVehicleTemplate: number) => {
    const idDealer = dealer !== null ? dealer.id : 0;

    dispatch(releaseVehicleTemplate({ idVehicleTemplate, idDealer })).catch(console.error);
    showReleaseModal(emptyReleaseModal);
    dispatch(enableHideTemplateAnimation({ idVehicleTemplate }));
  };

  const onShowDetails = (status: string, item: IVehicleListItem, idVehicle: number) => {
   
    const contentProps: IVehiclesDetailsProps = { idVehicleTemplate: item.idTemplate, selectedTab: 'details' };

    if (status !== 'release') {
      contentProps.idSubscription = (item as IMarketplaceVehicle).idSubscription;
    }

    if (status !== 'release' && status !== 'marketplace') {
      contentProps.idPurchase = (item as IOfferVehicle).idPurchase;
    }
    if (idVehicle !== 0) {
      contentProps.idVehicle = idVehicle;
    }

    const headline = `${item.manufacturer} ${item.model} ${item.variant}`;

    dispatch(show({ headline, content: OverlayPages.VEHICLE_DETAILS, contentProps }));
  };

  const renderModals = () => {
    return (
      <AppModal
        open={releaseModalPros.isOpen}
        className={'isSuperregional'}
        onRequestClose={() => showReleaseModal(emptyReleaseModal)}
        onRequestSubmit={() => onReleaseVehicleTemplate(releaseModalPros.idTemplate)}
        primaryButtonText={t('release_vehicle')}
        secondaryButtonText={t('abort')}
        modalHeading={releaseModalPros.title}
        showSecondaryButton={true}
        size="sm"
      >
        {releaseModalPros.isOpen && <ReleaseVehicleTemplate idVehicleTemplate={releaseModalPros.idTemplate} />}
      </AppModal>
    );
  };

  const renderReleaseItems = () => {
    return (
      <div className="release-page--items">
        <div className="release-page--items__title fp--productive-heading-02">{t('models')}</div>

        <ReleaseItems
          status={state.status}
          items={vehicles.release
            .filter(it => it.variant && it.manufacturer && it.model)
            .filter(vehicleTemplate => {
              let inMarketplace = false;
              dealer?.marketplaces?.map(m => {
                m.manufacturers?.map(mm => {
                  if (String(mm.name).indexOf(vehicleTemplate.manufacturer) !== -1) {
                    inMarketplace = true;
                  }
                });
              });
              return inMarketplace;
            })
            .map(item => ({
              button: (
                <AppButton
                  kind="secondary"
                  onClick={() => showReleaseModal({ isOpen: true, title: `${item.manufacturer} ${item.model} ${item.variant}`, idTemplate: item.idTemplate })}
                >
                  {t('for_release')}
                </AppButton>
              ),
              item: item,
              animation: item.animation,
              onShowDetails: () => onShowDetails('release', item, 0),
            }))}
        ></ReleaseItems>
      </div>
    );
  };


  const renderMarketPlace = () => {
    return (
      <div className="release-page--market">
        <div className="release-page--market__title fp--productive-heading-02">{t('marketplace')}</div>
        <MarketplaceItems
          status={state.status}
          items={vehicles.marketplace
            .filter(v => v.details.active)
            .map(item => ({
              button: (
                <AppButton
                  kind="secondary"
                  onClick={() => showReleaseModal({ isOpen: true, title: `${item.manufacturer} ${item.model} ${item.variant}`, idTemplate: item.idTemplate })}
                >
                  {t('for_release')}
                </AppButton>
              ),
              item: item,
              onShowDetails: () => onShowDetails('marketplace', item, 0),
            }))}
        ></MarketplaceItems>
      </div>
    );
  };

  return (
    <div className="release-page container">
      <div className="release-page--description">
        <div className="release-page--description__title fp--productive-heading-03">{t('release')}</div>
        <div className="release-page--description__text fp--body-long-01">{t('selecting_models_car_subscription')}</div>
      </div>

      <div className="release-page--container ">
        {renderReleaseItems()}
        {renderMarketPlace()}
      </div>

      {renderModals()}
    </div>
  );
};

export default Release;
