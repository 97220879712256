import CalendarVehiclesBody from './components/CalendarVehiclesBody';
import CalendarVehiclesFilter from './components/CalendarVehiclesFilter';
import { useAppDispatch } from '../../redux/hooks';
import { filterVehiclesData, IVehicleFilterParams } from '../../redux/reducers/VehiclesSlice';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppModal from '../../common/components/AppModal/AppModal';
import { acceptOffer } from '../../redux/reducers/AcceptOfferSlice';
import { VehiclesStatus } from '../../common/models/vehicles';
import './CalendarVehicles.scss';
import CalendarVehiclesFilterByDate from './components/CalendarVehiclesFilterByDate';

export interface ITranslatedHeadlines {
  offers: string;
  handovers: string;
  atCustomer: string;
  return: string;
  completed: string;
}

export interface IAcceptOfferModalProps {
  isOpen: boolean;
  title: string;
  idPurchase: number;
}

const emptyAcceptOfferModal: IAcceptOfferModalProps = {
  isOpen: false,
  title: '',
  idPurchase: 0,
};

const CalendarVehicles: React.FC = () => {
  const { t } = useTranslation('calendar-vehicles');
  const dispatch = useAppDispatch();
  const translatedHeadlines: ITranslatedHeadlines = {
    offers: t('inquiry'),
    handovers: t('pickup'),
    atCustomer: t('at_the_customer'),
    return: t('return'),
    completed: t('at_the_dealer'),
  };

  const filterStatusItems = [t('show_all'), t('inquiry'), t('pickup'), t('return')];

  const [acceptOfferModalProps, showAcceptOfferModal] = useState<IAcceptOfferModalProps>(emptyAcceptOfferModal);

  useEffect(() => {
    const params: IVehicleFilterParams = {
      filter: {},
      statusList: [VehiclesStatus.OFFER, VehiclesStatus.HANDOVER, VehiclesStatus.RETURN],
    };

    dispatch(filterVehiclesData(params)).catch(console.error);
  }, []);

  const onAcceptOffer = (idPurchase: number, status: 'accepted' | 'declined', reason: string | null) => {
    dispatch(acceptOffer({ idPurchase, status, reason })).catch(console.error);
    showAcceptOfferModal(emptyAcceptOfferModal);
  };

  const renderModals = () => {
    return (
      <AppModal
        open={acceptOfferModalProps.isOpen}
        className={'isSuperregional'}
        onRequestClose={() => showAcceptOfferModal(emptyAcceptOfferModal)}
        onRequestSubmit={() => onAcceptOffer(acceptOfferModalProps.idPurchase, 'accepted', null)}
        onSecondarySubmit={() => onAcceptOffer(acceptOfferModalProps.idPurchase, 'declined', null)}
        primaryButtonText={t('confirm_offer')}
        secondaryButtonText={t('reject_offer')}
        modalHeading={acceptOfferModalProps.title}
        showSecondaryButton={true}
      ></AppModal>
    );
  };

  const renderCalendarVehiclesFilter = () => {
    return <CalendarVehiclesFilter filterStatusItems={filterStatusItems} />;
  };
  const renderCalendarVehiclesFilterbyDate = () => {
    return <CalendarVehiclesFilterByDate />;
  };

  const renderCalendarVehicles = () => {
    return <CalendarVehiclesBody translatedHeadlines={translatedHeadlines} />;
  };

  return (
    <div className="vehciles-calendar component-wrapper">
      {renderModals()}
      <div className="filters-wrapper">
        {renderCalendarVehiclesFilterbyDate()} {renderCalendarVehiclesFilter()}
      </div>
      {renderCalendarVehicles()}
    </div>
  );
};

export default CalendarVehicles;
