import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { getVehiclesState } from '../../../redux/reducers/VehiclesSlice';
import './ProcessBarItem.scss';

export interface IProcessBarItem {
  status: string;
  statusName?: string;
  notifications?: number;
  disabled?: boolean;
}

const ProcessBarItem: React.FC<IProcessBarItem> = (props: IProcessBarItem) => {
  const { status, statusName, notifications, disabled } = props;

  const [scroll, setScroll] = useState(false);
  const [isFirstElement, setFirstElement] = useState(false);
  const vehiclesState = useAppSelector(getVehiclesState);
  const elementDivTime = document.getElementById(status);

  useEffect(() => {
    const elementDiv = document.getElementById(status);
    const firstAnchor = document.querySelector('.anchor');
    if (firstAnchor && !scroll && !firstAnchor.classList.contains('disabled')) {
      firstAnchor.className = 'anchor first';
    }
    let elementTopPosition = 0;
    let elementHeight = 0;
    const header = document.querySelector('.bx--header');
    let headerHeight = 0;
    const progressbar = document.querySelector('.vehicle-list-header');
    const processbarContainer = document.querySelector('.vehicle-list-header-container');
    let progressbarHeight = 0;
    let progressbarTop = 0;
    let elementMarginTop = 0;
    if (elementDiv && header && progressbar && firstAnchor && processbarContainer) {
      const anchorStyle = getComputedStyle(firstAnchor);
      elementMarginTop = parseInt(anchorStyle.height);
      elementTopPosition = window.scrollY + elementDiv.getBoundingClientRect().top;
      elementHeight = elementDiv.offsetHeight;
      headerHeight = header.clientHeight;
      progressbarHeight = progressbar.clientHeight;
      progressbarTop = window.scrollY + processbarContainer.getBoundingClientRect().top;
    }
    const fixedElementsHeight = headerHeight + progressbarHeight;
    const elementOnTop = elementTopPosition - fixedElementsHeight - 1;
    const elementWasScrolled = elementHeight + elementOnTop;
    const handleScroll = () => {
      setFirstElement(elementOnTop < progressbarTop && window.scrollY < progressbarTop && !disabled);
      if (!disabled) {
        setScroll(window.scrollY > elementOnTop - elementMarginTop && window.scrollY < elementWasScrolled && !disabled);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [elementDivTime && vehiclesState]);

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    const firstAnchor = document.querySelector('.first');
    if (firstAnchor !== null) {
      firstAnchor.className = 'anchor inactive';
    }
    if (firstAnchor) {
      firstAnchor.className = 'anchor';
    }
    const elementDiv = document.getElementById(status);
    let progressbarHeight = 0;
    const header = document.querySelector('.bx--header');
    let headerHeight = 0;
    const progressbar = document.querySelector('.vehicle-list-header');
    let elementTopPosition = 0;
    if (elementDiv && header && progressbar) {
      elementTopPosition = window.scrollY + elementDiv.getBoundingClientRect().top;
      headerHeight = header.clientHeight;
      progressbarHeight = progressbar.clientHeight;
    }
    const fixedElementsHeight = headerHeight + progressbarHeight;
    const elementOnTop = elementTopPosition - fixedElementsHeight;
    e.preventDefault();
    if (!disabled) {
      window.scrollTo({ behavior: 'smooth', top: elementOnTop });
    }
  };

  return (
    <li className="process-bar-item">
      <a
        href={`#${status}`}
        onClick={handleClick}
        title={statusName}
        className={`anchor  
        ${scroll ? 'active' : 'inactive'} 
        ${disabled ? 'disabled' : ''} 
        ${isFirstElement ? 'first' : ''}`}
      >
        {statusName}
        {notifications ? (
          <span>
            ({notifications})<sup>&#9679;</sup>
          </span>
        ) : (
          ''
        )}
      </a>
    </li>
  );
};

export default ProcessBarItem;
