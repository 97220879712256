import { ICondition } from '../../common/models/condition';
import IDataConverter from '../../common/models/IDataConverter';

export interface IRawCondition {
  id: number;
  erpId: string;
  milage: number;
  upe: number;
  ekp: number;
  rest: number;
  discount_percentage: number;
  duration: number;
}

class ConditionConverter implements IDataConverter<IRawCondition, ICondition> {
  convert(from: IRawCondition): ICondition {
    return {
      id: from.id || 0,
      erpId: from.erpId || '',
      milage: Number(from.milage) || 0,
      upe: from.upe || 0,
      ekp: from.ekp || 0,
      rest: from.rest || 0,
      discount_percentage: from.discount_percentage || 0,
      duration: Number(from.duration) || 0,
    };
  }
}

export default ConditionConverter;
