import { Tab, TabProps } from 'carbon-components-react';
import './AppTab.scss';

type AppTabProps = TabProps & {
  notifications?: number;
};

export const AppTab: React.FC<AppTabProps> = (props: AppTabProps) => {
  const { notifications, ...rest } = props;
  return (
    <Tab
      {...rest}
      className="app-tab"
      label={
        <span>
          {rest.label}
          {notifications && (
            <span className="tabs-notification">
              ({notifications})<sup>&#9679;</sup>
            </span>
          )}
        </span>
      }
    />
  );
};

export default AppTab;
