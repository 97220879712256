import { Dropdown, OnChangeData } from 'carbon-components-react';
import './FilterByStatus.scss';

export interface IFilterByStatus {
  filterItems: string[];
  onFilterChange: (selectedStatus: unknown) => void;
  label: string;
}

const FilterByStatus: React.FC<IFilterByStatus> = (props: IFilterByStatus) => {
  const { filterItems, onFilterChange, label } = props;
  const onChange = (selectedFilter: OnChangeData<string>) => {
    onFilterChange(selectedFilter.selectedItem);
  };
  return (
    <div className="app-filter">
      <Dropdown
        id=""
        titleText=""
        label={label}
        items={filterItems}
        itemToString={item => (item ? item : '')}
        onChange={e => {
          onChange(e);
        }}
      />
    </div>
  );
};

export default FilterByStatus;
