import './DealerTimeSlots.scss';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox16, CheckboxCheckedFilled16 } from '@carbon/icons-react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { addTimeSlot, deleteTimeSlot, getTimeSlotState } from '../../../redux/reducers/TimeSlotsSlice';
import { DataStatus } from '../../models/dataStatus';
import { IMarketplace, ITimeSlot } from '../../models/dealer';
import { Loading } from 'carbon-components-react';

type ITimeSlotData = {
  checked: boolean;
  timeSlot: ITimeSlot | undefined;
};

type IDealerTimeSlots = {
  idDealer: number;
  marketplaces: IMarketplace[];
};

const DealerTimeSlots: React.FC<IDealerTimeSlots> = (props: IDealerTimeSlots) => {
  const { t } = useTranslation('account-settings');
  const { idDealer, marketplaces } = props;

  const state = useAppSelector(getTimeSlotState);
  const { status, timeSlots } = state;

  const dispatch = useAppDispatch();
  const initActions = [
    { morning: false, afternoon: false },
    { morning: false, afternoon: false },
    { morning: false, afternoon: false },
    { morning: false, afternoon: false },
    { morning: false, afternoon: false },
    { morning: false, afternoon: false },
  ];
  const [actions, setActions] = useState(initActions);

  const emptySlot: { checked: boolean; timeSlot: ITimeSlot | undefined } = {
    checked: false,
    timeSlot: undefined,
  };

  const days = [
    { key: 0, name: t('monday') },
    { key: 1, name: t('tuesday') },
    { key: 2, name: t('wednesday') },
    { key: 3, name: t('thursday') },
    { key: 4, name: t('friday') },
    { key: 5, name: t('saturday') },
  ];

  const newMorningsArray = [emptySlot, emptySlot, emptySlot, emptySlot, emptySlot, emptySlot];
  const newAfternoonsArray = [emptySlot, emptySlot, emptySlot, emptySlot, emptySlot, emptySlot];

  const renderCheckbox = (value: boolean) => {
    const unchecked = <Checkbox16 />;
    const checked = <CheckboxCheckedFilled16 />;
    return value ? checked : unchecked;
  };

  const disableTimeslotCheckBox = () => {
    const allowSelectTimeSlotsToDealer = marketplaces.find(marketplace => marketplace.allowTimeslotsInSdm);
    if (!allowSelectTimeSlotsToDealer) {
      return 'checkboxDisable timeslots--disabled';
    }
    return '';
  };

  const checkboxHandler = (slot: ITimeSlotData, index: number, period: 'morning' | 'afternoon') => {
    if (slot.timeSlot && status !== DataStatus.LOADING) {
      const newActions = actions.map((a, i) => ({ morning: i == index && period === 'morning', afternoon: i == index && period === 'afternoon' }));
      setActions(newActions);

      if (!slot.checked) {
        dispatch(
          addTimeSlot({
            idDealer: idDealer,
            from: slot.timeSlot.from,
            until: slot.timeSlot.until,
            dayOfWeek: slot.timeSlot.dayOfWeek,
            active: slot.timeSlot.active,
          }),
        ).catch(console.error);
      } else {
        dispatch(
          deleteTimeSlot({
            idTimeSlot: slot.timeSlot.id,
          }),
        ).catch(console.error);
      }
    }
  };

  const renderTimeSlots = () => {
    const mornings: ITimeSlotData[] = newMorningsArray.map((item, key) => {
      const found = timeSlots.find(t => t.dayOfWeek === key && t.active && t.from === '8:00');
      if (found) {
        return {
          checked: true,
          timeSlot: found,
        };
      } else {
        return {
          checked: false,
          timeSlot: { from: '8:00', until: '12:00', dayOfWeek: key, active: false, occupied: false, id: 0 },
        };
      }
    });

    const afternoons: ITimeSlotData[] = newAfternoonsArray.map((item, key) => {
      const found = timeSlots.find(t => t.dayOfWeek === key && t.active && t.from === '14:00');
      if (found) {
        return {
          checked: true,
          timeSlot: found,
        };
      } else {
        return {
          checked: false,
          timeSlot: { from: '14:00', until: '18:00', dayOfWeek: key, active: false, occupied: false, id: 0 },
        };
      }
    });

    return (
      <div>
        <div className="dealer--timeslots__text fp--body-short-01">{t('select_period')}</div>

        <div className="dealer--timeslots__grid">
          <div className="dealer--timeslots__grid--header fp--label-01"></div>
          <div className="dealer--timeslots__grid--header fp--label-01">{t('morning')}</div>
          <div className="dealer--timeslots__grid--header fp--label-01">{t('afternoon')}</div>
        </div>

        <div className={`dealer--timeslots__grid ${disableTimeslotCheckBox()}`}>
          {days.map(day => (
            <Fragment key={`slot-${day.key}`}>
              <div className="dealer--timeslots__grid--day fp--productive-heading-01">{day.name}</div>
              <div className={`dealer--timeslots__grid--morning fp--body-short-01 ${status === DataStatus.LOADING && 'timeslots--disabled'}`}>
                <div className="custom-checkbox" onClick={() => checkboxHandler(mornings[day.key], day.key, 'morning')}>
                  {renderCheckbox(mornings[day.key].checked)}
                </div>
                {`8 - 12 ${t('clock')} `}
                {status === DataStatus.LOADING && actions[day.key].morning && <Loading className="timeslots__loading" small={true} withOverlay={false} />}
              </div>
              <div className={`dealer--timeslots__grid--afternoon fp--body-short-01 ${status === DataStatus.LOADING && 'timeslots--disabled'}`}>
                <div className="custom-checkbox" onClick={() => checkboxHandler(afternoons[day.key], day.key, 'afternoon')}>
                  {renderCheckbox(afternoons[day.key].checked)}
                </div>
                {`14 - 18 ${t('clock')} `}
                {status === DataStatus.LOADING && actions[day.key].afternoon && <Loading className="timeslots__loading" small={true} withOverlay={false} />}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    );
  };

  return <div className="dealer--timeslots">{renderTimeSlots()}</div>;
};

export default DealerTimeSlots;
