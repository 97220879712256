import IDataConverter from '../../common/models/IDataConverter';
import { IVehicle } from '../../common/models/purchase';

export interface IRawVehicle {
  id: number;
  vin: string | null;
  orbit: string | null;
  plateNumber: string | null;
  car_registration_status: string | null;
  registrationDate: string | null;
}

class VehicleConverter implements IDataConverter<IRawVehicle, IVehicle> {
  convert(from: IRawVehicle): IVehicle {
    return {
      id: from.id || 0,
      vin: from.vin || null,
      orbit: from.orbit || null,
      plateNumber: from.plateNumber || null,
      registrationDate: from.registrationDate ? new Date(from.registrationDate) : null,
      car_registration_status: from.car_registration_status
    };
  }
}

export default VehicleConverter;
