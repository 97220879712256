import React from 'react';
import Register from '../modules/register/Register';
import './RegisterPage.scss';
import { useTheme } from '../utils/ThemeUtil';

const RegisterPage: React.FC = () => {
  const theme = useTheme();
  return (
    <div className="register-page">
      <div className={`register-page__logo overlaylogo ${theme.t('theme_slug')}`}></div>
      <Register />
    </div>
  );
};

export default RegisterPage;
