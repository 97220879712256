import { IDocument } from '../../common/models/purchase';
import IDataConverter from '../../common/models/IDataConverter';

export interface IRawDocument {
  id: number;
  name: string;
  type: string;
  uri: string;
  usage: string;
  createdAt: Date;
}

class DocumentConvertor implements IDataConverter<IRawDocument, IDocument> {
  convert(from: IRawDocument): IDocument {
    return {
      id: from.id || 0,
      name: from.name || '',
      type: from.type || '',
      uri: from.uri || '',
      usage: from.usage || '',
      createdAt: from.createdAt
    };
  }
}

export default DocumentConvertor;
