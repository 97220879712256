import { InlineNotification, Loading } from 'carbon-components-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../utils/ThemeUtil';
import DashboardItemList from '../../common/components/Dashboard/DashboardItemList';
import { DataStatus } from '../../common/models/dataStatus';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getDashboardData, getDashboardState } from '../../redux/reducers/DashboardSlice';
import { authenticateState } from '../../redux/reducers/AuthenticationSlice';
import './Dashboard.scss';
import EnvUtil from '../../utils/EnvUtil';
import { IRefresh } from '../../utils/RefreshUtil';

const Dashboard: React.FC = () => {
  const { t } = useTranslation('dashboard');
  const theme = useTheme();
  const state = useAppSelector(getDashboardState);
  const dealerName = useAppSelector(authenticateState);
  const { dashboardData, status } = state;
  const dispatch = useAppDispatch();
  const refresh: IRefresh = {
    intervalId: undefined,
    time: (EnvUtil.get('REFRESH_INTERVAL') as unknown as number) || 0,
  };
  useEffect(() => {
    dispatch(getDashboardData()).catch(console.error);

    if (refresh.time > 0 && refresh.intervalId === undefined) {
      refresh.intervalId = setInterval(() => {
        dispatch(getDashboardData()).catch(console.error);
      }, refresh.time);
    }

    return () => {
      if (refresh.time > 0 && refresh.intervalId !== undefined) {
        clearInterval(refresh.intervalId as unknown as number);
      }
    };
  }, []);
  const renderDashboard = () => {
    return (
      dashboardData && (
        <div className={`dashboard ${dashboardData.length == 1 ? 'one-item' : dashboardData.length == 2 ? 'two-items' : ''}`}>
          <div className="container">
            <h3>{t('greetings') + ' ' + dealerName.dealer?.contact + ', ' + theme.t('welcome_message')}</h3>
            <DashboardItemList dashboardItems={dashboardData} />
          </div>
        </div>
      )
    );
  };

  const renderError = () => {
    return (
      <InlineNotification iconDescription="describes the close button" title={t('error_content_loaded') as string} notificationType="inline" kind="error" />
    );
  };

  const renderLoading = () => {
    return (
      <div className="vehicle-details__loading dashboard">
        <Loading description={t('load_vehicle_details')} withOverlay={false} />
      </div>
    );
  };

  const render = () => {
    if (status === DataStatus.LOADING) {
      return renderLoading();
    }
    if (status === DataStatus.FAILED) {
      return renderError();
    }

    return renderDashboard();
  };

  return render();
};

export default Dashboard;
