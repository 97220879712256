import { Header, HeaderGlobalAction, HeaderGlobalBar, HeaderNavigation } from 'carbon-components-react/lib/components/UIShell';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFavicon, useTheme, useTitle } from '../../../utils/ThemeUtil';
import { routerPages } from '../../routerPages';
import AppLanguage from '../AppLanguage/AppLanguage';
import './AppHeader.scss';
import Help20 from '@carbon/icons-react/lib/help/20';
import User20 from '@carbon/icons-react/lib/user/20';
import { useState } from 'react';
import AuthenticationUtil from '../../../utils/AuthenticationUtil';
import { OverlayPages } from '../Overlay/overlayPages';
import { useAppDispatch } from '../../../redux/hooks';
import { show } from '../../../redux/reducers/OverlaySlice';

const AppHeader: React.FC = () => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  useTitle(theme.t('brand_name') + ' - ' + theme.t('app_title'));
  useFavicon(theme);

  const location = useLocation();
  const { pathname } = location;
  const dispatch = useAppDispatch();
  const [selectedPage, setPage] = useState(pathname);

  const logout = () => {
    AuthenticationUtil.logout();
  };

  const translatedButtons: { [key: string]: string } = {
    release: t('release'),
    status: t('vehicle_status'),
    calendar: t('calendar'),
    history: t('history'),
  };

  return (
    <Header aria-label="Header">
      <div className="container">
        <a className={`bx--header__name applogo ${theme.t('theme_slug')}`}></a>

        <HeaderNavigation aria-label="Header navigation">
          {routerPages
            .filter(page => page.type === 'header')
            .map(page => (
              <Link
                key={page.url}
                to={page.url}
                className={`bx--header__menu-item ${selectedPage === page.url ? 'page-active' : ''}`}
                onClick={() => {
                  setPage(page.url);
                }}
              >
                <span className="bx--text-truncate--end">{page.name in translatedButtons ? translatedButtons[page.name] : 'not translated'}</span>
              </Link>
            ))}
        </HeaderNavigation>

        <AppLanguage />

        <HeaderGlobalBar aria-label="Header global bar">
          <HeaderGlobalAction aria-label="User" onClick={() => dispatch(show({ content: OverlayPages.HELP_PAGE, headline: t('help'), contentProps: {} }))}>
            <Help20 />
          </HeaderGlobalAction>
          <HeaderGlobalAction
            aria-label="User"
            onClick={() => dispatch(show({ content: OverlayPages.ACCOUNT_SETTINGS, headline: t('company_profile'), contentProps: {} }))}
          >
            <User20 />
          </HeaderGlobalAction>
          <a className="bx--header__menu-item" onClick={logout}>
            {t('sign_out')}
          </a>
          <a className={`logo_right applogo ${theme.t('theme_slug')}`}></a>
        </HeaderGlobalBar>
      </div>
    </Header>
  );
};
export default AppHeader;
