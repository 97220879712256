import { useTranslation } from 'react-i18next';
import TopPageHeadline from '../common/components/TopPageHeadline/TopPageHeadline';
import History from '../modules/history/History';

const HistoryPage: React.FC = () => {
  const { t } = useTranslation('history');

  return (
    <div>
      <TopPageHeadline text={t('history')} />
      <div className="container">
        <History />
      </div>
    </div>
  );
};

export default HistoryPage;
