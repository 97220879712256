import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from '../reducerNames';
import { RootState } from '../store';

export interface INotificationState {
  id?: number;
  type: 'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt';
  title: string;
  caption?: string;
  subtitle: string;
  role?: string;
  timeout?: number;
}

interface SliceNotificationState {
  notifications: INotificationState[];
}

const notifications = createSlice({
  name: Notification,
  initialState: {
    notifications: [],
  } as SliceNotificationState,
  reducers: {
    showNotification: (state, action: PayloadAction<INotificationState>) => {
      state.notifications = [
        ...state.notifications,
        {
          id: state.notifications.length,
          type: action.payload.type,
          title: action.payload.title,
          subtitle: action.payload.subtitle,
          caption: action.payload.caption,
          role: action.payload.role,
        },
      ];
    },
    hideNotification: (state, action: PayloadAction<unknown | number>) => {
      state.notifications = state.notifications.filter(notification => notification.id !== action.payload);
    },
  },
});

export const { showNotification, hideNotification } = notifications.actions;
export const getNotificationState = (state: RootState): SliceNotificationState => state.notificationReducer;
export default notifications.reducer;
