import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStatus } from '../../common/models/dataStatus';
import { IDealer } from '../../common/models/dealer';
import AuthenticationUtil from '../../utils/AuthenticationUtil';
import HttpUtil from '../../utils/HttpUtil';
import { AccountSettings } from '../reducerNames';
import { RootState } from '../store';
import { ICodeResponse } from '../../common/models/ICodeResponse';

export interface AccountSettingsState {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
  details: IDealer | null;
  message: string | null;
}

const initialState: AccountSettingsState = {
  status: DataStatus.IDLE,
  details: null,
  message: null,

};

export interface DealerMarketPlaceSettingParam  {
    marketplaceId: string,
}

export interface DealerMarketPlaceSettingState  {
    status: DataStatus.IDLE | DataStatus.FAILED
    message: string,
}

export const getAccountSettings = createAsyncThunk<AccountSettingsState>(`${AccountSettings}/fetchDealer`, async (): Promise<AccountSettingsState> => {
  try {
    const userInfo = await AuthenticationUtil.getUserInfo();

    const url = `api/v1/dealer/${userInfo.sub}`;

    const response = await HttpUtil.get<{ dealer: IDealer }>(url);
    return { details: response.data.dealer, status: DataStatus.IDLE, message: initialState.message };
  } catch (err) {
    console.error('Backend not found', err);
    return { details: initialState.details, status: DataStatus.FAILED, message: initialState.message };
  }
});


export const setAutoAcceptRegionalOfferStatus =  createAsyncThunk<DealerMarketPlaceSettingState, DealerMarketPlaceSettingParam>(`${AccountSettings}/autoAcceptRegionalOffer`, 
async (params: DealerMarketPlaceSettingParam): Promise<DealerMarketPlaceSettingState> => {
  
  try {
    
    const url = `api/v1/dealer/marketplace-setting`;
    const response = await HttpUtil.post<ICodeResponse>(url, params);
    if(response.data.code != 200) {
        console.error('Backend not found');
        return { message: 'failed', status: DataStatus.FAILED };
    }

    return { message: 'success', status: DataStatus.IDLE };

  } catch (err) {
    console.error('Backend not found', err);
    return { message: 'failed', status: DataStatus.FAILED };
  }
});

export const AccountSettingsSlice = createSlice({
  name: AccountSettings,
  initialState,
  reducers: {
    // reducers go here
  },
  extraReducers: builder => {
    builder
      .addCase(getAccountSettings.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(getAccountSettings.fulfilled, (state, action: PayloadAction<AccountSettingsState>) => {
        state.status = action.payload.status;
        state.details = action.payload.details;
      })
      .addCase(setAutoAcceptRegionalOfferStatus.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(setAutoAcceptRegionalOfferStatus.fulfilled, (state, action: PayloadAction<DealerMarketPlaceSettingState>) => {
        state.status = action.payload.status;
        state.message = action.payload.message;
      });
  },
});

export const getAccountSettingsState = (state: RootState): AccountSettingsState => state.accountSettings;

export default AccountSettingsSlice.reducer;
