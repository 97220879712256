import { useAppDispatch } from '../../../redux/hooks';
import { getVehiclesData, filterVehiclesData, IVehicleFilterParams } from '../../../redux/reducers/VehiclesSlice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AppSearch from '../../../common/components/AppSearch/AppSearch';
import { VehiclesStatus } from '../../../common/models/vehicles';

const VehiclesFilter: React.FC = () => {
  const { t } = useTranslation('vehicles');
  const dispatch = useAppDispatch();

  const translatedStatus: { key: VehiclesStatus; translation: string }[] = [
    {
      key: VehiclesStatus.RELEASE,
      translation: t('release'),
    },
    {
      key: VehiclesStatus.MARKETPLACE,
      translation: t('marketplace'),
    },
    {
      key: VehiclesStatus.OFFER,
      translation: t('inquiry'),
    },
    {
      key: VehiclesStatus.HANDOVER,
      translation: t('pickup'),
    },
    {
      key: VehiclesStatus.AT_CUSTOMER,
      translation: t('at_the_customer'),
    },
    {
      key: VehiclesStatus.RETURN,
      translation: t('return'),
    },
    {
      key: VehiclesStatus.COMPLETED,
      translation: t('at_the_dealer'),
    },
  ];

  const onSearchItems = (searchText: string) => {
    if (searchText === '') {
      dispatch(getVehiclesData()).catch(console.error);
      return;
    }

    const status = translatedStatus.find(val => val.translation.toLowerCase() === searchText.toLowerCase());
    const statusList = [VehiclesStatus.OFFER, VehiclesStatus.HANDOVER, VehiclesStatus.AT_CUSTOMER, VehiclesStatus.RETURN, VehiclesStatus.COMPLETED];

    const search: IVehicleFilterParams = {
      filter: {
        searchByText: !status ? searchText : '',
      },
      statusList: status ? [status.key] : statusList,
    };

    dispatch(filterVehiclesData(search)).catch(console.error);
  };

  return (
    <div className="component-wrapper">
      <AppSearch onSearchItems={onSearchItems} text={t('seek')} />
    </div>
  );
};

export default VehiclesFilter;
