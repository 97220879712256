import IDataConverter from '../../common/models/IDataConverter';
import { PurchaseStatus } from '../../common/models/purchase';
import {
  IAtCustomerVehicle,
  ICompletedVehicle,
  IHandOverVehicle,
  IMarketplaceVehicle,
  IOfferVehicle,
  IOtherVehicle,
  IReleaseVehicle,
  IReturnVehicle,
  VehiclesStatus,
} from '../../common/models/vehicles';
import { IVehiclesList } from '../../redux/reducers/VehiclesSlice';
import AppointmentConverter, { IRawAppointment } from './AppointmentConverter';
import PurchaseConverter, { IRawPurchase } from './PurchaseConverter';

interface IRawVehicleListItem {
  idTemplate: number;
  image: string;
  manufacturer: string;
  model: string;
  variant: string;
  fuel: string;
  transmission: string;
}

interface IRawReleaseVehicle extends IRawVehicleListItem {
  status: string;
  details: {
    price: string;
    currency: string;
  };
}

interface IRawMarketplaceVehicle extends IRawVehicleListItem {
  idSubscription: number;
  status: string;
  details: {
    marketplace: string;
    isSdmDealerSubscriptionAllowed: boolean;
    url: string;
    createdAt: string;
    unsubscribedAt: string;
    releaseDate: string;
    active: boolean;
  };
}

interface IRawOfferVehicle extends IRawVehicleListItem {
  idSubscription: number;
  idPurchase: number;
  status: string;
  details: {
    vin: string;
    orbit: string;
    plateNumber: string;
    offerNumber: string;
    status: string;
    unparsedDate: string;
    createdDateTime: string;
    purchase: IRawPurchase;
  };
}

interface IRawHandOverVehicle extends IRawVehicleListItem {
  idSubscription: number;
  idPurchase: number;
  status: string;
  details: {
    idVehicle: number;
    vin: string;
    orbit: string;
    plateNumber: string;
    tasks: number;
    handOverDateTime: string;
    status: string;
    unparsedDate: string;
    appointments: IRawAppointment[];
    purchase: IRawPurchase;
  };
}

interface IRawReturnVehicle extends IRawVehicleListItem {
  idSubscription: number;
  idPurchase: number;
  status: string;
  details: {
    idVehicle: number;
    vin: string;
    orbit: string;
    plateNumber: string;
    tasks: number;
    returnDateTime: string;
    status: string;
    unparsedDate: string;
    appointments: IRawAppointment[];
    purchase: IRawPurchase;
  };
}

interface IRawAtCustomerVehicle extends IRawVehicleListItem {
  idSubscription: number;
  idPurchase: number;
  status: string;
  details: {
    idVehicle: number;
    vin: string;
    plateNumber: string;
    returnDate: string;
    status: string;
    unparsedDate: string;
    purchase: IRawPurchase;
  };
}

interface IRawCompletedVehicle extends IRawVehicleListItem {
  idSubscription: number;
  idPurchase: number;
  status: string;
  details: {
    idVehicle: number;
    vin: string;
    plateNumber: string;
    updateDate: string;
    status: string;
    unparsedDate: string;
    purchase: IRawPurchase;
  };
}

interface IRawOtherVehicle extends IRawVehicleListItem {
  idSubscription: number;
  idPurchase: number;
  status: string;
  details: {
    purchase: IRawPurchase;
  };
}

export interface IRawVehiclesList {
  release: IRawReleaseVehicle[];
  marketplace: IRawMarketplaceVehicle[];
  offer: IRawOfferVehicle[];
  handOver: IRawHandOverVehicle[];
  atCustomer: IRawAtCustomerVehicle[];
  return: IRawReturnVehicle[];
  completed: IRawCompletedVehicle[];
  other: IRawOtherVehicle[];
}

class VehiclesListConverter implements IDataConverter<IRawVehiclesList, IVehiclesList> {
  private convertRelease(from: IRawReleaseVehicle): IReleaseVehicle {
    return {
      idTemplate: from.idTemplate || 0,
      image: from.image || '',
      manufacturer: from.manufacturer || '',
      model: from.model || '',
      variant: from.variant || '',
      fuel: from.fuel || '',
      transmission: from.transmission || '',
      status: VehiclesStatus.RELEASE,
      animation: 'none',
      details: {
        price: from.details && from.details.price ? from.details.price : '',
        currency: from.details && from.details.currency ? from.details.currency : '',
      },
    };
  }

  private convertMarketplace(from: IRawMarketplaceVehicle): IMarketplaceVehicle {
    return {
      idTemplate: from.idTemplate || 0,
      image: from.image || '',
      manufacturer: from.manufacturer || '',
      model: from.model || '',
      variant: from.variant || '',
      fuel: from.fuel || '',
      transmission: from.transmission || '',
      status: VehiclesStatus.MARKETPLACE,
      idSubscription: from.idSubscription || 0,
      animation: 'none',
      details: {
        marketplace: from?.details?.marketplace ? from.details.marketplace : '',
        isSdmDealerSubscriptionAllowed: from?.details?.isSdmDealerSubscriptionAllowed ? from.details.isSdmDealerSubscriptionAllowed : false,
        url: from?.details?.url ? from.details.url : '',
        createdAt: from?.details?.createdAt ? new Date(from.details.createdAt) : new Date(),
        unsubscribedAt: from?.details?.unsubscribedAt ? new Date(from.details.unsubscribedAt) : null,
        releaseDate: from?.details && from?.details?.releaseDate ? from.details.releaseDate : '',
        active: from?.details && from?.details.active ? from.details.active : false,
      },
    };
  }

  private convertOffer(from: IRawOfferVehicle, purchaseConverter: PurchaseConverter): IOfferVehicle {
    return {
      idTemplate: from.idTemplate || 0,
      image: from.image || '',
      manufacturer: from.manufacturer || '',
      model: from.model || '',
      variant: from.variant || '',
      fuel: from.fuel || '',
      transmission: from.transmission || '',
      status: VehiclesStatus.OFFER,
      idSubscription: from.idSubscription || 0,
      idPurchase: from.idPurchase || 0,
      animation: 'none',
      details: {
        vin: from.details && from.details.vin ? from.details.vin : '',
        orbit: from.details && from.details.orbit ? from.details.orbit : '',
        plateNumber: from.details && from.details.plateNumber ? from.details.plateNumber : '',
        offerNumber: from.details && from.details.offerNumber ? from.details.offerNumber : '',
        status: from.details && from.details.status == PurchaseStatus.ACCEPTED ? PurchaseStatus.ACCEPTED : PurchaseStatus.PENDING,
        unparsedDate: from.details && from.details.unparsedDate ? from.details.unparsedDate : '',
        createdDateTime: from.details && from.details.createdDateTime ? from.details.createdDateTime : '',
        purchase: purchaseConverter.convert(from.details.purchase),
      },
    };
  }

  private convertHandOver(from: IRawHandOverVehicle, purchaseConverter: PurchaseConverter, appointmentConverter: AppointmentConverter): IHandOverVehicle {
    return {
      idTemplate: from.idTemplate || 0,
      image: from.image || '',
      manufacturer: from.manufacturer || '',
      model: from.model || '',
      variant: from.variant || '',
      fuel: from.fuel || '',
      transmission: from.transmission || '',
      status: VehiclesStatus.HANDOVER,
      idSubscription: from.idSubscription || 0,
      idPurchase: from.idPurchase || 0,
      animation: 'none',
      details: {
        idVehicle: from.details && from.details.idVehicle ? from.details.idVehicle : 0,
        vin: from.details && from.details.vin ? from.details.vin : '',
        orbit: from.details && from.details.orbit ? from.details.orbit : '',
        plateNumber: from.details && from.details.plateNumber ? from.details.plateNumber : '',
        status: from.details && from.details.status ? purchaseConverter.toPurchaseStatus(from.details.status) : PurchaseStatus.UNKNOWN,
        unparsedDate: from.details && from.details.unparsedDate ? from.details.unparsedDate : '',
        handOverDateTime: from.details && from.details.handOverDateTime ? from.details.handOverDateTime : '',
        purchase: purchaseConverter.convert(from.details.purchase),
        tasks: from.details && from.details.tasks ? from.details.tasks : 0,
        appointments: from.details && from.details.appointments ? from.details.appointments.map(appointmentConverter.convert) : [],
      },
    };
  }

  private convertReturn(from: IRawReturnVehicle, purchaseConverter: PurchaseConverter, appointmentConverter: AppointmentConverter): IReturnVehicle {
    return {
      idTemplate: from.idTemplate || 0,
      image: from.image || '',
      manufacturer: from.manufacturer || '',
      model: from.model || '',
      variant: from.variant || '',
      fuel: from.fuel || '',
      transmission: from.transmission || '',
      status: VehiclesStatus.RETURN,
      idSubscription: from.idSubscription || 0,
      idPurchase: from.idPurchase || 0,
      animation: 'none',
      details: {
        idVehicle: from.details && from.details.idVehicle ? from.details.idVehicle : 0,
        vin: from.details && from.details.vin ? from.details.vin : '',
        orbit: from.details && from.details.orbit ? from.details.orbit : '',
        plateNumber: from.details && from.details.plateNumber ? from.details.plateNumber : '',
        status: from.details && from.details.status ? purchaseConverter.toPurchaseStatus(from.details.status) : PurchaseStatus.UNKNOWN,
        unparsedDate: from.details && from.details.unparsedDate ? from.details.unparsedDate : '',
        returnDateTime: from.details && from.details.returnDateTime ? from.details.returnDateTime : '',
        purchase: purchaseConverter.convert(from.details.purchase),
        tasks: from.details && from.details.tasks ? from.details.tasks : 0,
        appointments: from.details && from.details.appointments ? from.details.appointments.map(appointmentConverter.convert) : [],
      },
    };
  }

  private convertAtCustomer(from: IRawAtCustomerVehicle, purchaseConverter: PurchaseConverter): IAtCustomerVehicle {
    return {
      idTemplate: from.idTemplate || 0,
      image: from.image || '',
      manufacturer: from.manufacturer || '',
      model: from.model || '',
      variant: from.variant || '',
      fuel: from.fuel || '',
      transmission: from.transmission || '',
      status: VehiclesStatus.AT_CUSTOMER,
      idSubscription: from.idSubscription || 0,
      idPurchase: from.idPurchase || 0,
      animation: 'none',
      details: {
        idVehicle: from.details && from.details.idVehicle ? from.details.idVehicle : 0,
        vin: from.details && from.details.vin ? from.details.vin : '',
        plateNumber: from.details && from.details.plateNumber ? from.details.plateNumber : '',
        returnDate: from.details && from.details.returnDate ? from.details.returnDate : '',
        status: PurchaseStatus.PICKED_UP,
        unparsedDate: from.details && from.details.unparsedDate ? from.details.unparsedDate : '',
        purchase: purchaseConverter.convert(from.details.purchase),
      },
    };
  }

  private convertCompleted(from: IRawCompletedVehicle, purchaseConverter: PurchaseConverter): ICompletedVehicle {
    return {
      idTemplate: from.idTemplate || 0,
      image: from.image || '',
      manufacturer: from.manufacturer || '',
      model: from.model || '',
      variant: from.variant || '',
      fuel: from.fuel || '',
      transmission: from.transmission || '',
      status: VehiclesStatus.COMPLETED,
      idSubscription: from.idSubscription || 0,
      idPurchase: from.idPurchase || 0,
      animation: 'none',
      details: {
        idVehicle: from.details && from.details.idVehicle ? from.details.idVehicle : 0,
        vin: from.details && from.details.vin ? from.details.vin : '',
        plateNumber: from.details && from.details.plateNumber ? from.details.plateNumber : '',
        status: from.details && from.details.status ? purchaseConverter.toPurchaseStatus(from.details.status) : PurchaseStatus.UNKNOWN,
        unparsedDate: from.details && from.details.unparsedDate ? from.details.unparsedDate : '',
        updateDate: from.details && from.details.updateDate ? from.details.updateDate : '',
        purchase: purchaseConverter.convert(from.details.purchase),
      },
    };
  }

  private convertOther(from: IRawOtherVehicle, purchaseConverter: PurchaseConverter): IOtherVehicle {
    return {
      idTemplate: from.idTemplate || 0,
      image: from.image || '',
      manufacturer: from.manufacturer || '',
      model: from.model || '',
      variant: from.variant || '',
      fuel: from.fuel || '',
      transmission: from.transmission || '',
      status: VehiclesStatus.OTHER,
      idSubscription: from.idSubscription || 0,
      idPurchase: from.idPurchase || 0,
      animation: 'none',
      details: {
        purchase: purchaseConverter.convert(from.details.purchase),
      },
    };
  }

  convert(from: IRawVehiclesList): IVehiclesList {
    const purchaseConverter = new PurchaseConverter();
    const appointmentConverter = new AppointmentConverter();

    return {
      release: from.release ? from.release.map(this.convertRelease) : [],
      marketplace: from.marketplace ? from.marketplace.map(this.convertMarketplace) : [],
      offer: from.offer ? from.offer.map(o => this.convertOffer(o, purchaseConverter)) : [],
      handOver: from.handOver ? from.handOver.map(h => this.convertHandOver(h, purchaseConverter, appointmentConverter)) : [],
      atCustomer: from.atCustomer ? from.atCustomer.map(a => this.convertAtCustomer(a, purchaseConverter)) : [],
      return: from.return ? from.return.map(r => this.convertReturn(r, purchaseConverter, appointmentConverter)) : [],
      completed: from.completed ? from.completed.map(c => this.convertCompleted(c, purchaseConverter)) : [],
      other: from.other ? from.other.map(o => this.convertOther(o, purchaseConverter)) : [],
    };
  }
}

export default VehiclesListConverter;
