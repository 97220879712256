import IDataConverter from '../../common/models/IDataConverter';
import { IVehicleTemplate } from '../../common/models/vehicleTemplate';
import ConditionConverter, { IRawCondition } from './ConditionConverter';
import MediaConverter, { IRawMedia } from './MediaConverter';

export interface IRawVehicleTemplate {
  id: number;
  manufacturer: string;
  model: string;
  variant: string;
  power: string;
  transmission: string;
  fuel: string;
  fuelConsumptionCombined: string;
  fuelConsumptionInTown: string;
  fuelConsumptionOutOfTown: string;
  co2EmissionValue: string;
  co2EmissionClass: string;
  medias: IRawMedia[];
  conditions: IRawCondition[];
}

class VehicleTemplateConverter implements IDataConverter<IRawVehicleTemplate, IVehicleTemplate> {
  convert(from: IRawVehicleTemplate): IVehicleTemplate {
    const mediaConverter = new MediaConverter();
    const conditionConverter = new ConditionConverter();

    return {
      id: from.id || 0,
      manufacturer: from.manufacturer || '',
      model: from.model || '',
      variant: from.variant || '',
      power: from.power || '',
      transmission: from.transmission || '',
      fuel: from.fuel || '',
      fuelConsumptionCombined: from.fuelConsumptionCombined || '',
      fuelConsumptionInTown: from.fuelConsumptionInTown || '',
      fuelConsumptionOutOfTown: from.fuelConsumptionOutOfTown || '',
      co2EmissionValue: from.co2EmissionValue || '',
      co2EmissionClass: from.co2EmissionValue || '',
      medias: from.medias ? from.medias.map(mediaConverter.convert) : [],
      conditions: from.conditions ? from.conditions.map(conditionConverter.convert) : [],
    };
  }
}

export default VehicleTemplateConverter;
