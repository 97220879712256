import CalendarVehiclesBody from './components/CalendarVehiclesBody';
import { useAppDispatch } from '../../../redux/hooks';
import { getVehiclesData } from '../../../redux/reducers/VehiclesSlice';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CalendarVehicles: React.FC = () => {
  const { t } = useTranslation('calendar-vehicles');
  const dispatch = useAppDispatch();
  const translatedHeadlines = {
    release: t('release'),
    marketplace: t('marketplace'),
    offers: t('offers'),
    handovers: t('handovers'),
    atCustomer: t('at_the_customer'),
    completed: t('closed'),
  };

  useEffect(() => {
    dispatch(getVehiclesData()).catch(console.error);
  }, []);

  const renderCalendarVehicles = () => {
    return <CalendarVehiclesBody translatedHeadlines={translatedHeadlines} />;
  };

  return <div className="component-wrapper">{renderCalendarVehicles()}</div>;
};

export default CalendarVehicles;
