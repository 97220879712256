import IDataConverter from '../../common/models/IDataConverter';
import { IPurchaseSubscription } from '../../common/models/purchaseSubscription';
import DealerConverter, { IRawDealer } from './DealerConverted';
import MarketplaceConverter, { IRawMarketplace } from './MarketPlaceConverter';
import VehicleTemplateConverter, { IRawVehicleTemplate } from './VehicleTemplateConverter';

export interface IRawPurchaseSubscription {
  id: number;
  erpId: string;
  active: boolean;
  createdAt: string;
  unsubscribedAt: string;
  dealer: IRawDealer;
  marketplace: IRawMarketplace;
  vehicleTemplate: IRawVehicleTemplate;
}

class PurchaseSubscriptionConverter implements IDataConverter<IRawPurchaseSubscription, IPurchaseSubscription> {
  convert(from: IRawPurchaseSubscription): IPurchaseSubscription {
    const dealerConverter = new DealerConverter();
    const marketplaceConverter = new MarketplaceConverter();
    const vehicleTemplateConverter = new VehicleTemplateConverter();

    return {
      id: from.id || 0,
      erpId: from.erpId || '',
      active: from.active || false,
      createdAt: from.createdAt ? new Date(from.createdAt) : new Date(),
      unsubscribedAt: from.unsubscribedAt ? new Date(from.unsubscribedAt) : null,
      dealer: dealerConverter.convert(from.dealer || {}),
      marketplace: marketplaceConverter.convert(from.marketplace || {}),
      vehicleTemplate: vehicleTemplateConverter.convert(from.vehicleTemplate || {}),
    };
  }
}

export default PurchaseSubscriptionConverter;
