import IDataConverter from '../../common/models/IDataConverter';
import { ITask, TaskType } from '../../common/models/purchase';

export interface IRawTask {
  id: number;
  erpId: string;
  description: string;
  auto_identifier: string | null;
  type: string;
  todo: boolean;
  isFinished: boolean;
  finishedAt: Date | null;
}

class TaskConverter implements IDataConverter<IRawTask, ITask> {
  convert(from: IRawTask): ITask {
    const toTaskType = (value: string): TaskType => {
      switch (value) {
        case TaskType.CHECK_IN:
          return TaskType.CHECK_IN;
        case TaskType.CHECK_OUT:
          return TaskType.CHECK_OUT;
        case TaskType.CHECK_IN_HINT:
          return TaskType.CHECK_IN_HINT;

        case TaskType.CHECK_IN_TASK:
          return TaskType.CHECK_IN_TASK;

        case TaskType.CHECK_OUT_TASK:
          return TaskType.CHECK_OUT_TASK;

        case TaskType.CHECK_OUT_HINT:
          return TaskType.CHECK_OUT_HINT;

        case TaskType.CHECKIN_APPOINTMENT_TASK:
          return TaskType.CHECKIN_APPOINTMENT_TASK;

        case TaskType.CHECKOUT_APPOINTMENT_TASK:
          return TaskType.CHECKOUT_APPOINTMENT_TASK;

        default:
          return TaskType.UNKNOWN;
      }
    };

    return {
      id: from.id || 0,
      erpId: from.erpId || '',
      auto_identifier: from.auto_identifier ? from.auto_identifier : null,
      description: from.description || '',
      type: toTaskType(from.type),
      todo: from.todo || false,
      isFinished: from.isFinished || false,
      finishedAt: from.finishedAt ? new Date(from.finishedAt) : null,
    };
  }
}

export default TaskConverter;
