import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICondition } from '../../common/models/condition';
import { DataStatus } from '../../common/models/dataStatus';
import { ICodeResponse } from '../../common/models/ICodeResponse';
import HttpUtil from '../../utils/HttpUtil';
import { Condition } from '../reducerNames';
import { RootState } from '../store';

export interface ConditionState extends ICodeResponse {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
  action: 'none' | 'get';
  conditions: ICondition[];
  selectedCondition?: ICondition | null;
}

const initialState: ConditionState = {
  status: DataStatus.IDLE,
  action: 'none',
  code: 0,
  conditions: [],
};

export interface IGetConditionParams {
  idVehicleTemplate: number;
}

export const getCondition = createAsyncThunk<ConditionState, IGetConditionParams>(
  `${Condition}/getConditionByVehicleTemplate`,
  async (params: IGetConditionParams): Promise<ConditionState> => {
    try {
      const url = `api/v1/condition/${params.idVehicleTemplate}`;

      const response = await HttpUtil.get<{ conditions: ICondition[] }>(url, {});

      return { code: 200, status: DataStatus.IDLE, action: 'none', conditions: response.data.conditions };
    } catch (err) {
      console.error('Backend not found', err);
      return { code: 0, status: DataStatus.FAILED, action: 'none', conditions: [] };
    }
  },
);

export const ConditionSlice = createSlice({
  name: Condition,
  initialState,
  reducers: {
    // reducers go here
  },
  extraReducers: builder => {
    builder
      .addCase(getCondition.pending, state => {
        state.status = DataStatus.LOADING;
        state.action = 'get';
      })
      .addCase(getCondition.fulfilled, (state, action: PayloadAction<ConditionState>) => {
        state.status = action.payload.status;
        state.code = action.payload.code;
        state.action = action.payload.action;
        state.conditions = action.payload.conditions ? [...action.payload.conditions] : [];
      });
  },
});

export const getConditionState = (state: RootState): ConditionState => state.conditionReducer;

export default ConditionSlice.reducer;
