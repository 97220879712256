/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataTable, Table, TableHead, TableRow, TableHeader, TableBody, TableCell, DataTableSize, DataTableProps } from 'carbon-components-react';
import { Key } from 'react';
import './AppTable.scss';

export interface AppTableProps {
  headers: {
    key: string;
    header: string;
  }[];
  rows: any;
  size?: DataTableSize;
}

const AppTable: React.FC<AppTableProps> = (props: AppTableProps) => {
  const { headers, rows, size } = props;
  return (
    <div>
      <DataTable headers={headers} rows={rows} size={size}>
        {({ rows, headers, getHeaderProps, getRowProps, getTableProps }: DataTableProps | any) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header: { header: [] }) => (
                  <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: { cells: [] }) => (
                <TableRow {...getRowProps({ row })}>
                  {row.cells.map((cell: { id: Key; value: string }) => (
                    <TableCell key={cell.id}>{cell.value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DataTable>
    </div>
  );
};

export default AppTable;
