import { Loading } from 'carbon-components-react';
import TimelineList from '../../common/components/TimelineList/TimelineList';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getVehicleFileState, getVehiclesFile, IGetVehicleFileParams } from '../../redux/reducers/VehicleFileSlice';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataStatus } from '../../common/models/dataStatus';

export interface IVehiclesFileProps {
  idVehicle: number;
}

const VehicleFile: React.FC<IVehiclesFileProps> = (props: IVehiclesFileProps) => {
    const { t } = useTranslation('vehicles');
  const state = useAppSelector(getVehicleFileState);
  const { status, vehicleFile } = state;
  const dispatch = useAppDispatch();
  const { idVehicle } = props;

  useEffect(() => {
    const params: IGetVehicleFileParams = { idVehicle };

    (async function () { await dispatch(getVehiclesFile(params)) })();

  }, []);

  const renderVehicles = () => {
    return vehicleFile.map(key => <TimelineList key={`timeline-${key.year}`} events={key.events} year={key.year} />);
  };

  const renderError = () => {
    return t('error_content_loaded');
  };

  const renderLoading = () => {
    return (
      <div className="home-list__loading">
        <Loading description="Loading list items" withOverlay={false} />
      </div>
    );
  };

  const render = () => {
    if (status === DataStatus.LOADING) {
      return renderLoading();
    }
    if (status === DataStatus.FAILED) {
      return renderError();
    }

    return renderVehicles();
  };

  return <div>{render()}</div>;
};

export default VehicleFile;
