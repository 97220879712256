import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStatus } from '../../common/models/dataStatus';
import { IDealer, ITimeSlot } from '../../common/models/dealer';
import AuthenticationUtil from '../../utils/AuthenticationUtil';
import HttpUtil from '../../utils/HttpUtil';
import { Authentication } from '../reducerNames';
import { RootState } from '../store';

export interface IAuthenticationState {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
  authenticated: boolean;
  isRegistered: boolean;
  dealer: IDealer | null;
}

const initialState: IAuthenticationState = {
  status: DataStatus.IDLE,
  authenticated: false,
  isRegistered: false,
  dealer: null,
};

export interface IDealerInfo {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
  isRegistered: boolean;
  dealer: IDealer | null;
}

export const fetchDealerInfo = createAsyncThunk<IDealerInfo>(`${Authentication}/getDealerInfo`, async (_, thunkAPI): Promise<IDealerInfo> => {
  try {
    const foundDealer = (thunkAPI.getState() as RootState).authenticationReducer.dealer;
    if (foundDealer) {
      const response = await HttpUtil.get<{ dealer: IDealer }>(`api/v1/dealer/${foundDealer.id}`);
      const dealer = response.data.dealer;

      return { isRegistered: true, dealer, status: DataStatus.IDLE };
    } else {
      return { isRegistered: false, dealer: null, status: DataStatus.FAILED };
    }
  } catch (err) {
    console.error('Backend not found', err);
    return { isRegistered: false, dealer: null, status: DataStatus.FAILED };
  }
});

export const authenticate = createAsyncThunk<IAuthenticationState>(`${Authentication}/authenticate`, async (): Promise<IAuthenticationState> => {
  try {
    const authenticated = await AuthenticationUtil.authenticate();
    let isRegistered = false;
    let dealer = null;

    if (authenticated) {
      const response = await HttpUtil.post<{ dealer: IDealer }>('api/v1/dealer/register');
      isRegistered = response.data.dealer.registered;
      dealer = response.data.dealer;
    }

    return { authenticated, isRegistered, dealer, status: DataStatus.IDLE };
  } catch (err) {
    console.error('Backend not found', err);
    return { authenticated: false, isRegistered: false, dealer: null, status: DataStatus.FAILED };
  }
});

export const validateDealer = createAsyncThunk<{ dealer: IDealer | null; isRegistered: boolean; status: DataStatus }>(
  `${Authentication}/validate`,
  async (): Promise<{ dealer: IDealer | null; isRegistered: boolean; status: DataStatus }> => {
    try {
      const response = await HttpUtil.post<{ dealer: IDealer }>('api/v1/dealer/validate');
      const isRegistered = response.data.dealer.registered;

      return { dealer: response.data.dealer, isRegistered, status: DataStatus.IDLE };
    } catch (err) {
      console.error('Backend not found', err);
      return { dealer: null, isRegistered: false, status: DataStatus.FAILED };
    }
  },
);

export const authenticationSlice = createSlice({
  name: Authentication,
  initialState,
  reducers: {
    updateDealerTimeSlots: (state, action: PayloadAction<{ timeSlot: ITimeSlot }>) => {
      if (state.dealer) {
        state.dealer.timeSlots = [...state.dealer.timeSlots, action.payload.timeSlot];
      }
    },
    removeDealerTimeSlots: (state, action: PayloadAction<{ idTimeSlot: number }>) => {
      if (state.dealer) {
        state.dealer.timeSlots = state.dealer.timeSlots.filter(item => item.id !== action.payload.idTimeSlot);
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(authenticate.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(authenticate.fulfilled, (state, action: PayloadAction<IAuthenticationState>) => {
        state.status = action.payload.status;
        state.authenticated = action.payload.authenticated;
        state.isRegistered = action.payload.isRegistered;
        state.dealer = action.payload.dealer ? { ...action.payload.dealer } : null;
      })
      .addCase(validateDealer.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(validateDealer.fulfilled, (state, action: PayloadAction<{ dealer: IDealer | null; isRegistered: boolean; status: DataStatus }>) => {
        state.status = action.payload.status;
        state.isRegistered = action.payload.isRegistered;
        state.dealer = action.payload.dealer ? { ...action.payload.dealer } : null;
      })
      .addCase(fetchDealerInfo.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(fetchDealerInfo.fulfilled, (state, action: PayloadAction<IDealerInfo>) => {
        state.status = action.payload.status;
        if (action.payload.dealer) {
          state.dealer = { ...action.payload.dealer };
        }
      });
  },
});

export const { updateDealerTimeSlots, removeDealerTimeSlots } = authenticationSlice.actions;

export const authenticateState = (state: RootState): IAuthenticationState => state.authenticationReducer;

export const getDealerInfo = (state: RootState): IDealer | null => state.authenticationReducer.dealer;

export default authenticationSlice.reducer;
