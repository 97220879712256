declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _env_: any;
  }
}

class EnvUtil {
  static get<T>(key: string): T {
    return window._env_ && window._env_[key] ? window._env_[key] : '';
  }
}

export default EnvUtil;
