import { InlineNotification, Loading } from 'carbon-components-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataStatus } from '../../common/models/dataStatus';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getPurchase, getPurchaseState } from '../../redux/reducers/PurchaseSlice';
import { getVehicleTemplate } from '../../redux/reducers/VehicleTemplateSlice';
import { sendPurchaseDocumentRequest } from '../../redux/reducers/AcceptOfferSlice';
import { DocumentUsageType } from '../../common/models/purchase';

import './PurchaseDocuments.scss';

const PurchaseDocuments: React.FC<{ idPurchase: number; idTemplate: number }> = props => {
  const { t } = useTranslation('vehicles');
  const dispatch = useAppDispatch();
  const state = useAppSelector(getPurchaseState);

  const { status, selectedPurchase } = state;
  const { idPurchase, idTemplate } = props;

  useEffect(() => {
    dispatch(getPurchase(idPurchase)).catch(err => console.error(err));
    dispatch(getVehicleTemplate(idTemplate)).catch(err => console.error(err));
  }, []);

  const onSendPurchaseDocumentRequest = (idPurchase: number, type: string) => {
    (async function () {
      await dispatch(sendPurchaseDocumentRequest({ idPurchase, type }));
    })();
  };

  const renderDocumentType = (type: string) => {
    switch (type) {
      case DocumentUsageType.ASSESMENT:
        return t('assesments');
      case DocumentUsageType.CHECK_IN_PROTOCOL:
        return t('check_in_protocol');
      case DocumentUsageType.CHECK_OUT_PROTOCOL:
        return t('check_out_protocol');
      case DocumentUsageType.INVOICE:
        return t('invoices');
      case DocumentUsageType.REGISTRATION:
        return t('registration');
      case DocumentUsageType.UNKNOWN:
        return t('unknown');
    }
  };

  const render = () => {
    if (status === DataStatus.LOADING) {
      return (
        <div className="accept-offer__loading">
          <Loading description="Loading items" withOverlay={false} />
        </div>
      );
    }
    if (status === DataStatus.FAILED) {
      return <InlineNotification iconDescription="close" title={t('error_subscription_information') as string} notificationType="inline" kind="error" />;
    }

    if (!selectedPurchase) {
      return <InlineNotification iconDescription="close" title={t('error_subscription_information') as string} notificationType="inline" kind="error" />;
    }

    const renderVehiclesHistory = () => {
      if (selectedPurchase?.documents.length > 0) {
        return selectedPurchase?.documents.map(document => (
          <li key={document.id}>
            <label>{renderDocumentType(document.usage)}</label>
            <a
              href="#"
              onClick={() => {
                onSendPurchaseDocumentRequest(selectedPurchase.id, document.usage);
              }}
            >
              {t('download')}
            </a>
          </li>
        ));
      } else {
        return <div className={`list-items__children--empty`}>{t('no_documents_not_available')}</div>;
      }
    };

    return (
      <>
        <div className="accept-offer__details accept-offer__heading document-tab">
          <ul className="purchase_document">{renderVehiclesHistory()}</ul>
        </div>
      </>
    );
  };

  return <div className="accept-offer-template">{render()}</div>;
};

export default PurchaseDocuments;
