import './TopPageHeadline.scss';

export interface TopPageHeadlineProps {
  text: string;
}

export const TopPageHeadline: React.FC<TopPageHeadlineProps> = (props: TopPageHeadlineProps) => {
  const { text } = props;

  return (
    <div className="top-page-headline">
      <div className="component-wrapper container">
        <h3>{text}</h3>
      </div>
    </div>
  );
};

export default TopPageHeadline;
