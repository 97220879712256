import { Dropdown, OnChangeData } from 'carbon-components-react';
import './Filter.scss';

export interface IVehicleTemplate {
  manufacturer: string;
  model: string;
  variant: string;
  id: number;
}

export interface IFilter {
  filterItems: IVehicleTemplate[];
  onFilterChange: (selectedCar: unknown) => void;
  label: string;
}

const Filter: React.FC<IFilter> = (props: IFilter) => {
  const { filterItems, onFilterChange, label } = props;
  const onChange = (selectedFilter: OnChangeData<IVehicleTemplate>) => {
    onFilterChange(selectedFilter.selectedItem);
  };
  return (
    <div className="app-filter">
      <Dropdown
        id=""
        titleText=""
        label={label}
        items={filterItems}
        itemToString={item => (item ? item.manufacturer + ' ' + item.model + ' ' + item.variant : 'Select All Models')}
        onChange={e => {
          onChange(e);
        }}
      />
    </div>
  );
};

export default Filter;
