import { IAppointment } from '../common/models/appointment';
import { IVehicleHandOverDetails, IVehicleReturnDetails } from '../common/models/vehicles';

const getPickupAppointments = (vehicleDetails: IVehicleHandOverDetails): IAppointment[] => {
  return vehicleDetails.appointments.filter(item => item.action === 'hand-over');
};

const hasPickupAppointment = (vehicleDetails: IVehicleHandOverDetails): boolean => {
  return getPickupAppointments(vehicleDetails).length > 0;
};

const getReturnAppointments = (vehicleDetails: IVehicleReturnDetails): IAppointment[] => {
  return vehicleDetails.appointments.filter(item => item.action === 'return');
};

const hasReturnAppointment = (vehicleDetails: IVehicleReturnDetails): boolean => {
  return getReturnAppointments(vehicleDetails).length > 0;
};

const formatAppointmentDate = (appointmentAt: Date): string => {
  return new Date(appointmentAt).toLocaleString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

const formatAppointmentDateTime = (appointmentAt: Date, timeLabel = 'h'): string => {
  return (
    formatAppointmentDate(appointmentAt) +
    ',' +
    ' ' +
    new Date(appointmentAt).toLocaleString('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }) +
    ' ' +
    timeLabel
  );
};

export { hasPickupAppointment, getPickupAppointments, hasReturnAppointment, getReturnAppointments, formatAppointmentDate, formatAppointmentDateTime };
