import { InlineNotification, Loading } from 'carbon-components-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MultiPriceConditionInfo from '../../../../common/components/PriceCondition/MultiPriceConditionInfo';
import PriceCondition from '../../../../common/components/PriceCondition/PriceCondition';
import { DataStatus } from '../../../../common/models/dataStatus';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { getVehicleTemplate, getVehicleTemplateState } from '../../../../redux/reducers/VehicleTemplateSlice';

const ReleaseVehicleTemplate: React.FC<{ idVehicleTemplate: number }> = props => {
  const { t } = useTranslation('vehicles');
  const dispatch = useAppDispatch();
  const state = useAppSelector(getVehicleTemplateState);
  const { status, selectedVehicleTemplate } = state;
  const { idVehicleTemplate } = props;

  useEffect(() => {
    dispatch(getVehicleTemplate(idVehicleTemplate)).catch(console.error);
  }, []);

  const render = () => {
    if (status === DataStatus.LOADING) {
      return (
        <div className="home-list__loading">
          <Loading description="Loading items" withOverlay={false} />
        </div>
      );
    }
    if (status === DataStatus.FAILED) {
      return (
        <InlineNotification
          iconDescription="describes the close button"
          title={t('error_subscription_information') as string}
          notificationType="inline"
          kind="error"
        />
      );
    }

    if (!selectedVehicleTemplate) {
      return (
        <InlineNotification
          iconDescription="describes the close button"
          title={t('error_subscription_information') as string}
          notificationType="inline"
          kind="error"
        />
      );
    }

    return (
      <PriceCondition description={t(`release_model`)}>
        {selectedVehicleTemplate.conditions && <MultiPriceConditionInfo conditions={selectedVehicleTemplate.conditions} />}
      </PriceCondition>
    );
  };

  return <div className="release-vehicle-template">{render()}</div>;
};

export default ReleaseVehicleTemplate;
