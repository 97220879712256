import './AccountSettings.scss';
import DealerProfile from '../../common/components/DealerProfile/DealerProfile';
import { InlineNotification, Loading, Tabs } from 'carbon-components-react';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getAccountSettingsState, getAccountSettings } from '../../redux/reducers/AccountSettingsSlice';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataStatus } from '../../common/models/dataStatus';
import AppTab from '../../common/components/AppTab/AppTab';
import DealerTimeSlots from '../../common/components/DealerTimeSlots/DealerTimeSlots';
import { getTimeSlots } from '../../redux/reducers/TimeSlotsSlice';

const AccountSettings: React.FC = () => {
  const { t } = useTranslation('account-settings');
  const dispatch = useAppDispatch();

  const state = useAppSelector(getAccountSettingsState);
  const { status, details } = state;

  useEffect(() => {
    dispatch(getAccountSettings()).catch(console.error);
    dispatch(getTimeSlots()).catch(console.error);
  }, []);
  const renderAccountSettings = () => {
    return (
      details && (
        <div className="account-settings">
          <Tabs>
            <AppTab id="tab-1" label={t('profile')}>
              <DealerProfile
                companyName={details.companyName}
                contact={details.contact}
                address={details.address}
                zipcode={details.zipCode}
                city={details.city}
                phone={details.phone}
                mail={details.mail}
                manufacturerCode={details.manufacturerCode}
                slaUrl={details.slaUrl}
                marketplaces={details.marketplaces}
                marketplaceSettings={details.marketplaceSettings}
              />
            </AppTab>
            <AppTab id="tab-2" label={t('pickup_times')}>
              <DealerTimeSlots idDealer={details.id} marketplaces={details.marketplaces} />
            </AppTab>
          </Tabs>
        </div>
      )
    );
  };

  const renderError = () => {
    return (
      <InlineNotification iconDescription="describes the close button" title={t('error_content_loaded') as string} notificationType="inline" kind="error" />
    );
  };

  const renderLoading = () => {
    return (
      <div className="vehicle-details__loading">
        <Loading description={t('load_vehicle_details')} withOverlay={false} />
      </div>
    );
  };

  const render = () => {
    if (status === DataStatus.LOADING) {
      return renderLoading();
    }
    if (status === DataStatus.FAILED || !details) {
      return renderError();
    }

    return renderAccountSettings();
  };

  return <div className="component-wrapper">{render()}</div>;
};

export default AccountSettings;
