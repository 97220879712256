import Dashboard from '../modules/dashboard/Dashboard';
import Vehicles from '../modules/vehicles/Vehicles';
import './VehiclesPage.scss';

const VehiclesPage: React.FC = () => {
  return (
    <div>
      <Dashboard />
      <div className="components-vehicles-page container">
        <Vehicles />
      </div>
    </div>
  );
};

export default VehiclesPage;
