export enum DealerStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface ITimeSlot {
  id: number;
  from: string;
  until: string;
  dayOfWeek: number;
  occupied: boolean;
  active: boolean;
}
export interface IManufacturer {
  erpId: string;
  name: string;
}
export interface IMarketplace {
  id: string | number;
  erpId: string;
  name: string;
  supportEmail: string;
  supportPhone: string;
  slaUrl: string;
  logoUrl: string;
  shopUrl: string;
  logistikportalAvailable: boolean;
  allowAcceptingOffersSdm: boolean;
  manufacturers: IManufacturer[];
  regionalMaxDistance: string | number;
  superregionalMaxistance: string | number;
  purchaseOffeDealerTimeout: string;
  deliveryTimeMin: string;
  deliveryTimeMax: string;
  deliveryTimePreparations: string;
  isSdmDealerSubscriptionAllowed: boolean;
  allowTimeslotsInSdm: boolean;
}
export interface IMarketplaceSetting {
  id: string | number;
  marketplaceErpId: string;
  manufacturerId: string | number;
  manufacturerName: string;
  dealerCode: string;
  isActive: boolean;
  isRegional: boolean;
  autoAcceptRegionalOffer: boolean;
  isSuperregional: boolean;
}
export interface IDealer {
  id: number;
  erpId: string;
  companyName: string;
  contact: string;
  address: string;
  zipCode: string;
  city: string;
  phone: string;
  mail: string;
  logistikportal_login_token: string;
  registered: boolean;
  manufacturerCode: string;
  isSuperRegional: boolean;
  timeSlots: ITimeSlot[];
  status: DealerStatus;
  slaUrl: string;
  // eslint-disable-next-line
  marketplaces: IMarketplace[];
  marketplaceSettings: IMarketplaceSetting[];
}
