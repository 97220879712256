import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataStatus } from '../../models/dataStatus';
import { IPurchase, PurchaseStatus } from '../../models/purchase';
import { useAppDispatch } from '../../../redux/hooks';
import HttpUtil from '../../../utils/HttpUtil';
import { getAccountSettings } from '../../../redux/reducers/AccountSettingsSlice';
import EnvUtil from '../../../utils/EnvUtil';

const LogistikportalLink: React.FC<{ selectedPurchase: IPurchase | null }> = props => {
  const { t } = useTranslation('vehicles');
  const dispatch = useAppDispatch();
  const selectedPurchase = props.selectedPurchase
  let loading = false;

  useEffect(() => {
    dispatch(getAccountSettings()).catch(err => console.error(err));
  }, []);

  const logistikportalEnabled = () => {
    return (EnvUtil.get('LP_PUBLIC_URL') != '') 
      && selectedPurchase?.marketplace?.logistikportalAvailable
      && (selectedPurchase?.transportType != 1) // 'on site handover';
  };

  const redirectTarget = () => {
    return selectedPurchase?.vehicle?.vin ?? selectedPurchase?.vehicle?.plateNumber;
  };                                                                         
                                          
  const isCheckout = () => {
    return selectedPurchase?.status === PurchaseStatus.READY_FOR_PICK_UP;
  };

  const isCheckin = () => {
    return selectedPurchase?.status === PurchaseStatus.SCHEDULED_FOR_RETURN;
  };

  const redirectAction = () => {
    let action: string | undefined = undefined;
    if (isCheckout()) {
      action = 'checkout';
    }
    if (isCheckin()) {
      action = 'checkin';
    }
    return action;
  };

  const redirectText = () => {
    let text: string | undefined = undefined;
    if (isCheckout()) {
      text = t('checkout_in_logistikportal');
    }
    if (isCheckin()) {
      text = t('checkin_in_logistikportal');
    }
    return text;
  };

  const showLogistikportalLink = () => {
    return (
      (
        (isCheckout() && selectedPurchase?.checkoutAvailable) ||
        (isCheckin() && selectedPurchase?.checkinAvailable)
      ) && (
        selectedPurchase?.vehicle?.vin && 
        selectedPurchase?.vehicle?.plateNumber
      )
    );
  };

  const onLogistikportalLinkClick = async () => {
    if (!logistikportalEnabled()) {
      return;
    }

    const target = redirectTarget();
    const action = redirectAction();
    if (!target || !action || loading) {
      return;
    }

    loading = true;
    try {
      const response = await HttpUtil.post<{ url?: string }>('api/v1/dealer/lp-redirect', {
        target,
        action,
      });

      if (response.data.url) {
        const w = window.open(response.data.url, 'sdm-lp');
        w?.focus();
        loading = false;
      } else {
        console.error(response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const renderLogistikportallink = () => {
    if (logistikportalEnabled() && showLogistikportalLink()) {
      return (
        <div className="list-item__button">
          <button className="bx--btn bx--btn--primary" disabled={loading} onClick={onLogistikportalLinkClick}>
            {redirectText()}
          </button>
        </div>
      );
    } else {
      return <p>{t('logistikportal_portal_not_available')}</p>;
    }
  };

  const render = () => {
    if (status === DataStatus.LOADING) {
      return null;
    }
    if (status === DataStatus.FAILED) {
      return null;
    }

    if (!selectedPurchase) {
      return null;
    }

    return (
      <>
        <div className={`accept-offer__details accept-offer__heading LogistikportalLink`}>
          {renderLogistikportallink()}
        </div>
      </>
    );
  };

  return <div className="accept-offer-template">{render()}</div>;
};

export default LogistikportalLink;
