import { InlineNotification } from 'carbon-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppButton from '../../../common/components/AppButton/AppButton';
import AppModal from '../../../common/components/AppModal/AppModal';
import ListItems from '../../../common/components/ListItems/ListItems';
import { OverlayPages } from '../../../common/components/Overlay/overlayPages';
import { AppointmentAction } from '../../../common/models/appointment';
import { DataStatus } from '../../../common/models/dataStatus';
import { PurchaseStatus } from '../../../common/models/purchase';
import { IVehicleListItem, IMarketplaceVehicle, IOfferVehicle, IHandOverVehicle, IReturnVehicle } from '../../../common/models/vehicles';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { acceptOffer } from '../../../redux/reducers/AcceptOfferSlice';
import { show } from '../../../redux/reducers/OverlaySlice';
import { enableHideOfferAnimation, getVehiclesState } from '../../../redux/reducers/VehiclesSlice';
import { DetailsTabKey, IVehiclesDetailsProps } from '../../vehicleDetails/VehicleDetails';
import { ITranslatedHeadlines } from '../Vehicles';
import AcceptOfferTemplate from './AcceptOfferTemplate/AcceptOfferTemplate';
import { getPurchase, getPurchaseState } from '../../../redux/reducers/PurchaseSlice';

interface VehiclesBodyProps {
  translatedHeadlines: ITranslatedHeadlines;
}
export interface IAcceptOfferModalProps {
  isOpen: boolean;
  title: string;
  idPurchase: number;
}

const emptyAcceptOfferModal: IAcceptOfferModalProps = {
  isOpen: false,
  title: '',
  idPurchase: 0,
};

const VehiclesBody: React.FC<VehiclesBodyProps> = props => {
  const { t } = useTranslation('vehicles');
  const state = useAppSelector(getVehiclesState);
  const dispatch = useAppDispatch();
  const { vehicles, status } = state;
  const { translatedHeadlines } = props;
  const statePurchase = useAppSelector(getPurchaseState);
  const { selectedPurchase } = statePurchase;
  const [reason, setReason] = useState('');
  const [rejectedPurchaseId, setRejectedPurchaseId] = useState(0);
  const [acceptOfferModalProps, showAcceptOfferModal] = useState<IAcceptOfferModalProps>(emptyAcceptOfferModal);
  const [showofferconfirmModal, setShowofferconfirmModal] = useState(false);
  const onShowDetails = (item: IVehicleListItem, idVehicle: number, selectedTab: DetailsTabKey) => {
    const contentProps: IVehiclesDetailsProps = { idVehicleTemplate: item.idTemplate, selectedTab };

    contentProps.idSubscription = (item as IMarketplaceVehicle).idSubscription;
    contentProps.idPurchase = (item as IOfferVehicle).idPurchase;

    if (idVehicle !== 0) {
      contentProps.idVehicle = idVehicle;
    }

    const headline = `${item.manufacturer} ${item.model} ${item.variant}`;

    dispatch(show({ headline, content: OverlayPages.VEHICLE_DETAILS, contentProps }));
  };
  const onAcceptOffer = (idPurchase: number, status: 'accepted' | 'declined', reason: string) => {
    dispatch(acceptOffer({ idPurchase, status, reason })).catch(console.error);
    showAcceptOfferModal(emptyAcceptOfferModal);
    dispatch(enableHideOfferAnimation({ idPurchase }));
  };
  useEffect(() => {
    if (acceptOfferModalProps.idPurchase !== 0) {
      dispatch(getPurchase(acceptOfferModalProps.idPurchase)).catch(err => console.error(err));
    }
  }, []);
  const isEmptyList = () => {
    return status == DataStatus.IDLE && vehicles.marketplace.length == 0 ? 'show' : 'none';
  };

  const renderModals = () => {
    return (
      <AppModal
        open={acceptOfferModalProps.isOpen}
        className={'isSuperregional'}
        onRequestClose={() => showAcceptOfferModal(emptyAcceptOfferModal)}
        onRequestSubmit={() => onAcceptOffer(acceptOfferModalProps.idPurchase, 'accepted', reason)}
        onSecondarySubmit={() => {
          setShowofferconfirmModal(true);
          setRejectedPurchaseId(acceptOfferModalProps.idPurchase);
          showAcceptOfferModal(emptyAcceptOfferModal);
        }}
        primaryButtonText={t('confirm_offer')}
        secondaryButtonText={t('reject_offer')}
        modalHeading={acceptOfferModalProps.title}
        showSecondaryButton={true}
      >
        {acceptOfferModalProps.isOpen && <AcceptOfferTemplate idPurchase={acceptOfferModalProps.idPurchase} />}
      </AppModal>
    );
  };

  const getOfferButton = (item: IOfferVehicle) => {
    if (item.details.status === PurchaseStatus.PENDING) {
      return (
        <AppButton
          kind="primary"
          onClick={() =>
            showAcceptOfferModal({
              isOpen: true,
              title: `${item.manufacturer} ${item.model} ${item.variant} - ${t('check_offer')}`,
              idPurchase: item.idPurchase,
            })
          }
        >
          {t('check_offer')}
        </AppButton>
      );
    } else {
      return (
        <AppButton kind="secondary" onClick={() => onShowDetails(item, 0, 'details')}>
          {t('details')}
        </AppButton>
      );
    }
  };

  const renderAppionmentPickupButton = (item: IOfferVehicle | IHandOverVehicle) => {
    const getappointment = item.details.purchase.appointments;
    if (PurchaseStatus.SCHEDULED_FOR_PICK_UP) {
      if (getappointment.length > 0) {
        const getaction = item.details.purchase.appointments[0].action;
        if (getaction == AppointmentAction.HAND_OVER || getaction == AppointmentAction.RETURN) {
          return t('handover_details');
        }
      } else {
        return t('make_an_appointment');
      }
    }
  };

  const handOverKeepBtnColor = (item: IOfferVehicle | IHandOverVehicle | IReturnVehicle) => {
    const getappointment = item.details.purchase.appointments;

    if (PurchaseStatus.SCHEDULED_FOR_PICK_UP == item?.details?.purchase.status || PurchaseStatus.READY_FOR_PICK_UP == item?.details?.purchase.status) {
      if (getappointment.length > 0) {
        const getaction = item.details.purchase.appointments[0].action;
        if (getaction == AppointmentAction.HAND_OVER) {
          return true;
        }
        if (getaction == AppointmentAction.RETURN) {
          return false;
        }
      }
    } else if (PurchaseStatus.SCHEDULED_FOR_RETURN == item?.details?.purchase.status) {
      if (getappointment.length > 0) {
        const getaction = item.details.purchase.appointments[getappointment.length - 1].action;

        if (getaction == AppointmentAction.HAND_OVER) {
          return false;
        }
        if (getaction == AppointmentAction.RETURN) {
          return true;
        }
      }
    }
  };

  const renderAppionmentReturnButton = (item: IOfferVehicle | IReturnVehicle) => {
    const getappointment = item.details.purchase.appointments;
    if (PurchaseStatus.SCHEDULED_FOR_RETURN) {
      if (getappointment.length > 0) {
        const getaction = item.details.purchase.appointments[getappointment.length - 1].action;
        if (getaction == AppointmentAction.HAND_OVER) {
          return t('make_an_appointment');
        } else if (getaction == AppointmentAction.RETURN) {
          return t('handover_details');
        }
      } else {
        return t('make_an_appointment');
      }
    }
  };

  const renderVehicles = () => {
    return (
      <div>
        <div className="list-items-wrapper" id="offer">
          <ListItems
            headline={translatedHeadlines.offers}
            status={state.status}
            emptyListAnimation={isEmptyList()}
            items={vehicles.offer.map(item => ({
              button: getOfferButton(item),
              item: item,
              animation: item.animation,
              onShowDetails: () => onShowDetails(item, 0, 'details'),
            }))}
          />
        </div>
        <div className="list-items-wrapper" id="handOver">
          <ListItems
            headline={translatedHeadlines.handovers}
            status={state.status}
            items={vehicles.handOver.map(item => ({
              button: (
                <AppButton
                  onClick={() => onShowDetails(item, item.details.idVehicle, 'appointments')}
                  kind={handOverKeepBtnColor(item) ? 'secondary' : 'primary'}
                >
                  {renderAppionmentPickupButton(item)}
                </AppButton>
              ),
              item: item,
              animation: item.animation,
              onShowDetails: () => onShowDetails(item, item.details.idVehicle, 'details'),
            }))}
          />
        </div>
        <div className="list-items-wrapper" id="atCustomer">
          <ListItems
            headline={translatedHeadlines.atCustomer}
            status={state.status}
            items={vehicles.atCustomer.map(item => ({
              button: (
                <AppButton onClick={() => onShowDetails(item, item.details.idVehicle, 'vehicle-file')} kind="secondary">
                  {t('to_the_vehicle_file')}
                </AppButton>
              ),
              item: item,
              animation: item.animation,
              onShowDetails: () => onShowDetails(item, item.details.idVehicle, 'details'),
            }))}
          />
        </div>
        <div className="list-items-wrapper" id="return">
          <ListItems
            headline={translatedHeadlines.return}
            status={state.status}
            items={vehicles.return.map(item => ({
              button: (
                <AppButton
                  onClick={() => onShowDetails(item, item.details.idVehicle, 'appointments')}
                  kind={handOverKeepBtnColor(item) ? 'secondary' : 'primary'}
                >
                  {renderAppionmentReturnButton(item)}
                </AppButton>
              ),
              item: item,
              animation: item.animation,
              onShowDetails: () => onShowDetails(item, item.details.idVehicle, 'details'),
            }))}
          />
        </div>
        <div className="list-items-wrapper" id="completed">
          <ListItems
            headline={translatedHeadlines.completed}
            status={state.status}
            items={vehicles.completed.map(item => ({
              button: (
                <AppButton onClick={() => onShowDetails(item, item.details.idVehicle, 'vehicle-file')} kind="secondary">
                  {t('to_the_vehicle_file')}
                </AppButton>
              ),
              item: item,
              animation: item.animation,
              onShowDetails: () => onShowDetails(item, item.details.idVehicle, 'details'),
            }))}
          />
        </div>
      </div>
    );
  };

  const renderError = () => {
    return (
      <InlineNotification iconDescription="describes the close button" title={t('error_content_loaded') as string} notificationType="inline" kind="error" />
    );
  };

  const render = () => {
    if (status === DataStatus.FAILED) {
      return renderError();
    }

    return (
      <div>
        {renderModals()}
        {renderVehicles()}
        {showofferconfirmModal ? (
          <div className="offerconfirm">
            <AppModal
              open={true}
              className={'isSuperregional'}
              onRequestClose={() => {
                setShowofferconfirmModal(false);
              }}
              onRequestSubmit={() => {
                onAcceptOffer(rejectedPurchaseId, 'declined', reason);
                setShowofferconfirmModal(false);
              }}
              onSecondarySubmit={() => {
                setShowofferconfirmModal(false);
              }}
              primaryButtonText={t('confirm')}
              secondaryButtonText={t('abort')}
              modalHeading={''}
              showSecondaryButton={true}
              size="xs"
            >
              <p>{t('decline_the_offer')}</p>
              {selectedPurchase?.isFollowUpContract === true ? (
                <div>
                  <textarea placeholder="Please enter a cancel reason" onChange={e => setReason(e.target.value)} />
                </div>
              ) : (
                ''
              )}
            </AppModal>
          </div>
        ) : null}
      </div>
    );
  };
  return render();
};

export default VehiclesBody;
