import { useEffect } from 'react';
import EnvUtil from './EnvUtil';
import { UseTranslationResponse, useTranslation } from 'react-i18next';

class ThemeUtil {
  static availableThemes = ['default', 'ald', 'ald_at']

  static defaultTheme = 'default'

  static namespacePrefix = 'theme-'

  static getThemeName(): string {
    const envTheme = String(EnvUtil.get('SDM_THEME')).toLowerCase();
    const queryTheme = (new URLSearchParams(window?.location?.search)).get('theme');
    const themeName = queryTheme ? String(queryTheme).toLowerCase() : envTheme

    return this.availableThemes.includes(themeName) ? themeName : this.defaultTheme
  }

  static getNamespace(): string {
    return this.namespacePrefix + this.getThemeName();
  }

  static decode(str: string): string {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  }
}

function useTheme(): UseTranslationResponse<string> {
  return useTranslation(ThemeUtil.getNamespace())
}

function useTitle(title: string): void {
  useEffect(() => {
    const prevTitle = document.title
    document.title = title
    return () => {
      document.title = prevTitle
    }
  })
}

function useFavicon(theme: UseTranslationResponse<string>): void {
  useEffect(() => {
    const favicon = document.getElementById('favicon-link');
    if (favicon) {
      favicon.setAttribute('href', '/img/' + theme.t('favicon_file'));
    }
    const touchIcon = document.getElementById('touchicon-link');
    if (touchIcon) {
      touchIcon.setAttribute('href', '/img/' + theme.t('touchicon_file'));
    }
  })
}

export {
  ThemeUtil,
  useTheme,
  useTitle,
  useFavicon
};
