import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStatus } from '../../common/models/dataStatus';
import { IPurchase, ITask } from '../../common/models/purchase';
import PurchaseConverter, { IRawPurchase } from '../../utils/converters/PurchaseConverter';
import HttpUtil from '../../utils/HttpUtil';
import { Purchase } from '../reducerNames';
import { RootState } from '../store';

export interface PurchaseState {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
  taskstatus: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
  selectedPurchase: IPurchase | null;
  task: ITask | null;
}

const initialState: PurchaseState = {
  status: DataStatus.IDLE,
  taskstatus: DataStatus.LOADING,
  selectedPurchase: null,
  task: null,
};

interface IPurchaseResponse {
  purchase: IRawPurchase;
}

export interface IParamTask {
  idPurchase: number;
  idTask: number;
  isFinished: boolean;
}

interface ITaskResponse {
  data: ITask;
  code: 200 | 500;
}

export const getPurchase = createAsyncThunk<PurchaseState, number>(`${Purchase}/fetchPurchase`, async (idPurchase: number): Promise<PurchaseState> => {
  try {
    const response = await HttpUtil.get<IPurchaseResponse>(`api/v1/purchase/${idPurchase}`);
    const converter = new PurchaseConverter();
    const purchase = converter.convert(response.data.purchase);
    return { selectedPurchase: purchase, taskstatus: DataStatus.IDLE, status: DataStatus.IDLE, task: initialState.task };
  } catch (err) {
    console.error('Backend not found', err);
    return { selectedPurchase: initialState.selectedPurchase, taskstatus: DataStatus.IDLE, status: DataStatus.FAILED, task: initialState.task };
  }
});

export const addTaskDone = createAsyncThunk<PurchaseState, IParamTask>(
  `${Purchase}/postTaskDone`,
  async (param: IParamTask, thunkAPI): Promise<PurchaseState> => {
    try {
      const url = `api/v1/task`;
      const { data } = await HttpUtil.put<ITaskResponse>(url, { idTask: param.idTask, isFinished: param.isFinished });
      if (data.code == 200) {
        (async function () {
          await thunkAPI.dispatch(getPurchase(param.idPurchase));
        })();

        return { task: data.data, status: DataStatus.IDLE, taskstatus: DataStatus.IDLE, selectedPurchase: initialState.selectedPurchase };
      }

      return { task: initialState.task, status: DataStatus.FAILED, taskstatus: DataStatus.IDLE, selectedPurchase: initialState.selectedPurchase };
    } catch (err) {
      console.error('Backend not found', err);
      return { task: initialState.task, status: DataStatus.FAILED, taskstatus: DataStatus.IDLE, selectedPurchase: initialState.selectedPurchase };
    }
  },
);

export const purchaseSlice = createSlice({
  name: Purchase,
  initialState,
  reducers: {
    // reducers go here
  },
  extraReducers: builder => {
    builder
      .addCase(getPurchase.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(getPurchase.fulfilled, (state, action: PayloadAction<PurchaseState>) => {
        state.status = action.payload.status;
        state.selectedPurchase = action.payload.selectedPurchase ? { ...action.payload.selectedPurchase } : null;
      })
      .addCase(addTaskDone.pending, state => {
        state.taskstatus = DataStatus.LOADING;
      })
      .addCase(addTaskDone.fulfilled, (state, action: PayloadAction<PurchaseState>) => {
        state.taskstatus = action.payload.status;
        state.task = action.payload.task ? { ...action.payload.task } : null;
      });
  },
});

export const getPurchaseState = (state: RootState): PurchaseState => state.purchaseReducer;

export default purchaseSlice.reducer;
