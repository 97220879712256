import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStatus } from '../../common/models/dataStatus';
import { IVehicleFileEventList } from '../../common/models/vehicleFile';
import HttpUtil from '../../utils/HttpUtil';
import { VehicleFile } from '../reducerNames';
import { RootState } from '../store';

export interface VehicleFileState {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
  vehicleFile: IVehicleFileEventList[];
}

const initialState: VehicleFileState = {
  status: DataStatus.IDLE,
  vehicleFile: [
    {
      year: 0,
      events: [],
    },
  ],
};

export interface IGetVehicleFileParams {
  idVehicle: number;
}

export const getVehiclesFile = createAsyncThunk<VehicleFileState, IGetVehicleFileParams>(
  `${VehicleFile}/fetchVehicleFile`,
  async (params: IGetVehicleFileParams): Promise<VehicleFileState> => {
    try {
      const { idVehicle } = params;
      const response = await HttpUtil.get<IVehicleFileEventList[]>(`api/v1/vehicle-file/${idVehicle}`);
      return { vehicleFile: response.data, status: DataStatus.IDLE };
    } catch (err) {
      console.error('Backend not found', err);
      return { vehicleFile: initialState.vehicleFile, status: DataStatus.FAILED };
    }
  },
);

export const vehicleFileSlice = createSlice({
  name: VehicleFile,
  initialState,
  reducers: {
    // reducers go here
  },
  extraReducers: builder => {
    builder
      .addCase(getVehiclesFile.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(getVehiclesFile.fulfilled, (state, action: PayloadAction<VehicleFileState>) => {
        state.status = action.payload.status;
        state.vehicleFile = [...action.payload.vehicleFile];
      });
  },
});

export const getVehicleFileState = (state: RootState): VehicleFileState => state.vehicleFileReducer;

export default vehicleFileSlice.reducer;
