import { IVehicleFileEvent } from '../../models/vehicleFile';
import './TimelineItem.scss';
import { Accordion, AccordionItem } from 'carbon-components-react';
import { Package32 } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';

const TimelineItem: React.FC<IVehicleFileEvent> = (props: IVehicleFileEvent) => {
  const { eventDate, event, details, eventIndex } = props;
  const { t } = useTranslation('vehicles');
  // remove everything after the last dot in date
  const eventDayMonth = eventDate.substring(0, eventDate.lastIndexOf('.') + 1);
  const getStatusTranslationByPurchaseStatus = (status: string) => {
    switch (status) {
      case 'finishedAt':
        return t('purchase_process_completed');
      case 'invoicePaidAt':
        return t('bill_paid');
      case 'invoicedAt':
        return t('invoiced');
      case 'returnedAt':
        return t('return_performed');
      case 'appointmentAt':
        return t('pickup_changed');
      case 'estimated_date_of_return':
        return t('estimated_return');
      case 'pickedUpdAt':
        return t('delivery_done');
      case 'estimated_date_of_delivery':
        return t('pickup_open');
      case 'acceptedAt':
        return t('order_num');
      case 'declinedAt':
        return t('order_number');
      case 'readyForPickUpAt':
        return t('pickup');
      case 'registrationDate':
        return t('registrationDate');
      case 'estimatedReturn':
        return t('return_scheduled');
      case 'estimatedPickUp':
        return t('pickup_scheduled')
      default:
        return '';
    }
  };

  return (
    <li key={`${eventIndex}`} className="timeline-item">
      <span className="date">{eventDayMonth}</span>
      {details.documents && details.documents.length > 0 ? (
        <span className="event-title">
          <Accordion>
            <AccordionItem title={getStatusTranslationByPurchaseStatus(event)}>{details.description}</AccordionItem>
          </Accordion>
          {details.documents.map(d => (
            <div key={`doc-${d.id}`} className="event-link">
              <a href={d.uri}>
                <span>{d.name}</span>
                <Package32 />
              </a>
            </div>
          ))}
        </span>
      ) : (
        <span className="event-title">
          {event && getStatusTranslationByPurchaseStatus(event)}
          <div className="event-description">{details.description}</div>
        </span>
      )}
    </li>
  );
};

export default TimelineItem;
