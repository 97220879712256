import './AppModal.scss';
import { Modal } from 'carbon-components-react';
import React, { useState } from 'react';

type AppModalProps = {
  open: boolean;
  className: string;
  onRequestClose?(event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement>): void;
  onRequestSubmit: (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement>) => void;
  onSecondarySubmit?: (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement>) => void;
  modalHeading: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  showSecondaryButton: boolean;
  primaryButtonDisabled?: boolean;
  size?: 'xs' | 'sm' | 'lg';
};

const AppModal: React.FC<AppModalProps> = props => {
  const {
    open,
    className,
    onRequestClose,
    modalHeading,
    primaryButtonText,
    secondaryButtonText,
    showSecondaryButton,
    onRequestSubmit,
    onSecondarySubmit,
    size,
    primaryButtonDisabled,
  } = props;
  const hideSecondaryButtonClass = !showSecondaryButton && 'btn--modal__secondary--hide';

  const [isDisabled, setDisabled] = useState(false);
  const timer = 1000;

  const preventDoubleClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isDisabled !== true) {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, timer);
    }
    return onRequestSubmit(e);
  };

  return (
    <div className="app-modal--container">
      <div className={`${hideSecondaryButtonClass}`}>
        <Modal
          open={open}
          className={className}
          onSecondarySubmit={onSecondarySubmit}
          onRequestClose={onRequestClose}
          onRequestSubmit={preventDoubleClickHandler}
          primaryButtonText={primaryButtonText}
          secondaryButtonText={secondaryButtonText}
          modalHeading={modalHeading}
          size={size ? size : 'sm'}
          primaryButtonDisabled={isDisabled || primaryButtonDisabled}
        >
          {props.children}
        </Modal>
      </div>
    </div>
  );
};

export default AppModal;
