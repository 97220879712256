import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStatus } from '../../common/models/dataStatus';
import { IVehicleTemplate } from '../../common/models/vehicleTemplate';
import HttpUtil from '../../utils/HttpUtil';
import { VehicleTemplate } from '../reducerNames';
import { RootState } from '../store';

export interface VehicleTemplateState {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
  vehicleTemplates?: IVehicleTemplate[];
  selectedVehicleTemplate?: IVehicleTemplate | null;
}

const initialState: VehicleTemplateState = {
  status: DataStatus.IDLE,
  vehicleTemplates: [],
  selectedVehicleTemplate: null,
};

interface IVehicleTemplatesResponse {
  vehicleTemplates: IVehicleTemplate[];
}

export const getVehiclesTemplates = createAsyncThunk<VehicleTemplateState>(
  `${VehicleTemplate}/fetchVehicleTemplates`,
  async (): Promise<VehicleTemplateState> => {
    try {
      const response = await HttpUtil.get<IVehicleTemplatesResponse>(`api/v1/vehicle-template`);
      return { vehicleTemplates: response.data.vehicleTemplates, status: DataStatus.IDLE };
    } catch (err) {
      console.error('Backend not found', err);
      return { vehicleTemplates: initialState.vehicleTemplates, status: DataStatus.FAILED };
    }
  },
);

interface IVehicleTemplateResponse {
  vehicleTemplate: IVehicleTemplate;
}

export const getVehicleTemplate = createAsyncThunk<VehicleTemplateState, number>(
  `${VehicleTemplate}/fetchVehicleTemplate`,
  async (idVehicleTemplate: number): Promise<VehicleTemplateState> => {
    try {
      const response = await HttpUtil.get<IVehicleTemplateResponse>(`api/v1/vehicle-template/${idVehicleTemplate}`);
      return { selectedVehicleTemplate: response.data.vehicleTemplate, status: DataStatus.IDLE };
    } catch (err) {
      console.error('Backend not found', err);
      return { selectedVehicleTemplate: initialState.selectedVehicleTemplate, status: DataStatus.FAILED };
    }
  },
);

export const vehicleTemplateSlice = createSlice({
  name: VehicleTemplate,
  initialState,
  reducers: {
    // reducers go here
  },
  extraReducers: builder => {
    builder
      .addCase(getVehiclesTemplates.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(getVehiclesTemplates.fulfilled, (state, action: PayloadAction<VehicleTemplateState>) => {
        state.status = action.payload.status;
        state.vehicleTemplates = action.payload.vehicleTemplates ? [...action.payload.vehicleTemplates] : [];
      })
      .addCase(getVehicleTemplate.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(getVehicleTemplate.fulfilled, (state, action: PayloadAction<VehicleTemplateState>) => {
        state.status = action.payload.status;
        state.selectedVehicleTemplate = action.payload.selectedVehicleTemplate ? { ...action.payload.selectedVehicleTemplate } : null;
      });
  },
});

export const getVehicleTemplateState = (state: RootState): VehicleTemplateState => state.vehicleTemplateReducer;

export default vehicleTemplateSlice.reducer;
