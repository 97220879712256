import IDataConverter from '../../common/models/IDataConverter';
import { IEquipment } from '../../common/models/vehicleDetails';

export interface IRawEquipment {
  id: number;
  name: string;
}

class EquipmentConverter implements IDataConverter<IRawEquipment, IEquipment> {
  convert(from: IRawEquipment): IEquipment {
    return {
      id: from.id || 0,
      name: from.name || '',
    };
  }
}

export default EquipmentConverter;
