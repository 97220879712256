import { DealerStatus, IDealer } from '../../common/models/dealer';
import IDataConverter from '../../common/models/IDataConverter';
import TimeSlotConverter, { IRawTimeSlot } from './TimeSlotConverter';

export interface IRawManufacturer {
  erpId: string;
  name: string;
}
export interface IRawMarketplace {
  id: string | number;
  erpId: string;
  name: string;
  supportEmail: string;
  supportPhone: string;
  slaUrl: string;
  logoUrl: string;
  shopUrl: string;
  logistikportalAvailable: boolean;
  allowAcceptingOffersSdm: boolean;
  manufacturers: IRawManufacturer[];
  regionalMaxDistance: string | number;
  superregionalMaxistance: string | number;
  purchaseOffeDealerTimeout: string;
  deliveryTimeMin: string;
  deliveryTimeMax: string;
  deliveryTimePreparations: string;
  isSdmDealerSubscriptionAllowed: boolean;
  allowTimeslotsInSdm: boolean;
}
export interface IRawMarketplaceSetting {
  id: string | number;
  marketplaceErpId: string;
  manufacturerId: string | number;
  manufacturerName: string;
  dealerCode: string;
  isActive: boolean;
  isRegional: boolean;
  autoAcceptRegionalOffer: boolean;
  isSuperregional: boolean;
}
export interface IRawDealer {
  id: number;
  erpId: string;
  companyName: string;
  contact: string;
  address: string;
  zipCode: string;
  city: string;
  phone: string;
  mail: string;
  logistikportal_login_token: string;
  registered: boolean;
  manufacturerCode: string;
  isSuperRegional: boolean;
  status: string;
  timeSlots: IRawTimeSlot[];
  slaUrl: string;
  // eslint-disable-next-line
  marketplaces: IRawMarketplace[];
  settings: IRawMarketplaceSetting[];
}

class DealerConverter implements IDataConverter<IRawDealer, IDealer> {
  convert(from: IRawDealer): IDealer {
    const timeSlotConverter = new TimeSlotConverter();

    return {
      id: from.id || 0,
      erpId: from.erpId || '',
      companyName: from.companyName || '',
      contact: from.contact || '',
      address: from.address || '',
      zipCode: from.zipCode || '',
      city: from.city || '',
      phone: from.phone || '',
      mail: from.mail || '',
      logistikportal_login_token: from.logistikportal_login_token || '',
      registered: from.registered || false,
      manufacturerCode: from.manufacturerCode || '',
      isSuperRegional: from.isSuperRegional || false,
      timeSlots: from.timeSlots ? from.timeSlots.map(timeSlotConverter.convert) : [],
      status: from.status && from.status === DealerStatus.ACTIVE ? DealerStatus.ACTIVE : DealerStatus.INACTIVE,
      slaUrl: from.slaUrl || '',
      marketplaces: from.marketplaces || [],
      marketplaceSettings: from.settings || [],
    };
  }
}

export default DealerConverter;
