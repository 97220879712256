import { ReactElement } from 'react';
import VehicleDetails, { IVehiclesDetailsProps } from '../../../modules/vehicleDetails/VehicleDetails';
import AccountSettings from '../../../modules/accountSettings/accountSettings';
import HelpPage from '../../../modules/help/help';
import ImprintPage from '../../../pages/ImprintPage';

export enum OverlayPages {
  DEFAULT = 'default',
  VEHICLE_DETAILS = 'vehicle_details',
  ACCOUNT_SETTINGS = 'account_settings',
  IMPRINT_PAGE = 'imprint_page',
  HELP_PAGE = 'help_page',
}

export type IOverlayProps = Record<string, unknown> | IVehiclesDetailsProps;

export const pages = <T extends IOverlayProps>(page: OverlayPages, props: T): ReactElement => {
  switch (page) {
    case OverlayPages.VEHICLE_DETAILS:
      return <VehicleDetails {...(props as IVehiclesDetailsProps)} />;

    case OverlayPages.ACCOUNT_SETTINGS:
      return <AccountSettings />;
    case OverlayPages.IMPRINT_PAGE:
      return <ImprintPage />;
    case OverlayPages.HELP_PAGE:
      return <HelpPage />;

    case OverlayPages.DEFAULT:
      return (
        <div>
          <p>This is just random content added in the overlay. Styles should be ignored for this text</p>
        </div>
      );
  }
};
