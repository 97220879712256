import { Close16 } from '@carbon/icons-react';
import React, { useEffect } from 'react';
import AppIcon from '../AppIcon/AppIcon';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { hide, getOverlayState } from '../../../redux/reducers/OverlaySlice';
import { pages } from '../Overlay/overlayPages';
import { useTheme } from '../../../utils/ThemeUtil';

import './Overlay.scss';

let isFirstLoad = true;

const Overlay: React.FC = () => {
  const state = useAppSelector(getOverlayState);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.classList?.contains('fp-overlay')) {
      dispatch(hide());
    }
  };

  useEffect(() => {
    isFirstLoad = false;

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const getClass = () => {
    if (isFirstLoad) return 'fp-overlay--first-load';

    return state.visible ? 'fp-overlay--visible' : 'fp-overlay--hidden';
  };

  return (
    <div className={`fp-overlay ${getClass()}`}>
      <div className={`fp-overlay__content ${!state.visible && !isFirstLoad && 'fp-overlay__content--hide'}`}>
        <div className="fp-overlay__content--container">
          <div className="fp-overlay__content--header">
            <h5>{state.headline}</h5>
            <div className={`fp-overlay__content--header__logo overlaylogo ${theme.t('theme_slug')}`}></div>
          </div>

          <div className="fp-overlay__close-wrapper" onClick={() => dispatch(hide())}>
            <AppIcon extraClass="fp-overlay__close-icon" icon={Close16} />
          </div>

          {pages(state.content, state.contentProps)}
        </div>
      </div>
    </div>
  );
};

export default Overlay;
