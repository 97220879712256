import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOverlayProps, OverlayPages } from '../../common/components/Overlay/overlayPages';
import { Overlay } from '../reducerNames';
import { RootState } from '../store';

export interface IOverlayState {
  visible: boolean;
  content: OverlayPages;
  contentProps: IOverlayProps;
  headline: string;
}

interface IShowPayload {
  content: OverlayPages;
  contentProps: IOverlayProps;
  headline: string;
}

const initialState: IOverlayState = {
  visible: false,
  content: OverlayPages.DEFAULT,
  contentProps: {},
  headline: '',
};

export const overlaySlice = createSlice({
  name: Overlay,
  initialState,
  reducers: {
    show: (state, action: PayloadAction<IShowPayload>) => {
      state.visible = true;
      state.content = action.payload.content;
      state.contentProps = { ...action.payload.contentProps };
      state.headline = action.payload.headline;
    },
    hide: state => {
      state.visible = false;
      state.contentProps = {};
      state.content = OverlayPages.DEFAULT;
      state.headline = '';
    },
  },
});

export const { show, hide } = overlaySlice.actions;

export const getOverlayState = (state: RootState): IOverlayState => state.overlayReducer;

export default overlaySlice.reducer;
