// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global ReturnType, unknown */
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authenticationReducer from './reducers/AuthenticationSlice';
import vehicleReducer from './reducers/VehiclesSlice';
import overlayReducer from './reducers/OverlaySlice';
import notificationReducer from './reducers/NotificationsSlice';
import detailsReducers from './reducers/VehicleDetailsSlice';
import vehicleFileReducer from './reducers/VehicleFileSlice';
import vehicleTemplateReducer from './reducers/VehicleTemplateSlice';
import accountSettings from './reducers/AccountSettingsSlice';
import releaseVehicleTemplateReducer from './reducers/ReleaseVehicleTemplateSlice';
import subscriptionReducer from './reducers/SubscriptionSlice';
import conditionReducer from './reducers/ConditionSlice';
import appointmentReducer from './reducers/VehicleAppointmentSlice';
import dashboardReducer from './reducers/DashboardSlice';
import purchaseReducer from './reducers/PurchaseSlice';
import timeSlotsReducer from './reducers/TimeSlotsSlice';

export const store = configureStore({
  reducer: {
    authenticationReducer,
    vehicleReducer,
    overlayReducer,
    notificationReducer,
    detailsReducers,
    vehicleFileReducer,
    vehicleTemplateReducer,
    accountSettings,
    releaseVehicleTemplateReducer,
    subscriptionReducer,
    conditionReducer,
    appointmentReducer,
    dashboardReducer,
    purchaseReducer,
    timeSlotsReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
