import { DatePicker, DatePickerInput } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import './FilterByDate.scss';

export interface IFilterByDate {
  onFilterChange: (selectedRangeDate: Date[]) => void;
}

const FilterByDate: React.FC<IFilterByDate> = (props: IFilterByDate) => {
  const { t } = useTranslation('filter-by-date');
  const { onFilterChange } = props;

  return (
    <div className="app-filter-by-date">
      <DatePicker
        allowInput={false}
        className="app-date-picker"
        datePickerType="range"
        locale="de"
        dateFormat="m.d.Y"
        onChange={e => {
          onFilterChange(e);
        }}
      >
        <DatePickerInput id="date-picker-input-id-start" placeholder={`${t('mm_dd_yyyy')}`} labelText={`${t('start_date')}`} />
        <DatePickerInput id="date-picker-input-id-finish" placeholder={`${t('mm_dd_yyyy')}`} labelText={`${t('end_date')}`} />
      </DatePicker>
    </div>
  );
};

export default FilterByDate;
