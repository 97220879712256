import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDashboardItem } from '../../common/models/dashboard';
import { DataStatus } from '../../common/models/dataStatus';
import DashboardConverter, { IRawDashboardItem } from '../../utils/converters/DashboardConverter';
import HttpUtil from '../../utils/HttpUtil';
import { Dashboard } from '../reducerNames';
import { RootState } from '../store';

export interface DashboardState {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
  dashboardData: IDashboardItem[];
}

const initialState: DashboardState = {
  status: DataStatus.IDLE,
  dashboardData: [],
};

export const getDashboardData = createAsyncThunk<DashboardState>(`${Dashboard}/fetchDasboardData`, async (): Promise<DashboardState> => {
  try {
    const response = await HttpUtil.get<IRawDashboardItem[]>(`api/v1/dashboard`);
    const converter = new DashboardConverter();
    const dashboardData = response.data && response.data.length ? response.data.map(d => converter.convert(d)) : [];

    return { dashboardData, status: DataStatus.IDLE };
  } catch (err) {
    console.error('Backend not found', err);
    return { dashboardData: initialState.dashboardData, status: DataStatus.FAILED };
  }
});

export const dashboardSlice = createSlice({
  name: Dashboard,
  initialState,
  reducers: {
    // reducers go here
  },
  extraReducers: builder => {
    builder
      .addCase(getDashboardData.pending, state => {
        state.status = DataStatus.LOADING;
        state.dashboardData = [...initialState.dashboardData];
      })
      .addCase(getDashboardData.fulfilled, (state, action: PayloadAction<DashboardState>) => {
        state.status = action.payload.status;
        state.dashboardData = action.payload.dashboardData.length ? [...action.payload.dashboardData] : [];
      });
  },
});

export const getDashboardState = (state: RootState): DashboardState => state.dashboardReducer;

export default dashboardSlice.reducer;
