import IDataConverter from '../../common/models/IDataConverter';
import { PurchaseStatus } from '../../common/models/purchase';
import { FuelType, IVehicleDetails, ISubscriptionVehicleDetails } from '../../common/models/vehicleDetails';
import { VehiclesStatus } from '../../common/models/vehicles';
import AppointmentConverter, { IRawAppointment } from './AppointmentConverter';
import EquipmentConverter, { IRawEquipment } from './EquipmentConverter';
import MediaConverter, { IRawMedia } from './MediaConverter';
import ConditionConverter, { IRawCondition } from './ConditionConverter';

export interface IRawVehicleDetails {
  idTemplate: number;
  idSubscription: number;
  idPurchase: number;
  number: string;
  description: string | null;
  vin: string;
  orbit: string;
  plateNumber: string;
  manufacturer: string;
  model: string;
  variant: string;
  fuel: string;
  transmission: string;
  status: 'string';
  purchaseStatus: 'string';
  bodyType: string;
  color: string;
  cubicCapacityLitres: string;
  power: string;
  powerKw: string;
  fuelConsumptionCombined: string;
  fuelConsumptionInTown: string;
  fuelConsumptionOutOfTown: string;
  electricRange: string;
  co2EmissionValue: string;
  co2EmissionClass: string;
  co2EfficiencyClass: string;
  equipments: IRawEquipment[];
  media: IRawMedia[];
  appointments: IRawAppointment[];
}

export interface IRawSubscriptionVehicleDetails extends IRawVehicleDetails{
  conditions: IRawCondition[];
}

class VehicleDetailsConverter implements IDataConverter<IRawVehicleDetails, IVehicleDetails> {
  private toVehicleDetailsStatus = (value: string): VehiclesStatus => {
    switch (value) {
      case VehiclesStatus.RELEASE:
        return VehiclesStatus.RELEASE;
      case VehiclesStatus.ACCEPTED:
        return VehiclesStatus.ACCEPTED;

      case VehiclesStatus.MARKETPLACE:
        return VehiclesStatus.MARKETPLACE;

      case VehiclesStatus.OFFER:
        return VehiclesStatus.OFFER;

      case VehiclesStatus.HANDOVER:
        return VehiclesStatus.HANDOVER;
      case VehiclesStatus.RETURN:
        return VehiclesStatus.RETURN;
      case VehiclesStatus.AT_CUSTOMER:
        return VehiclesStatus.AT_CUSTOMER;
      case VehiclesStatus.COMPLETED:
        return VehiclesStatus.COMPLETED;
      case VehiclesStatus.OTHER:
        return VehiclesStatus.OTHER;

      default:
        return VehiclesStatus.UNKNOWN;
    }
  };
  toPurchaseStatus = (value: string): PurchaseStatus => {
    switch (value) {
      case PurchaseStatus.PENDING:
        return PurchaseStatus.PENDING;

      case PurchaseStatus.DECLINED:
        return PurchaseStatus.DECLINED;

      case PurchaseStatus.ACCEPTED:
        return PurchaseStatus.ACCEPTED;

      case PurchaseStatus.SCHEDULED_FOR_PICK_UP:
        return PurchaseStatus.SCHEDULED_FOR_PICK_UP;

      case PurchaseStatus.READY_FOR_PICK_UP:
        return PurchaseStatus.READY_FOR_PICK_UP;

      case PurchaseStatus.PICKED_UP:
        return PurchaseStatus.PICKED_UP;

      case PurchaseStatus.SCHEDULED_FOR_RETURN:
        return PurchaseStatus.SCHEDULED_FOR_RETURN;

      case PurchaseStatus.RETURNED:
        return PurchaseStatus.RETURNED;

      case PurchaseStatus.INVOICED:
        return PurchaseStatus.INVOICED;

      case PurchaseStatus.INVOICE_PAID:
        return PurchaseStatus.INVOICE_PAID;

      case PurchaseStatus.FINISHED:
        return PurchaseStatus.FINISHED;

      case PurchaseStatus.CANCELED:
        return PurchaseStatus.CANCELED;

      default:
        return PurchaseStatus.UNKNOWN;
    }
  };
  toFuelType = (value: string): FuelType => {
    switch (value) {
      case FuelType.DIESEL:
        return FuelType.DIESEL;

      case FuelType.ELECTRIC:
        return FuelType.ELECTRIC;

      case FuelType.HYBRID:
        return FuelType.HYBRID;

      case FuelType.MILD_HYBRID_DIESEL:
        return FuelType.MILD_HYBRID_DIESEL;

      case FuelType.MILD_HYBRID_GAS:
        return FuelType.MILD_HYBRID_GAS;

      case FuelType.NATURAL_GAS:
        return FuelType.NATURAL_GAS;

      case FuelType.PETROL:
        return FuelType.PETROL;

      case FuelType.PLUG_IN_HYBRID:
        return FuelType.PLUG_IN_HYBRID;

      default:
        return FuelType.UNKNOWN;
    }
  };

  convert(from: IRawSubscriptionVehicleDetails): ISubscriptionVehicleDetails {
   
    const mediaConverter = new MediaConverter();
    const equipmentConverter = new EquipmentConverter();
    const appointmentConverter = new AppointmentConverter();
    const conditionConverter = new ConditionConverter();

    return {
      idTemplate: from.idTemplate || 0,
      idSubscription: from.idSubscription || 0,
      idPurchase: from.idPurchase || 0,
      number: from.number || '',
      description: from.description,
      vin: from.vin || '',
      orbit: from.orbit || '',
      plateNumber: from.plateNumber || '',
      manufacturer: from.manufacturer || '',
      model: from.model || '',
      variant: from.variant || '',
      fuel: this.toFuelType(from.fuel),
      transmission: from.transmission || '',
      status: this.toVehicleDetailsStatus(from.status || ''),
      purchaseStatus: this.toPurchaseStatus(from.purchaseStatus || ''),
      bodyType: from.bodyType || '',
      color: from.color || '',
      cubicCapacityLitres: from.cubicCapacityLitres || '',
      power: from.power || '',
      powerKw: from.powerKw || '',
      fuelConsumptionCombined: from.fuelConsumptionCombined || '',
      fuelConsumptionInTown: from.fuelConsumptionInTown || '',
      fuelConsumptionOutOfTown: from.fuelConsumptionOutOfTown || '',
      electricRange: from.electricRange || '',
      co2EmissionValue: from.co2EmissionValue || '',
      co2EmissionClass: from.co2EmissionValue || '',
      co2EfficiencyClass: from.co2EfficiencyClass || '',
      equipments: from.equipments ? from.equipments.map(equipmentConverter.convert) : [],
      conditions: from.conditions ? from.conditions.map(conditionConverter.convert) : [],
      media: from.media ? from.media.map(mediaConverter.convert) : [],
      appointments: from.appointments ? from.appointments.map(appointmentConverter.convert) : [],
    };
  }
}

export default VehicleDetailsConverter;
