export enum AppointmentAction {
  HAND_OVER = 'hand-over',
  RETURN = 'return',
  UNKNOWN = 'unknown',
}

export enum AppointmentStatus {
  REQUESTED = 'requested',
  DECLINED = 'declined',
  ACCEPTED = 'accepted',
  UNKNOWN = 'unknown',
}

export interface IAppointment {
  id: number;
  erpId: string;
  appointmentAt: Date | null;
  action: AppointmentAction;
  acceptedAt: Date | null;
  declinedAt: Date | null;
  status: AppointmentStatus;
}
