import { ButtonKind, InlineNotification, Loading } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import ListItems from '../../../../common/components/ListItems/ListItems';
import { OverlayPages } from '../../../../common/components/Overlay/overlayPages';
import { DataStatus } from '../../../../common/models/dataStatus';
import { IPurchase, PurchaseStatus} from '../../../../common/models/purchase';
import { IVehicleListItem, IMarketplaceVehicle, IOfferVehicle, IHandOverVehicle, VehiclesStatus, IReturnVehicle } from '../../../../common/models/vehicles';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { show } from '../../../../redux/reducers/OverlaySlice';
import { getVehiclesState } from '../../../../redux/reducers/VehiclesSlice';
import { IVehiclesDetailsProps } from '../../../../modules/vehicleDetails/VehicleDetails';
import './CalendarVehiclesBody.scss';
import { AppointmentAction, AppointmentStatus } from '../../../models/appointment';
import AppButton from '../../AppButton/AppButton';

interface CalendarVehiclesBodyProps {
  translatedHeadlines: { release: string; marketplace: string; offers: string; handovers: string; atCustomer: string; completed: string };
}

const handOverVehiclesMock: IHandOverVehicle[] = [
  {
    idTemplate: 1,
    idPurchase: 27,
    idSubscription: 7,
    image: '',
    manufacturer: 'Ford',
    model: 'Fiesta B479',
    variant: 'JEX-ST-LINE',
    fuel: 'Benzin',
    transmission: 'Schaltgetreibe',
    status: VehiclesStatus.HANDOVER,
    animation: 'none',
    details: {
      idVehicle: 13,
      vin: 'RPU-FI-asdasd',
      orbit: '',
      plateNumber: 'DE12TZK',
      tasks: 0,
      handOverDateTime: '13.05.2021 07:25',
      unparsedDate: '',
      status: PurchaseStatus.READY_FOR_PICK_UP,
      purchase: {} as IPurchase,
      appointments: [
        {
          erpId: '6app3d66-2dbc-11ec-8d3d-0242ac130003',
          appointmentAt: '2021-05-18T03:24:00.000Z' as unknown as Date,
          action: AppointmentAction.HAND_OVER,
          acceptedAt: null,
          declinedAt: null,
          status: AppointmentStatus.REQUESTED,
          id: 10,
        },
      ],
    },
  },
  {
    idTemplate: 1,
    idPurchase: 24,
    idSubscription: 7,
    image: '/img/mocks/Fiesta_ST-Line.png',
    manufacturer: 'Ford',
    model: 'Fiesta B479',
    variant: 'JEX-ST-LINE',
    fuel: 'Benzin',
    transmission: 'Schaltgetreibe',
    status: VehiclesStatus.HANDOVER,
    animation: 'none',
    details: {
      idVehicle: 14,
      vin: 'RPU-FI-Z12343',
      orbit: '',
      plateNumber: 'DE12TZK',
      tasks: 0,
      handOverDateTime: '13.05.2021 07:25',
      unparsedDate: '',
      status: PurchaseStatus.READY_FOR_PICK_UP,
      purchase: {} as IPurchase,
      appointments: [
        {
          erpId: '6app3d66-2dbc-11ec-8d3d-0242ac130003',
          appointmentAt: '2021-05-18T03:24:00.000Z' as unknown as Date,
          action: AppointmentAction.HAND_OVER,
          acceptedAt: null,
          declinedAt: null,
          status: AppointmentStatus.REQUESTED,
          id: 10,
        },
      ],
    },
  },
  {
    idTemplate: 1,
    idPurchase: 27,
    idSubscription: 7,
    image: '',
    manufacturer: 'Ford',
    model: 'Fiesta B479',
    variant: 'JEX-ST-LINE',
    fuel: 'Benzin',
    transmission: 'Schaltgetreibe',
    status: VehiclesStatus.HANDOVER,
    animation: 'none',
    details: {
      idVehicle: 13,
      vin: 'RPU-FI-asdasd',
      orbit: '',
      plateNumber: 'DE12TZK',
      tasks: 0,
      handOverDateTime: '13.05.2022 07:25',
      unparsedDate: '',
      status: PurchaseStatus.SCHEDULED_FOR_PICK_UP,
      purchase: {} as IPurchase,
      appointments: [
        {
          erpId: '6app3d66-2dbc-11ec-8d3d-0242ac130003',
          appointmentAt: '2021-05-18T03:24:00.000Z' as unknown as Date,
          action: AppointmentAction.HAND_OVER,
          acceptedAt: null,
          declinedAt: null,
          status: AppointmentStatus.ACCEPTED,
          id: 10,
        },
      ],
    },
  },
];

const offerVehiclesMock: IOfferVehicle[] = [
  {
    idTemplate: 1,
    idSubscription: 7,
    idPurchase: 23,
    image: '',
    manufacturer: 'OFFER',
    model: 'OFFER',
    variant: 'JEX-ST-LINE',
    fuel: 'Benzin',
    transmission: 'Schaltgetreibe',
    status: VehiclesStatus.OFFER,
    animation: 'none',
    details: {
      vin: 'NOT AVAILABLE',
      orbit: 'NOT AVAILABLE',
      plateNumber: 'NOT AVAILABLE',
      offerNumber: 'NOT AVAILABLE',
      status: PurchaseStatus.PENDING,
      unparsedDate: '',
      createdDateTime: '13.06.2021 07:25',
      purchase: {} as IPurchase,
    },
  },
];
const returnVehiclesMock: IReturnVehicle[] = [
  {
    idTemplate: 2,
    idPurchase: 28,
    idSubscription: 8,
    image: '/img/mocks/Fiesta_ST-Line.png',
    manufacturer: 'Ford',
    model: 'Mondeo',
    variant: 'Titanium',
    fuel: 'Benzin',
    transmission: 'Schaltgetreibe',
    status: VehiclesStatus.RETURN,
    animation: 'none',
    details: {
      idVehicle: 16,
      vin: 'RT-Z12343235',
      orbit: '',
      plateNumber: 'DE12AAA',
      tasks: 0,
      returnDateTime: '17.05.2021 03:24',
      status: PurchaseStatus.SCHEDULED_FOR_RETURN,
      purchase: {} as IPurchase,
      appointments: [
        {
          erpId: '6app3d66-2dbc-11ec-8d3d-0242ac130003',
          appointmentAt: '2021-05-18T03:24:00.000Z' as unknown as Date,
          action: AppointmentAction.RETURN,
          acceptedAt: null,
          declinedAt: null,
          status: AppointmentStatus.ACCEPTED,
          id: 9,
        },
      ],
      unparsedDate: '2021-05-17T03:24:00.000Z',
    },
  },
  {
    idTemplate: 1,
    idPurchase: 29,
    idSubscription: 9,
    image: '/img/mocks/Fiesta_ST-Line.png',
    manufacturer: 'Ford',
    model: 'Fiesta B479',
    variant: 'JEX-ST-LINE',
    fuel: 'Benzin',
    transmission: 'Schaltgetreibe',
    status: VehiclesStatus.RETURN,
    animation: 'none',
    details: {
      idVehicle: 17,
      vin: 'IN-Z12343235',
      orbit: '',
      plateNumber: 'DE12BBB',
      tasks: 0,
      returnDateTime: '17.07.2021 03:24',
      status: PurchaseStatus.RETURNED,
      purchase: {} as IPurchase,
      appointments: [
        {
          erpId: '6app3d66-2dbc-11ec-8d3d-0242ac130003',
          appointmentAt: '2021-07-19T03:24:00.000Z' as unknown as Date,
          action: AppointmentAction.RETURN,
          acceptedAt: null,
          declinedAt: null,
          status: AppointmentStatus.REQUESTED,
          id: 9,
        },
      ],
      unparsedDate: '2021-07-17T03:24:00.000Z',
    },
  },
];

const CalendarVehiclesBody: React.FC<CalendarVehiclesBodyProps> = () => {
  const { t } = useTranslation('calendar-vehicles');
  const state = useAppSelector(getVehiclesState);
  const dispatch = useAppDispatch();
  const { status } = state;
  const onShowDetails = (status: string, item: IVehicleListItem, idVehicle: number) => {
    const contentProps: IVehiclesDetailsProps = { idVehicleTemplate: item.idTemplate, selectedTab: 'details' };

    if (status !== 'release') {
      contentProps.idSubscription = (item as IMarketplaceVehicle).idSubscription;
    }

    if (status !== 'release' && status !== 'marketplace') {
      contentProps.idPurchase = (item as IOfferVehicle).idPurchase;
    }
    if (idVehicle !== 0) {
      contentProps.idVehicle = idVehicle;
    }

    const headline = `${item.manufacturer} ${item.model} ${item.variant}`;

    dispatch(show({ headline, content: OverlayPages.VEHICLE_DETAILS, contentProps }));
  };

  const datePattern = /(\d{2})\.(\d{2})\.(\d{4})/;

  const vehiclesHandoverDates = handOverVehiclesMock.map(item => item.details.handOverDateTime);
  const vehiclesOfferDates = offerVehiclesMock.map(item => item.details.createdDateTime);
  const vehiclesReturnDates = returnVehiclesMock.map(item => item.details.returnDateTime);

  const allVehiclesDates = [...vehiclesOfferDates, ...vehiclesHandoverDates, ...vehiclesReturnDates].sort(function (a: string, b: string) {
    const c = new Date(a.substring(0, a.lastIndexOf(' ')).replace(datePattern, '$3.$2.$1'));
    const d = new Date(b.substring(0, b.lastIndexOf(' ')).replace(datePattern, '$3.$2.$1'));
    return c.getTime() - d.getTime();
  });

  const vehiclesDatesMonthYear = allVehiclesDates.map(item =>
    new Date(item.substring(0, item.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
      .toLocaleString('de-DE', { month: 'long', year: 'numeric' })
      .toLowerCase(),
  );

  const vehiclesDatesDayMonthYear = allVehiclesDates.map(item =>
    new Date(item.substring(0, item.lastIndexOf(' ')).replace(datePattern, '$3.$2.$1')).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }),
  );

  const uniqVehiclesDatesMonthYear = vehiclesDatesMonthYear.reduce(function (a: string[], b: string) {
    if (a.indexOf(b) < 0) a.push(b);
    return a;
  }, []);
  const uniqVehiclesDatesDayMonthYear = vehiclesDatesDayMonthYear.reduce(function (a: string[], b: string) {
    if (a.indexOf(b) < 0) a.push(b);
    return a;
  }, []);

  type IVehiclesHandOverGroupedByDayMonthYear = {
    [key: string]: IHandOverVehicle[];
  };

  const vehiclesHandOverGroupedByDayMonthYear = handOverVehiclesMock.reduce((acc: IVehiclesHandOverGroupedByDayMonthYear, item) => {
    acc[
      new Date(item.details.handOverDateTime.substring(0, item.details.handOverDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
        .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .toLowerCase()
    ] =
      acc[
        new Date(item.details.handOverDateTime.substring(0, item.details.handOverDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
          .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
          .toLowerCase()
      ] || [];
    acc[
      new Date(item.details.handOverDateTime.substring(0, item.details.handOverDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
        .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .toLowerCase()
    ].push(item);
    return acc;
  }, {});

  type IVehiclesOfferGroupedByDayMonthYear = {
    [key: string]: IOfferVehicle[];
  };

  const vehiclesOfferGroupedByDayMonthYear = offerVehiclesMock.reduce((acc: IVehiclesOfferGroupedByDayMonthYear, item) => {
    acc[
      new Date(item.details.createdDateTime.substring(0, item.details.createdDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
        .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .toLowerCase()
    ] =
      acc[
        new Date(item.details.createdDateTime.substring(0, item.details.createdDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
          .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
          .toLowerCase()
      ] || [];
    acc[
      new Date(item.details.createdDateTime.substring(0, item.details.createdDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
        .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .toLowerCase()
    ].push(item);
    return acc;
  }, {});

  type IVehiclesReturnGroupedByDayMonthYear = {
    [key: string]: IReturnVehicle[];
  };

  const vehiclesReturnGroupedByDayMonthYear = returnVehiclesMock.reduce((acc: IVehiclesReturnGroupedByDayMonthYear, item) => {
    acc[
      new Date(item.details.returnDateTime.substring(0, item.details.returnDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
        .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .toLowerCase()
    ] =
      acc[
        new Date(item.details.returnDateTime.substring(0, item.details.returnDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
          .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
          .toLowerCase()
      ] || [];
    acc[
      new Date(item.details.returnDateTime.substring(0, item.details.returnDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
        .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .toLowerCase()
    ].push(item);
    return acc;
  }, {});

  const allVehiclesGrouped = Object.assign(vehiclesOfferGroupedByDayMonthYear, vehiclesHandOverGroupedByDayMonthYear, vehiclesReturnGroupedByDayMonthYear);

  const renderCustomButton = (status: VehiclesStatus, detailsStatus: PurchaseStatus) => {
    let kind: ButtonKind | undefined;
    let text = '';
    if (status == VehiclesStatus.OFFER) {
      kind = 'primary';
      text = t('check_offer');
    }
    if (status == VehiclesStatus.HANDOVER && detailsStatus == PurchaseStatus.READY_FOR_PICK_UP) {
      kind = 'primary';
      text = t('make_an_appointment');
    }
    if (status == VehiclesStatus.HANDOVER && detailsStatus == PurchaseStatus.SCHEDULED_FOR_PICK_UP) {
      kind = 'secondary';
      text = t('handover_details');
    }
    if (status == VehiclesStatus.RETURN && detailsStatus == PurchaseStatus.RETURNED) {
      kind = 'primary';
      text = t('make_an_appointment');
    }
    if (status == VehiclesStatus.RETURN && detailsStatus == PurchaseStatus.SCHEDULED_FOR_RETURN) {
      kind = 'secondary';
      text = t('handover_details');
    }

    return <AppButton kind={kind}>{text}</AppButton>;
  };

  const renderCalendarVehicles = () => {
    return (
      <div className="calendar-body">
        {uniqVehiclesDatesMonthYear.map((monthYear: string, key: number) => (
          <div key={`handover-headline-${key}`}>
            <h3 className="month-year-headline">{monthYear}</h3>
            {uniqVehiclesDatesDayMonthYear.map((date: string, key: number) => {
              if (monthYear == new Date(date.replace(datePattern, '$3-$2-$1')).toLocaleString('de-DE', { month: 'long', year: 'numeric' }).toLowerCase()) {
                return (
                  <ListItems
                    status={DataStatus.IDLE}
                    key={`handover2-${key}`}
                    headline={date}
                    items={allVehiclesGrouped[date].map((item: IHandOverVehicle | IReturnVehicle) => ({
                      key: item.idPurchase,
                      button: renderCustomButton(item.status, item.details.status),
                      item: item,
                      onShowDetails: () => onShowDetails('hand-over', item, item.details.idVehicle),
                    }))}
                  />
                );
              }
            })}
          </div>
        ))}
      </div>
    );
  };

  const renderError = () => {
    return (
      <InlineNotification iconDescription="describes the close button" title={t('error_content_loaded') as string} notificationType="inline" kind="error" />
    );
  };

  const renderLoading = () => {
    return (
      <div className="home-list__loading">
        <Loading description="Loading list items" withOverlay={false} />
      </div>
    );
  };
  const render = () => {
    if (status === DataStatus.LOADING) {
      return renderLoading();
    }
    if (status === DataStatus.FAILED) {
      return renderError();
    }

    return renderCalendarVehicles();
  };
  return render();
};

export default CalendarVehiclesBody;
