import { IDashboardItem } from '../../models/dashboard';
import DashboardItem from './DashboardItem';
import './DashboardItemList.scss';

interface IDashboardItemList {
  dashboardItems: IDashboardItem[];
}

const DashboardItemList: React.FC<IDashboardItemList> = (props: IDashboardItemList) => {
  const { dashboardItems } = props;

  return (
    <div>
      {dashboardItems.length ? (
        <div className={`dashboard-items ${dashboardItems.length < 2 ? 'single' : 'double'}`}>
          {dashboardItems.map(item => (
            <div key={item.idVehicle}>
              <DashboardItem
                idPurchase={item.idPurchase}
                idSubscription={item.idSubscription}
                idVehicleTemplate={item.idVehicleTemplate}
                idVehicle={item.idVehicle}
                status={item.status}
                medias={item.medias}
                manufacturer={item.manufacturer}
                model={item.model}
                variant={item.variant}
                dueDate={item.dueDate}
                dueTime={item.dueTime}
                appointment={item.appointment}
              />
            </div>
          ))}
        </div>
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default DashboardItemList;
