import { TooltipDefinition, TooltipDefinitionProps } from 'carbon-components-react';

type AppTooltipProps = TooltipDefinitionProps & {
  maxNROfCharacters: number;
  tooltipText: string;
  className?: string;
};

export const AppTooltip: React.FC<AppTooltipProps> = (props: AppTooltipProps) => {
  const { maxNROfCharacters, tooltipText, className, ...rest } = props;
  if (tooltipText.length >= maxNROfCharacters) {
    if (typeof rest.children !== 'string') {
      const props = (rest.children as { props?: { children?: string } }).props;
      rest.children = props?.children ?? '';
    }

    return <TooltipDefinition {...rest} tooltipText={tooltipText} className="app-tab" />;
  } else return <div className={className}>{tooltipText}</div>;
};

export default AppTooltip;
