import { ITimeSlot } from '../../common/models/dealer';
import IDataConverter from '../../common/models/IDataConverter';

export interface IRawTimeSlot {
  id: number;
  idDealer: number;
  from: string;
  until: string;
  dayOfWeek: number;
  occupied: boolean;
  active: boolean;
}

class TimeSlotConverter implements IDataConverter<IRawTimeSlot, ITimeSlot> {
  isDayOfWeek(day: number): boolean {
    return day >= 0 || day <= 6;
  }

  convert(from: IRawTimeSlot): ITimeSlot {
    return {
      id: from.id || 0,
      from: from.from || '',
      until: from.until || '',
      dayOfWeek: this.isDayOfWeek(from.dayOfWeek) ? from.dayOfWeek : -1,
      occupied: from.occupied || false,
      active: from.active || false,
    };
  }
}

export default TimeSlotConverter;
