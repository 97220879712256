import { IAppointment } from './appointment';

export interface IDashboardItemMedia {
  name: string;
  path: string;
  type: string;
  id: number;
  createdAt: Date;
  updatedAt: Date;
}

export enum DashboardStatus {
  PENDING = 'pending',
  SCHEDULED_FOR_PICK_UP = 'scheduled-for-pick-up',
  READY_FOR_PICK_UP = 'ready-for-pick-up',
  SCHEDULED_FOR_RETURN = 'scheduled-for-return',
  UNKNOWN = 'unknown', //fallback in case erp adds new status
}

export interface IDashboardItem {
  idPurchase: number;
  idSubscription: number;
  idVehicleTemplate: number;
  idVehicle: number;
  status: DashboardStatus;
  medias: IDashboardItemMedia[];
  manufacturer: string;
  model: string;
  variant: string;
  dueDate: string;
  dueTime: string;
  appointment: IAppointment | null;
}
