import { ReactElement } from 'react';
import ListItem from '../ListItem/ListItem';
import {
  IAtCustomerVehicle,
  ICompletedVehicle,
  IHandOverVehicle,
  IMarketplaceVehicle,
  IOfferVehicle,
  IReleaseVehicle,
  IReturnVehicle,
} from '../../models/vehicles';
import './ListItems.scss';
import { useTranslation } from 'react-i18next';
import { IHistoryVehicle } from '../../../modules/history/History';
import { IAnimation } from '../../../redux/reducers/VehiclesSlice';
import { DataStatus } from '../../models/dataStatus';
import { Loading } from 'carbon-components-react';

interface ListItem {
  item: IReleaseVehicle | IMarketplaceVehicle | IOfferVehicle | IHandOverVehicle | IAtCustomerVehicle | ICompletedVehicle | IReturnVehicle | IHistoryVehicle;
  button: ReactElement;
  animation?: IAnimation;
  onShowDetails: () => void;
  page?: string;
}

interface ListItemsProps {
  items: ListItem[];
  headline: string;
  status?: DataStatus;
  emptyListAnimation?: 'show' | 'hide' | 'none';
}

const ListItems: React.FC<ListItemsProps> = (props: ListItemsProps) => {
  const { t } = useTranslation('vehicles');
  const { items, headline, status, emptyListAnimation } = props;

  const groupByCollection = (itemCollection: ListItem[]): ListItem[][] => {
    const items: ListItem[][] = [];
    const appointmentItemArray: Array<ListItem> = [];
    const purchaseItemArray: Array<ListItem> = [];

    itemCollection.forEach(item => {
      if (item?.item?.details?.appointments.length > 0) {
        appointmentItemArray.push(item);
      } else {
        purchaseItemArray.push(item);
      }
    });
    items.push(appointmentItemArray);
    items.push(purchaseItemArray);
    return items;
  };

  const sortItems = (itemCollection: ListItem[], heading: string): ListItem[] => {
    if (heading == 'Abholung') {
      const newItemCollection: ListItem[][] = groupByCollection(itemCollection);
      const appointmentItemCollection: ListItem[] = [...newItemCollection[0]];
      const purchaseItemCollection: ListItem[] = [...newItemCollection[1]];

      const sortedItemsAppointment = [...appointmentItemCollection].sort(function (a, b): number {
        const dateOne: Date = new Date(a?.item.details.appointments[0].appointmentAt);
        const dateTwo: Date = new Date(b?.item.details.appointments[0].appointmentAt);
        return dateOne.getTime() - dateTwo.getTime();
      });

      const sortedItemsPurchase = [...purchaseItemCollection].sort(function (a, b): number {
        const purchaseDateOne: Date = new Date(a?.item.details.purchase.estimatedPickUp);
        const purchaseDateTwo: Date = new Date(b?.item.details.purchase.estimatedPickUp);
        return purchaseDateOne.getTime() - purchaseDateTwo.getTime();
      });

      const itemCollectionArray: ListItem[] = [...sortedItemsPurchase, ...sortedItemsAppointment];
      return itemCollectionArray;
    }
    return itemCollection;
  };

  return (
    <div className="list-items">
      <div className="list-items__headline fp--productive-heading-01">{headline}</div>
      <div className="list-items__children">
        {items.length ? (
          sortItems(items, headline).map(item => (
            <ListItem onShowDetails={item.onShowDetails} key={item.item.idTemplate} item={item.item} button={item.button} page={item.page} />
          ))
        ) : (
          <div className={`list-items__children--empty list-items__children--empty--${emptyListAnimation}`}>{t('no_vehicles_available')}</div>
        )}
        {status === DataStatus.LOADING && (
          <div className="loading-list-items">
            <Loading description="Loading list items" withOverlay={false} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ListItems;
