import vehiclesJson from '../assets/mocks/vehicles.json';
import vehicleDetailsJson from '../assets/mocks/vehicleDetails.json';
import vehicleFileJson from '../assets/mocks/vehicleFile.json';
import appointmentJson from '../assets/mocks/vehicleAppointment.json';
import dashboardJson from '../assets/mocks/dashboard.json';

class MockUtil {
  static get<T>(mock: string): Promise<{ data: T }> {
    if (mock === 'api/v1/vehicle') {
      return Promise.resolve({ data: vehiclesJson as unknown as T });
    }
    if (mock === 'api/v1/vehicle-details') {
      return Promise.resolve({ data: vehicleDetailsJson as unknown as T });
    }
    if (mock === 'api/v1/vehicleFile') {
      return Promise.resolve({ data: vehicleFileJson as unknown as T });
    }
    if (mock.includes('api/v1/appointment/')) {
      return Promise.resolve({ data: appointmentJson as unknown as T });
    }
    if (mock === 'api/v1/dashboard') {
      return Promise.resolve({ data: dashboardJson as unknown as T });
    }

    return Promise.resolve({ data: {} as T });
  }
}

export default MockUtil;
