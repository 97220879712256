import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { DataStatus } from '../../common/models/dataStatus';
import { ITimeSlot } from '../../common/models/dealer';
import { ICodeResponse } from '../../common/models/ICodeResponse';
import TimeSlotConverter, { IRawTimeSlot } from '../../utils/converters/TimeSlotConverter';
import HttpUtil from '../../utils/HttpUtil';
import { TimeSlots } from '../reducerNames';
import { RootState } from '../store';
import { removeDealerTimeSlots, updateDealerTimeSlots } from './AuthenticationSlice';
import { INotificationState, showNotification } from './NotificationsSlice';

const getNotification = (code: number): INotificationState => {
  if (code === 200) {
    return {
      type: 'success',
      title: i18next.t('success'),
      subtitle: i18next.t('timeslot_updated'),
      caption: '',
    };
  } else if (code === 201) {
    return {
      type: 'warning',
      title: i18next.t('warning'),
      subtitle: i18next.t('timeslot_updated_but_ERP_system_not_updated4'),
      caption: '',
    };
  }

  return {
    type: 'error',
    title: i18next.t('error'),
    subtitle: i18next.t('timeslot_not_updated'),
    caption: '',
  };
};

export interface TimeSlotsState {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
  timeSlots: ITimeSlot[];
}

const initialState: TimeSlotsState = {
  status: DataStatus.IDLE,
  timeSlots: [],
};

interface IGetTimeSlots {
  timeSlots: ITimeSlot[];
  status: DataStatus;
}

export const getTimeSlots = createAsyncThunk<TimeSlotsState>(`${TimeSlots}/getTimeSlots`, async (): Promise<TimeSlotsState> => {
  try {
    const url = `api/v1/time-slot`;

    const response = await HttpUtil.get<IGetTimeSlots>(url);

    return { timeSlots: response.data.timeSlots, status: DataStatus.IDLE };
  } catch (err) {
    console.error('Backend not found', err);
    return { timeSlots: initialState.timeSlots, status: DataStatus.FAILED };
  }
});

export interface IAddTimeSlotParams {
  idDealer: number;
  from: string;
  until: string;
  dayOfWeek: number;
  active: boolean;
}

interface ITimeSlotAddPayload {
  timeSlot: ITimeSlot | null;
  status: DataStatus;
}

interface ITimeSlotAddResponse extends ICodeResponse {
  timeSlot: IRawTimeSlot;
}

export const addTimeSlot = createAsyncThunk<ITimeSlotAddPayload, IAddTimeSlotParams>(
  `${TimeSlots}/postTimeSlot`,
  async (params: IAddTimeSlotParams, thunkAPI): Promise<ITimeSlotAddPayload> => {
    try {
      const url = `api/v1/time-slot`;

      const response = await HttpUtil.post<ITimeSlotAddResponse>(url, params);

      thunkAPI.dispatch(showNotification(getNotification(response.data.code)));
      const converter = new TimeSlotConverter();
      if (response.data.code === 201 || response.data.code === 200) {
        const converted = converter.convert(response.data.timeSlot);
        //update dealer time slots
        thunkAPI.dispatch(updateDealerTimeSlots({ timeSlot: converted }));
        return { timeSlot: converted, status: DataStatus.IDLE };
      } else {
        return { timeSlot: null, status: DataStatus.FAILED };
      }
    } catch (err) {
      console.error('Backend not found', err);
      thunkAPI.dispatch(showNotification(getNotification(0)));
      return { timeSlot: null, status: DataStatus.FAILED };
    }
  },
);

interface ITimeSlotDeletePayload {
  idTimeSlot: number;
  status: DataStatus;
}

interface IDeleteTimeSlotParams {
  idTimeSlot: number;
}

export const deleteTimeSlot = createAsyncThunk<ITimeSlotDeletePayload, IDeleteTimeSlotParams>(
  `${TimeSlots}/deleteTimeSlot`,
  async (params: IDeleteTimeSlotParams, thunkAPI): Promise<ITimeSlotDeletePayload> => {
    try {
      const url = `api/v1/time-slot/${params.idTimeSlot}`;

      const response = await HttpUtil.delete<ICodeResponse>(url, {});

      thunkAPI.dispatch(showNotification(getNotification(response.data.code)));

      if (response.data.code === 200) {
        //update dealer time slots
        thunkAPI.dispatch(removeDealerTimeSlots(params));
        return { idTimeSlot: params.idTimeSlot, status: DataStatus.IDLE };
      } else {
        return { idTimeSlot: 0, status: DataStatus.FAILED };
      }
    } catch (err) {
      console.error('Backend not found', err);
      thunkAPI.dispatch(showNotification(getNotification(0)));
      return { idTimeSlot: 0, status: DataStatus.FAILED };
    }
  },
);

export const TimeSlotsSlice = createSlice({
  name: TimeSlots,
  initialState,
  reducers: {
    // reducers go here
  },
  extraReducers: builder => {
    builder
      .addCase(getTimeSlots.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(getTimeSlots.fulfilled, (state, action: PayloadAction<TimeSlotsState>) => {
        state.status = action.payload.status;
        state.timeSlots = action.payload.timeSlots.filter(t => t.active);
      })
      .addCase(addTimeSlot.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(addTimeSlot.fulfilled, (state, action: PayloadAction<ITimeSlotAddPayload>) => {
        state.status = action.payload.status;

        if (action.payload.status === DataStatus.IDLE) {
          state.timeSlots = action.payload.timeSlot ? [...state.timeSlots, action.payload.timeSlot] : state.timeSlots;
        }
      })

      .addCase(deleteTimeSlot.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(deleteTimeSlot.fulfilled, (state, action: PayloadAction<ITimeSlotDeletePayload>) => {
        state.status = action.payload.status;
        state.timeSlots = state.timeSlots.filter(item => item.id !== action.payload.idTimeSlot);
      });
  },
});

export const getTimeSlotState = (state: RootState): TimeSlotsState => state.timeSlotsReducer;

export default TimeSlotsSlice.reducer;
