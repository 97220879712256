import './ImageSlider.scss';

import React from 'react';
import DDSCarousel from '@carbon/ibmdotcom-web-components/es/components-react/carousel/carousel';
import Image, { ImageProps } from '../Image/Image';

interface ImageSliderProps {
  images: ImageProps[];
}

const ImageSlider: React.FC<ImageSliderProps> = (props: ImageSliderProps) => {
  const { images } = props;
  return (
    <div className="image-slider">
      <DDSCarousel>
        {images.map((item, index) => (
          <Image key={index} link={item.link} size="large" caption={item.caption} alt={item.alt} />
        ))}
      </DDSCarousel>
    </div>
  );
};

export default ImageSlider;
