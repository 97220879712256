import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMarketplace, ITimeSlot } from '../../models/dealer';
import './RegisterTimeSlots.scss';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { addTimeSlot, deleteTimeSlot, getTimeSlotState } from '../../../redux/reducers/TimeSlotsSlice';
import { DataStatus } from '../../models/dataStatus';
import { Loading } from 'carbon-components-react';

interface IRegisterTimeSlotsProps {
  idDealer: number;
  marketplaces: IMarketplace[];
}

const RegisterTimeSlots: React.FC<IRegisterTimeSlotsProps> = (props: IRegisterTimeSlotsProps) => {
  const { t } = useTranslation('register');
  const { idDealer, marketplaces } = props;
  const dispatch = useAppDispatch();
  const state = useAppSelector(getTimeSlotState);
  const { status, timeSlots } = state;

  const initActions = [
    { morning: false, afternoon: false },
    { morning: false, afternoon: false },
    { morning: false, afternoon: false },
    { morning: false, afternoon: false },
    { morning: false, afternoon: false },
    { morning: false, afternoon: false },
  ];
  const [actions, setActions] = useState(initActions);

  const days = [
    { key: 0, name: t('monday') },
    { key: 1, name: t('tuesday') },
    { key: 2, name: t('wednesday') },
    { key: 3, name: t('thursday') },
    { key: 4, name: t('friday') },
    { key: 5, name: t('saturday') },
  ];

  const getActiveClass = (daySlot: ITimeSlot): string => {
    return `time-slot-time fp--body-long-01 ${daySlot.active ? 'time-slot--active' : 'time-slot--inactive'} ${
      status === DataStatus.LOADING && 'time-slot--disabled'
    }`;
  };

  const getDayText = (daySlot: ITimeSlot): string => {
    return `${daySlot.from} - ${daySlot.until}`;
  };

  const disableTimeslotCheckBox = () => {
    const allowSelectTimeSlotsToDealer = marketplaces.find(marketplace => marketplace.allowTimeslotsInSdm);
    if (!allowSelectTimeSlotsToDealer) {
      return 'checkboxDisable timeslots--disabled';
    }
    return '';
  };

  const onUpdateTimeSlot = (slot: ITimeSlot, index: number, period: 'morning' | 'afternoon') => {
    if (status !== DataStatus.LOADING) {
      const newActions = actions.map((a, i) => ({
        morning: i === index && period === 'morning',
        afternoon: i === index && period === 'afternoon',
      }));
      setActions(newActions);

      if (!slot.active) {
        dispatch(
          addTimeSlot({
            idDealer: idDealer,
            from: slot.from,
            until: slot.until,
            active: slot.active,
            dayOfWeek: slot.dayOfWeek,
          }),
        ).catch(console.error);
      } else {
        dispatch(
          deleteTimeSlot({
            idTimeSlot: slot.id,
          }),
        ).catch(console.error);
      }
    }
  };

  return (
    <div className={`time-slots-list ${disableTimeslotCheckBox()}`}>
      <div className="time-slots__description fp--body-short-01">{t('select_period')}</div>
      <div className="time-slots-list__values">
        <div></div>
        <div className="time-slot-period fp--label-01">{t('vormittags')}</div>
        <div className="time-slot-period fp--label-01">{t('nachmittags')}</div>

        {days
          .map(day => {
            const morning: ITimeSlot = timeSlots.find(ts => ts.dayOfWeek === day.key && ts.from === '8:00') || {
              from: '8:00',
              until: '12:00',
              dayOfWeek: day.key,
              active: false,
              occupied: false,
              id: 0,
            };
            const afternoon: ITimeSlot = timeSlots.find(ts => ts.dayOfWeek === day.key && ts.from === '14:00') || {
              from: '14:00',
              until: '18:00',
              dayOfWeek: day.key,
              active: false,
              occupied: false,
              id: 0,
            };

            return { ...day, daySlots: [morning, afternoon] };
          })
          .map(day => (
            <Fragment key={`time-slot-${day.key}`}>
              <div className="time-slot-day fp--productive-heading-01">{day.name}</div>
              {day.daySlots.length > 0 ? (
                <div className={getActiveClass(day.daySlots[0])} onClick={() => onUpdateTimeSlot(day.daySlots[0], day.key, 'morning')}>
                  {getDayText(day.daySlots[0])}
                  {status === DataStatus.LOADING && actions[day.key].morning && <Loading className="time-slot__loading" small={true} withOverlay={false} />}
                </div>
              ) : (
                <div></div>
              )}
              {day.daySlots.length > 1 ? (
                <div className={getActiveClass(day.daySlots[1])} onClick={() => onUpdateTimeSlot(day.daySlots[1], day.key, 'afternoon')}>
                  {getDayText(day.daySlots[1])}
                  {status === DataStatus.LOADING && actions[day.key].afternoon && <Loading className="time-slot__loading" small={true} withOverlay={false} />}
                </div>
              ) : (
                <div></div>
              )}
            </Fragment>
          ))}
      </div>
    </div>
  );
};

export default RegisterTimeSlots;
