import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  Button,
  DatePicker,
  DatePickerInput,
  Form,
  InlineNotification,
  Loading,
  Select,
  SelectItem,
  Tabs,
  TextArea,
  TextInput,
  ToastNotification,
} from 'carbon-components-react';
import { TrashCan16, TrashCan20, TrashCan24, TrashCan32 } from '@carbon/icons-react';
import TypographyTest from '../common/components/TypographyTest';
import AppIcon from '../common/components/AppIcon/AppIcon';

import { show } from '../redux/reducers/OverlaySlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

import './ComponentsTestPage.scss';
import { getVehiclesState } from '../redux/reducers/VehiclesSlice';
import { showNotification } from '../redux/reducers/NotificationsSlice';
import { OverlayPages } from '../common/components/Overlay/overlayPages';
import Image, { ImageProps } from '../common/components/Image/Image';
import TableTest from '../common/components/TableTest';
import ListItems from '../common/components/ListItems/ListItems';
import AppTab from '../common/components/AppTab/AppTab';
import AppModal from '../common/components/AppModal/AppModal';
import VehiclesFilter from '../modules/vehicles/components/VehiclesFilter';
import VehicleAppointment from '../modules/appointments/components/vehicleAppointment/VehicleAppointment';
import DashboardTest from '../common/components/DashboardTest';
import ImageSlider from '../common/components/ImageSlider/ImageSlider';
import AppButton from '../common/components/AppButton/AppButton';

const ComponentsTestPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(getVehiclesState);
  const { vehicles } = state;
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const ImagesForSlider: ImageProps[] = [
    {
      link: 'https://www.gpas-cache.ford.com/guid/82d9b920-9ec5-300c-b858-f405134b4bc3.png',
      size: 'large',
      alt: '',
      caption: '',
    },
    {
      link: 'https://www.gpas-cache.ford.com/guid/56dc9d5c-d9fc-30d3-9a66-8f11bdda45e3.png',
      size: 'large',
      alt: '',
      caption: '',
    },
    {
      link: 'https://www.gpas-cache.ford.com/guid/56dc9d5c-d9fc-30d3-9a66-8f11bdda45e3.png',
      size: 'large',
      alt: '',
      caption: '',
    },
    {
      link: 'https://www.gpas-cache.ford.com/guid/82d9b920-9ec5-300c-b858-f405134b4bc3.png',
      size: 'large',
      alt: '',
      caption: '',
    },
  ];

  return (
    <div className="components-test-page">
      <h1>Page required to test Carbon components</h1>
      <div className="component-wrapper">
        <h3>New Button Component</h3>
        <div>
          <AppButton>Test Primary</AppButton>
          <AppButton kind="secondary">Test Secondary</AppButton>
          <AppButton kind="primary" disabled={true}>
            Test Primary
          </AppButton>
          <AppButton kind="primary">Test Primary</AppButton>
        </div>
      </div>
      <div className="component-wrapper">
        <h3>Button Component</h3>
        <div>
          <Button>Test Primary</Button>
          <Button kind="secondary">Test Secondary</Button>
          <Button kind="tertiary">Test Tertiary</Button>
          <Button kind="danger">Test Danger</Button>
          <Button kind="ghost">Ghost Button</Button>
        </div>
        <br />
        <div>
          <Button disabled>Test Primary</Button>
          <Button disabled kind="secondary">
            Test Secondary
          </Button>
          <Button disabled kind="tertiary">
            Test Tertiary
          </Button>
          <Button disabled kind="danger">
            Test Danger
          </Button>
          <Button disabled kind="ghost">
            Ghost Button
          </Button>
        </div>
        <br />
        <div>
          <Button kind="primary">Long text button demo to test right spacer</Button>
        </div>
      </div>
      <div className="component-wrapper">
        <ImageSlider images={ImagesForSlider} />
      </div>
      <div className="component-wrapper">
        <h3>Spinner</h3>
        <label htmlFor="">Big: </label>
        <Loading description="Active loading indicator" withOverlay={false} />
        <label htmlFor="">Small: </label>
        <Loading small description="Active loading indicator" withOverlay={false} />
      </div>
      <div className="component-wrapper">
        <h3>Notifications</h3>
        <ToastNotification
          caption="error"
          iconDescription="describes the close button"
          subtitle={
            <span>
              Subtitle text goes here. <a href="#example">Example link</a>
            </span>
          }
          timeout={0}
          title="Error"
        />
        <ToastNotification
          caption="info"
          iconDescription="describes the close button"
          subtitle={
            <span>
              Subtitle text goes here. <a href="#example">Example link</a>
            </span>
          }
          timeout={0}
          title="Info"
          kind="info"
        />
        <ToastNotification
          caption="success"
          iconDescription="describes the close button"
          subtitle={
            <span>
              Subtitle text goes here. <a href="#example">Example link</a>
            </span>
          }
          timeout={0}
          title="Success"
          kind="success"
        />
        <ToastNotification
          caption="warning"
          iconDescription="describes the close button"
          subtitle={
            <span>
              Subtitle text goes here. <a href="#example">Example link</a>
            </span>
          }
          timeout={0}
          title="Warning"
          kind="warning"
        />
        <ToastNotification
          caption="error"
          iconDescription="describes the close button"
          subtitle={<span>I will fade away in 10 seconds.</span>}
          timeout={10000}
          title="Error with hide"
        />
        <InlineNotification
          iconDescription="describes the close button"
          subtitle={
            <span>
              Subtitle text goes here. <a href="#example">Example link</a>
            </span>
          }
          title="Inline"
          notificationType="inline"
          kind="info"
        />
        <hr />
      </div>
      <div className="component-wrapper">
        <h3>Icons</h3>
        <AppIcon icon="house" size="xs" />
        <AppIcon icon="house" size="sm" />
        <AppIcon icon="house" size="lg" />
        <br />
        <AppIcon icon="bookable" size="xs" />
        <AppIcon icon="handover" size="xs" />
        <AppIcon icon="release" size="xs" />
        <AppIcon icon="complete" size="xs" />
        <AppIcon icon="file" size="xs" />
        <AppIcon icon="house" size="xs" />
        <br />
        <AppIcon icon={TrashCan16} />
        <AppIcon icon={TrashCan20} />
        <AppIcon icon={TrashCan16} />
        <AppIcon icon={TrashCan24} />
        <AppIcon icon={TrashCan32} />
      </div>
      <div className="component-wrapper">
        <h3>Accordion</h3>
        <Accordion>
          <AccordionItem title="Item 1">
            <p>Item 1 text</p>
          </AccordionItem>
          <AccordionItem title="Item 2">
            <p>Item 2 text</p>
          </AccordionItem>
          <AccordionItem title="Item 3">
            <p>Item 3 text</p>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="component-wrapper">
        <h3>Date Picker</h3>
        <DatePicker dateFormat="m/d/Y" datePickerType="single">
          <DatePickerInput id="date-picker-default-id" placeholder="mm/dd/yyyy" labelText="Date picker label" type="text" />
        </DatePicker>
      </div>
      <div className="component-wrapper">
        <h3>Forms</h3>
        <Form>
          <div className="input-group">
            <TextInput
              helperText="Optional helper text here; if message is more than one line text should wrap (~100 character count maximum)"
              id="test2"
              invalidText="Invalid error message."
              labelText="Text input label"
              placeholder="Placeholder text"
            />
          </div>
          <div className="input-group">
            <TextArea
              cols={50}
              helperText="Optional helper text here; if message is more than one line text should wrap (~100 character count maximum)"
              id="test5"
              invalidText="Invalid error message."
              labelText="Text area label"
              placeholder="Placeholder text"
              rows={4}
            />
          </div>
          <div className="input-group">
            <Select defaultValue="placeholder-item" id="select-1" invalidText="This is an invalid error message." labelText="Select">
              <SelectItem text="Option 1" value="option-1" />
              <SelectItem text="Option 2" value="option-2" />
              <SelectItem text="Option 3" value="option-3" />
            </Select>
          </div>
          <Button kind="primary" tabIndex={0} type="submit">
            Submit
          </Button>
        </Form>
      </div>
      <div className="component-wrapper">
        <h3>Overlay</h3>
        <Button onClick={() => dispatch(show({ content: OverlayPages.DEFAULT, headline: 'Ford Puma ST-Line', contentProps: {} }))}>Test Overlay</Button>
      </div>
      <div className="component-wrapper">
        <h3>NotificationTest</h3>
        <Button
          onClick={() =>
            dispatch(
              showNotification({
                type: 'success',
                title: 'You clicked successfully',
                subtitle: 'This is a simple notification test',
                caption: 'It will close in 5 sec',
              }),
            )
          }
        >
          Test Notification
        </Button>
      </div>
      <div className="component-wrapper">
        <h3>Headings</h3>
        <h1 className="no-change-h1">Heading 1</h1>
        <h2>Heading 2</h2>
        <h3 className="no-change-h3">Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
      </div>
      <div className="component-wrapper">
        <h3>Typography</h3>
        <TypographyTest />
      </div>
      <div className="component-wrapper">
        <h3>ImageTest</h3>
        <h5>Image medium size test with caption</h5>
        <br />
        <Image
          link="https://www.gpas-cache.ford.com/guid/82d9b920-9ec5-300c-b858-f405134b4bc3.png"
          alt="Modelpicture"
          size="medium"
          caption="This is the image caption"
        />
        <h5>
          <br />
          <br />
          Image small size test
        </h5>
        &nbsp;
        <Image link="https://www.gpas-cache.ford.com/guid/56dc9d5c-d9fc-30d3-9a66-8f11bdda45e3.png" alt="Modelpicture" size="small" />
        &nbsp;
        <h5>
          <br />
          Image medium size test
        </h5>
        <br />
        <Image link="https://www.gpas-cache.ford.com/guid/56dc9d5c-d9fc-30d3-9a66-8f11bdda45e3.png" alt="Modelpicture" size="medium" />
        <br />
      </div>
      <div className="component-wrapper">
        <h3>Table Test</h3>
        <TableTest />
      </div>
      <div className="component-wrapper">
        <h3>Empty vehicles list</h3>
        <ListItems headline="Empty State" items={[]} />
      </div>
      <div className="component-wrapper tabs-preview">
        <h3>Tab navi for modelinformation-detailpage</h3>
        <Tabs>
          <AppTab id="tab-1" label="Fahrzeugdetails">
            <div className="some-content">
              <p>
                <b>Content for first tab goes here.</b>
              </p>
              <p>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                anim id est laborum."
              </p>
            </div>
          </AppTab>
          <AppTab id="tab-2" label="Fahrzeugakte">
            <div className="some-content">
              <p>
                <b>Content for second tab goes here.</b>
              </p>
              <p>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                anim id est laborum."
              </p>
            </div>
          </AppTab>
          <AppTab id="tab-3" notifications={4} label="Aufgaben und Termine">
            <div className="some-content">
              <p>
                <b>Content for third tab goes here.</b>
              </p>
              <p>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                anim id est laborum."
              </p>
            </div>
          </AppTab>
          <AppTab id="tab-4" label="Disabled Example" disabled={true}>
            <div className="some-content"></div>
          </AppTab>
        </Tabs>
      </div>

      <div className="component-wrapper">
        <h3>Modal 1 CTA</h3>
        <div>
          <Button onClick={() => setOpen2(true)}>Launch Modal</Button>
          <AppModal
            open={open2}
            className={'isSuperregional'}
            onRequestSubmit={() => setOpen2(false)}
            onRequestClose={() => setOpen2(false)}
            primaryButtonText="Save"
            modalHeading="Termin bestätigen"
            showSecondaryButton={false}
          >
            Content
          </AppModal>
        </div>
      </div>
      <div className="component-wrapper">
        <h3>Modal 2 CTAs (test transparent scroll indicator)</h3>
        <div>
          <Button onClick={() => setOpen1(true)}>Launch Modal</Button>
          <AppModal
            open={open1}
            className={'isSuperregional'}
            onRequestSubmit={() => setOpen1(false)}
            onRequestClose={() => setOpen1(false)}
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            modalHeading="Termin bestätigen"
            showSecondaryButton={true}
          >
            <ul>
              <li>
                <b>Content for testing scroll indicator</b>
              </li>
              <li>Aliquam tincidunt mauris eu risus.</li>
              <li>Vestibulum auctor dapibus neque.</li>
              <li>Nunc dignissim risus id metus.</li>
              <li>Cras ornare tristique elit.</li>
              <li>Vivamus vestibulum ntulla nec ante.</li>
              <li>Praesent placerat risus quis eros.</li>
              <li>Fusce pellentesque suscipit nibh.</li>
              <li>Integer vitae libero ac risus egestas placerat.</li>
              <li>Vestibulum commodo felis quis tortor.</li>
              <li>Ut aliquam sollicitudin leo.</li>
              <li>Cras iaculis ultricies nulla.</li>
              <li>Donec quis dui at dolor tempor interdum.</li>
              <li>Ut aliquam sollicitudin leo.</li>
              <li>Cras iaculis ultricies nulla.</li>
              <li>Donec quis dui at dolor tempor interdum.</li>
              <li>Donec quis dui at dolor tempor interdum.</li>
              <li>Ut aliquam sollicitudin leo.</li>
              <li>Cras iaculis ultricies nulla.</li>
              <li>Donec quis dui at dolor tempor interdum.</li>
              <li>Donec quis dui at dolor tempor interdum.</li>
              <li>Ut aliquam sollicitudin leo.</li>
              <li>Cras iaculis ultricies nulla.</li>
              <li>Donec quis dui at dolor tempor interdum.</li>
              <li>Donec quis dui at dolor tempor interdum.</li>
              <li>Ut aliquam sollicitudin leo.</li>
              <li>Cras iaculis ultricies nulla.</li>
              <li>Donec quis dui at dolor tempor interdum.</li>
            </ul>
          </AppModal>
        </div>
      </div>
      <VehiclesFilter items={vehicles.marketplace} />

      <div className="component-wrapper component-wrapper-50">
        <VehicleAppointment
          selectedPurchase={null}
          idPurchase={0}
          estimatedDate={null}
          status="ready-for-pick-up"
          date="22.05.2022"
          customer={{ contactName: '', address: '', zipCode: '', city: '', phone: 'string', email: 'string' }}
          tasks={[]}
          appointments={[]}
          vehicleTitle="Fleetpool Mondeo"
        />
      </div>
      <div className="component-wrapper component-wrapper-50">
        <VehicleAppointment
          selectedPurchase={null}
          idPurchase={0}
          estimatedDate={null}
          status="scheduled-for-return"
          date="22.05.2022"
          customer={{ contactName: '', address: '', zipCode: '', city: '', phone: 'string', email: 'string' }}
          tasks={[]}
          appointments={[]}
          vehicleTitle="Fleetpool Mondeo"
        />
      </div>
      <div className="component-wrapper component-wrapper-50">
        <VehicleAppointment
          selectedPurchase={null}
          idPurchase={0}
          estimatedDate={null}
          status="returned"
          date="22.05.2022"
          customer={{ contactName: '', address: '', zipCode: '', city: '', phone: 'string', email: 'string' }}
          tasks={[]}
          appointments={[]}
          vehicleTitle="Fleetpool Mondeo"
        />
      </div>
      <div className="component-wrapper component-wrapper-50">
        <VehicleAppointment
          selectedPurchase={null}
          idPurchase={0}
          estimatedDate={null}
          status="picked-up"
          date="22.05.2022"
          customer={{ contactName: '', address: '', zipCode: '', city: '', phone: 'string', email: 'string' }}
          tasks={[]}
          appointments={[]}
          vehicleTitle="Fleetpool Mondeo"
        />
      </div>
      <div className="dashboard-test">
        <DashboardTest />
      </div>
    </div>
  );
};

export default ComponentsTestPage;
