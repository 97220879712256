import './AppSearch.scss';

import React, { useState } from 'react';
import { Search32 } from '@carbon/icons-react';
import AppIcon from '../AppIcon/AppIcon';

type IAppSearch = {
  text?: string;
  onSearchItems: (value: string) => void;
};

const AppSearch: React.FC<IAppSearch> = (props: IAppSearch) => {
  const { text, onSearchItems } = props;
  const [value, setValue] = useState('');

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onEnterHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      onSearchItems(value);
    }
  };

  return (
    <div className="app-search">
      <AppIcon icon={Search32} size="xs" />
      <div className="fp--body-short-01">
        <input
          type="text"
          id="search"
          name="search"
          placeholder={text}
          value={value}
          onChange={event => onChangeHandler(event)}
          onKeyPress={event => onEnterHandler(event)}
        />
      </div>
    </div>
  );
};

export default AppSearch;
