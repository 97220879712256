import './AppLanguage.scss';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EnvUtil from '../../../utils/EnvUtil';

const AppLanguage: React.FC = () => {
  const { t, i18n } = useTranslation('common');
  const languageStorageKey = 'currentLanguage';
  const defaultLanguage = 'english';
  const configuredLanguages = String(EnvUtil.get<string>('SDM_I18N') || 'en,de')
    .toLowerCase()
    .split(',');
  const supportedLanguages = ['deutsch', 'english', 'français', 'italiano', 'español'];
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  function locale2Letter(locale_str: string): string {
    return locale_str.substring(0, 2).toLowerCase();
  }
  function setCookie(name: string, value: string): void {
    const date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
  }
  function getCookie(name: string): string | undefined {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length == 2) {
      return parts.pop()?.split(';').shift();
    }
  }
  function setLanguage(language: string): void {
    setSelectedLanguage(language);
    storeLanguage(language);
    i18n.changeLanguage(language).catch(console.error);
  }
  function getLanguage(): string {
    let language = '';
    if (localStorage) {
      language = localStorage.getItem(languageStorageKey) || '';
      console.log('lang localStorage', language);
    } else {
      language = getCookie(languageStorageKey) || '';
      console.log('lang cookie', language);
    }
    if (language == '') {
      language = navigator.language;
      console.log('lang navigator', language);
    }
    if (language == '' && !(i18n.options?.supportedLngs || []).includes(language) && !(supportedLanguages || []).includes(language)) {
      language = defaultLanguage;
      console.log('lang default', language);
    }
    console.log('lang selected', language);
    return locale2Letter(language);
  }
  function storeLanguage(language: string): void {
    if (localStorage) {
      localStorage.setItem(languageStorageKey, locale2Letter(language));
    } else {
      setCookie(languageStorageKey, locale2Letter(language));
    }
  }
  function onLanguageChange(event: React.ChangeEvent<{ value: string }>): void {
    const language: string = event.target.value;
    setLanguage(language);
  }
  useEffect(() => {
    const language = getLanguage();
    setLanguage(locale2Letter(language));
  }, []);
  return (
    <div className="app-language">
      <div className="bx--form-item language--select">
        <div className="bx--select bx--select--inline">
          <div className="bx--select-input--inline__wrapper">
            <div className="bx--select-input__wrapper" data-invalid>
              <select id="select-id" value={selectedLanguage} className="bx--select-input" onChange={onLanguageChange}>
                {supportedLanguages.map((language, index) => {
                  return configuredLanguages.map(configured => {
                    return language.startsWith(configured) ? (
                      <option key={index} value={locale2Letter(language)}>
                        {t(language)}
                      </option>
                    ) : null;
                  });
                })}
              </select>
              {supportedLanguages ? <div className={`language--flag ${selectedLanguage}`}></div> : null}
              <svg
                focusable="false"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                className="bx--select__arrow"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                aria-hidden="true"
              >
                <path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AppLanguage;
