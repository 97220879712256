import './VehicleDetails.scss';
import VehicleDetails from '../../common/components/VehicleDetails/VehicleDetails';
import { InlineNotification, Loading, Tabs } from 'carbon-components-react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getVehiclesDetails, getVehicleDetailsState, IGetVehicleDetailsParams, clearVehicleDetails } from '../../redux/reducers/VehicleDetailsSlice';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataStatus } from '../../common/models/dataStatus';
import AppTab from '../../common/components/AppTab/AppTab';
import VehicleFile from '../vehicleFile/VehicleFile';
import { VehiclesStatus } from '../../common/models/vehicles';
import AppIcon from '../../common/components/AppIcon/AppIcon';
import Appointments from '../appointments/Appointments';
import { ISubscriptionVehicleDetails } from '../../common/models/vehicleDetails';
import VehicleContract from '../purchaseCondition/PurchaseCondition';
import PurchaseDocuments from '../purchaseDocuments/PurchaseDocuments';

export type DetailsTabKey = 'details' | 'vehicle-file' | 'appointments' | 'contract' | 'documents';

export interface IVehiclesDetailsProps {
  idVehicleTemplate: number;
  idSubscription?: number;
  idPurchase?: number;
  idVehicle?: number;
  selectedTab: DetailsTabKey;
}

const VehiclesDetails: React.FC<IVehiclesDetailsProps> = (props: IVehiclesDetailsProps) => {
  const { t } = useTranslation('vehicles');
  const state = useAppSelector(getVehicleDetailsState);
  const { status, details } = state;

  const dispatch = useAppDispatch();
  const { idVehicleTemplate, idSubscription, idPurchase, idVehicle, selectedTab } = props;

  const statusTranslations = {
    [VehiclesStatus.RELEASE]: t('release'),
    [VehiclesStatus.MARKETPLACE]: t('bookable'),
    [VehiclesStatus.OFFER]: t('offer'),
    [VehiclesStatus.HANDOVER]: t('pickup'),
    [VehiclesStatus.RETURN]: t('return'),
    [VehiclesStatus.AT_CUSTOMER]: t('at_the_customer'),
    [VehiclesStatus.COMPLETED]: t('bill_paid'),
    [VehiclesStatus.REJECTED]: t('rejected'),
    [VehiclesStatus.OTHER]: 'Other',
    [VehiclesStatus.ACCEPTED]: t('accepted'),
    [VehiclesStatus.UNKNOWN]: t('unknown'),
    [VehiclesStatus.REGISTERED]: t('registered'),
  };

  const renderStatusIcon = () => {
    let statusIcon;
    if (details) {
      VehiclesStatus.RELEASE === details.status && (statusIcon = <AppIcon icon="release" size="xs" />);
      VehiclesStatus.MARKETPLACE === details.status && (statusIcon = <AppIcon icon="bookable" size="xs" />);
      VehiclesStatus.OFFER === details.status && (statusIcon = <AppIcon icon="file" size="xs" />);
      (VehiclesStatus.HANDOVER === details.status || VehiclesStatus.RETURN === details.status) && (statusIcon = <AppIcon icon="handover" size="xs" />);
      VehiclesStatus.AT_CUSTOMER === details.status && (statusIcon = <AppIcon icon="house" size="xs" />);
      VehiclesStatus.COMPLETED === details.status && (statusIcon = <AppIcon icon="complete" size="xs" />);

      return statusIcon;
    }
  };

  const shouldShowAppointments = (): boolean => {
    return !(
      details !== null && [VehiclesStatus.HANDOVER, VehiclesStatus.RETURN, VehiclesStatus.AT_CUSTOMER, VehiclesStatus.COMPLETED].includes(details.status)
    );
  };

  useEffect(() => {
    const params: IGetVehicleDetailsParams = { idVehicleTemplate };

    if (idSubscription) {
      params.idSubscription = idSubscription;
    }

    if (idPurchase) {
      params.idPurchase = idPurchase;
    }

    (async function () {
      await dispatch(getVehiclesDetails(params));
    })();

    renderStatusIcon();

    return () => {
      dispatch(clearVehicleDetails());
    };
  }, []);

  const generateTabs = (details: ISubscriptionVehicleDetails): { key: DetailsTabKey; tab: ReactElement }[] => [
    {
      key: 'details',
      tab: (
        <AppTab key="tab-details" id="details" label={t('vehicle_details')}>
          <VehicleDetails
            conditions={details.conditions}
            purchaseStatus={details.purchaseStatus}
            idPurchase={details.idPurchase}
            manufacturer={details.manufacturer}
            model={details.model}
            variant={details.variant}
            transmission={details.transmission}
            vin={details.vin}
            image={details.media && details.media.length ? details.media[0].path : ''}
            bodyType={details.bodyType}
            color={details.color}
            cubicCapacityLitres={details.cubicCapacityLitres}
            power={details.power}
            powerKw={details.powerKw}
            fuel={details.fuel}
            fuelConsumptionCombined={details.fuelConsumptionCombined}
            fuelConsumptionInTown={details.fuelConsumptionInTown}
            fuelConsumptionOutOfTown={details.fuelConsumptionOutOfTown}
            electricRange={details.electricRange}
            co2EmissionValue={details.co2EmissionValue}
            co2EmissionClass={details.co2EmissionClass}
            co2EfficiencyClass={details.co2EfficiencyClass}
            equipments={details.equipments.map(equipment => equipment.name)}
            description={details.description}
          />
        </AppTab>
      ),
    },
    {
      key: 'contract',
      tab: (
        <AppTab key="tab-contract" id="contract" label={t('contract_details')} disabled={!idPurchase ? true : false}>
          {idPurchase && <VehicleContract idPurchase={idPurchase} idTemplate={details.idTemplate} />}
        </AppTab>
      ),
    },
    {
      key: 'vehicle-file',
      tab: (
        <AppTab key="tab-timeline" id="timeline" label={t('vehicle_file')} disabled={!idVehicle ? true : false}>
          <VehicleFile idVehicle={idVehicle ? idVehicle : 0} />
        </AppTab>
      ),
    },
    {
      key: 'appointments',
      tab: (
        <AppTab key="tab-appointment" id="appointment" label={t('meeting')} disabled={shouldShowAppointments()}>
          {idPurchase && <Appointments idPurchase={idPurchase} />}
        </AppTab>
      ),
    },
    {
      key: 'documents',
      tab: (
        <AppTab key="tab-document" id="documents" label={t('documents')} disabled={!idPurchase ? true : false}>
          {idPurchase && <PurchaseDocuments idPurchase={idPurchase} idTemplate={details.idTemplate} />}
        </AppTab>
      ),
    },
  ];

  const getSelectedTab = (tabs: { key: DetailsTabKey; tab: ReactElement }[]): number => {
    const selected = tabs.findIndex(t => t.key === selectedTab);

    if (selected) {
      return selected;
    }

    return 0;
  };

  const renderVehiclesDetails = () => {
    const tabs = details ? generateTabs(details) : [];
    return (
      details && (
        <div className="vehicle-details-module">
          <div className="vehicle-details--status fp--label-01">
            <div>
              {t('Status :')} {details.status in statusTranslations ? statusTranslations[details.status] : ''}
              {details?.purchaseStatus == 'accepted' ? ` (${t('auto')} )` : ''}
            </div>
            {renderStatusIcon()}
          </div>
          <Tabs selected={getSelectedTab(tabs)}>{tabs.map(val => val.tab)}</Tabs>
        </div>
      )
    );
  };

  const renderError = () => {
    return (
      <InlineNotification iconDescription="describes the close button" title={t('error_content_loaded') as string} notificationType="inline" kind="error" />
    );
  };

  const renderLoading = () => {
    return (
      <div className="vehicle-details__loading">
        <Loading description={t('load_vehicle_details')} withOverlay={false} />
      </div>
    );
  };

  const render = () => {
    if (status === DataStatus.LOADING) {
      return renderLoading();
    }
    if (status === DataStatus.FAILED || !details) {
      return renderError();
    }

    return renderVehiclesDetails();
  };

  return <div className="component-wrapper">{render()}</div>;
};

export default VehiclesDetails;
