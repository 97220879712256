import IDataConverter from '../../common/models/IDataConverter';
import { IMedia } from '../../common/models/vehicleDetails';

export interface IRawMedia {
  id: number;
  path: string;
  type: string;
}

class MediaConverter implements IDataConverter<IRawMedia, IMedia> {
  convert(from: IRawMedia): IMedia {
    return {
      id: from.id || 0,
      path: from.path || '',
      type: from.type || '',
    };
  }
}

export default MediaConverter;
