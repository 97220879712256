import './AppIcon.scss';
import { CarbonIconType } from '@carbon/icons-react';
import { ReactComponent as IconCarBookable } from '../../../assets/icons/car_bookable.svg';
import { ReactComponent as IconCarHandover } from '../../../assets/icons/car_handover.svg';
import { ReactComponent as IconCarRelease } from '../../../assets/icons/car_release.svg';
import { ReactComponent as IconComplete } from '../../../assets/icons/complete.svg';
import { ReactComponent as IconFile } from '../../../assets/icons/file.svg';
import { ReactComponent as IconHouse } from '../../../assets/icons/house.svg';
import { ReactComponent as IconMarketEmpty } from '../../../assets/icons/market_empty.svg';
import { ReactComponent as IconModelEmpty } from '../../../assets/icons/model_empty.svg';

type AppIconProps = {
  icon: 'bookable' | 'handover' | 'release' | 'complete' | 'file' | 'house' | 'marketEmpty' | 'modelEmpty' | CarbonIconType;
  color?: 'default' | 'interactive01' | 'disabled';
  size?: 'xs' | 'sm' | 'lg' | 'xl';
  extraClass?: string;
};

const AppIcon: React.FC<AppIconProps> = (props: AppIconProps) => {
  const { icon, color = 'default', size, extraClass } = props;

  const icons = {
    bookable: IconCarBookable,
    handover: IconCarHandover,
    release: IconCarRelease,
    complete: IconComplete,
    file: IconFile,
    house: IconHouse,
    marketEmpty: IconMarketEmpty,
    modelEmpty: IconModelEmpty,
  };

  const colors = {
    default: 'svg--color',
    interactive01: 'svg--color-link',
    disabled: 'svg--color-disabled',
  };

  const sizes = {
    xs: 'icon-xs',
    sm: 'icon-sm',
    lg: 'icon-lg',
    xl: 'icon-xl',
  };

  const IconTag = typeof icon === 'string' ? icons[icon] : icon;
  const IconColor = colors[color];
  const IconSize = typeof size === 'string' && sizes[size];
  const iconExtraClass = extraClass || '';

  return <IconTag className={`${IconSize} ${IconColor} ${iconExtraClass}`} />;
};

export default AppIcon;
