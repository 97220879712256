import './ReleaseItem.scss';

import { IReleaseVehicle } from '../../models/vehicles';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';
import Image from '../Image/Image';
import AppTooltip from '../AppTooltip/AppTooltip';

type ReleaseItemProps = {
  item: IReleaseVehicle;
  button: ReactElement;
  onShowDetails: () => void;
};

const ReleaseItem: React.FC<ReleaseItemProps> = (props: ReleaseItemProps) => {
  const { t } = useTranslation('vehicles');

  const { image, manufacturer, model, variant, fuel, transmission, animation } = props.item;
  const { button, onShowDetails } = props;
  const modelCompleteName = manufacturer + ' ' + model + ' ' + variant;
  const maxNROfCharacters = 28;

  return (
    <div className={`release-item release-item--${animation}`}>
      <div className="release-item--image">
        <Image link={image} alt="Modelpicture" size="medium" />
      </div>
      <div className="fp--body-short-01">
        <AppTooltip maxNROfCharacters={maxNROfCharacters} tooltipText={modelCompleteName} className="release-item--model" direction="top">
          <div className="release-item--model fp--productive-heading-01">{modelCompleteName}</div>
        </AppTooltip>
        <div className="release-item--description">
          {t(fuel)}, {t(transmission)}
        </div>
        <div className="release-item--details" onClick={onShowDetails}>
          {t('details')}
        </div>
      </div>

      <div className="release-item--button">{button}</div>
    </div>
  );
};

export default ReleaseItem;
