import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppHeader from './common/components/AppHeader/AppHeader';
import AppFooter from './common/components/AppFooter/AppFooter';
import AppRouter from './common/components/AppRouter';
import Overlay from './common/components/Overlay/Overlay';
import Authenticate from './modules/authenticate/Authenticate';
import AppNotifications from './common/components/AppNotifications/AppNotifications';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import './translations/i18n';
import EnvUtil from './utils/EnvUtil';
import { ThemeUtil } from './utils/ThemeUtil';

import './App.scss';
import AppVersion from './common/components/AppVersion/AppVersion';

const App: React.FC = () => {
  const sentryLink = EnvUtil.get<string>('SENTRY_DSN');
  if (sentryLink) {
    Sentry.init({
      dsn: sentryLink,
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: EnvUtil.get<number>('SENTRY_SAMPLE_RATE'),
    });
  }

  return (
    <Suspense fallback="...is loading">
      <Provider store={store}>
        <div className={'app ' + ThemeUtil.getNamespace()} data-testid="app">
          <AppVersion />
          <BrowserRouter>
            <Authenticate>
              <AppHeader />
              <main>
                <Overlay />
                <AppNotifications />
                <AppRouter />
              </main>
              <AppFooter />
            </Authenticate>
          </BrowserRouter>
        </div>
      </Provider>
    </Suspense>
  );
};

export default App;
