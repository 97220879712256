import { ReactElement } from 'react';
import ReleaseItem from '../ReleaseItem/ReleaseItem';
import { IReleaseVehicle } from '../../models/vehicles';
import './ReleaseItems.scss';
import { useTranslation } from 'react-i18next';
import AppIcon from '../AppIcon/AppIcon';
import { IAnimation } from '../../../redux/reducers/VehiclesSlice';
import { Loading } from 'carbon-components-react';
import { DataStatus } from '../../models/dataStatus';

interface ReleaseItem {
  item: IReleaseVehicle;
  animation: IAnimation;
  button: ReactElement;
  onShowDetails: () => void;
}

interface ReleaseItemsProps {
  status: DataStatus;
  items: ReleaseItem[];
}

const ReleaseItems: React.FC<ReleaseItemsProps> = (props: ReleaseItemsProps) => {
  const { t } = useTranslation('vehicles');
  const { items, status } = props;

  return (
    <div className="release-items">
      {items.length ? (
        items.map((item, index) => <ReleaseItem onShowDetails={item.onShowDetails} key={index} item={item.item} button={item.button} />)
      ) : (
        <div className="release-items--empty">
          <div className="release-items--empty__icon">
            <AppIcon icon="modelEmpty" size="xl" color="disabled" />
          </div>
          <div className="release-items--empty__text">{t('available_models_in_marketplace')}</div>
        </div>
      )}
      {status === DataStatus.LOADING && <Loading description="Loading list items" withOverlay={false} />}
    </div>
  );
};

export default ReleaseItems;
