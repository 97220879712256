import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStatus } from '../../common/models/dataStatus';

import {
  IAtCustomerVehicle,
  ICompletedVehicle,
  IHandOverVehicle,
  IMarketplaceVehicle,
  IOfferVehicle,
  IOtherVehicle,
  IReleaseVehicle,
  IReturnVehicle,
  VehiclesStatus,
} from '../../common/models/vehicles';
import VehiclesListConverter, { IRawVehiclesList } from '../../utils/converters/VehiclesListConverter';
import HttpUtil from '../../utils/HttpUtil';
import { Vehicles } from '../reducerNames';
import { RootState } from '../store';

export type IVehiclesList = {
  release: IReleaseVehicle[];
  marketplace: IMarketplaceVehicle[];
  offer: IOfferVehicle[];
  handOver: IHandOverVehicle[];
  atCustomer: IAtCustomerVehicle[];
  return: IReturnVehicle[];
  completed: ICompletedVehicle[];
  other: IOtherVehicle[];
};

export type IAnimation = 'show' | 'hide' | 'none';

export interface VehiclesState {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
  vehicles: IVehiclesList;
  filter?: IVehicleFilterParams | null;
}

const initialState: VehiclesState = {
  status: DataStatus.IDLE,
  filter: null,
  vehicles: {
    release: [],
    marketplace: [],
    offer: [],
    handOver: [],
    atCustomer: [],
    return: [],
    completed: [],
    other: [],
  },
};

export interface IVehicleFilterParams {
  filter: {
    searchByFields?: {
      manufacturer: string;
      model: string;
      variant: string;
    };
    searchByText?: string;
    searchByDateRange?: {
      from: string;
      to: string;
    };
    searchByTemplateId?: string;
  };
  statusList: VehiclesStatus[];
}

export const getVehiclesData = createAsyncThunk<VehiclesState>(`${Vehicles}/fetchVehicleList`, async (): Promise<VehiclesState> => {
  try {
    const response = await HttpUtil.get<IRawVehiclesList>(`api/v1/vehicle`);
    const converter = new VehiclesListConverter();
    return { vehicles: converter.convert(response.data), status: DataStatus.IDLE };
  } catch (err) {
    console.error('Backend not found', err);
    return { vehicles: initialState.vehicles, status: DataStatus.FAILED };
  }
});

export const filterVehiclesData = createAsyncThunk<VehiclesState, IVehicleFilterParams>(
  `${Vehicles}/filterVehicleList`,
  async (params: IVehicleFilterParams, thunkAPI): Promise<VehiclesState> => {
    try {
      const response = await HttpUtil.post<IRawVehiclesList>(`api/v1/vehicle`, params);
      const converter = new VehiclesListConverter();
      const newValues = converter.convert(response.data);
      const oldValues = (thunkAPI.getState() as RootState).vehicleReducer.vehicles;

      if (oldValues.marketplace.length > 0) {
        const oldMarketplaceValues = oldValues.marketplace.filter(m => m.details.active);
        newValues.marketplace = newValues.marketplace.map(newVehicle => ({
          ...newVehicle,
          animation: oldMarketplaceValues.some(oldVehicle => oldVehicle.idSubscription === newVehicle.idSubscription) ? 'none' : 'show',
        }));
      }

      if (oldValues.release.length > 0) {
        newValues.release = newValues.release.map(newVehicle => ({
          ...newVehicle,
          animation: oldValues.release.some(oldVehicle => oldVehicle.idTemplate === newVehicle.idTemplate) ? 'none' : 'show',
        }));
      }
      if (oldValues.handOver.length > 0) {
        newValues.handOver = newValues.handOver.map(newVehicle => ({
          ...newVehicle,
          animation: oldValues.handOver.some(oldVehicle => oldVehicle.idPurchase === newVehicle.idPurchase) ? 'none' : 'show',
        }));
      }

      return { vehicles: newValues, status: DataStatus.IDLE, filter: params };
    } catch (err) {
      console.error('Backend not found', err);
      return { vehicles: initialState.vehicles, status: DataStatus.FAILED, filter: null };
    }
  },
);

export const vehiclesSlice = createSlice({
  name: Vehicles,
  initialState,
  reducers: {
    enableHideTemplateAnimation: (state, action: PayloadAction<{ idVehicleTemplate: number }>) => {
      state.vehicles.release = state.vehicles.release.map(template => ({
        ...template,
        animation: template.idTemplate === action.payload.idVehicleTemplate ? 'hide' : 'none',
      }));
    },
    enableHideSubscriptionAnimation: (state, action: PayloadAction<{ idSubscription: number }>) => {
      state.vehicles.marketplace = state.vehicles.marketplace.map(subscription => ({
        ...subscription,
        animation: subscription.idSubscription === action.payload.idSubscription ? 'hide' : 'none',
      }));
    },
    enableHideOfferAnimation: (state, action: PayloadAction<{ idPurchase: number }>) => {
      state.vehicles.offer = state.vehicles.offer.map(purchase => ({
        ...purchase,
        animation: purchase.idPurchase === action.payload.idPurchase ? 'hide' : 'none',
      }));
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getVehiclesData.pending, state => {
        state.status = DataStatus.LOADING;
        state.vehicles = { ...initialState.vehicles };
      })
      .addCase(getVehiclesData.fulfilled, (state, action: PayloadAction<VehiclesState>) => {
        state.status = action.payload.status;
        state.filter = null;
        state.vehicles = { ...action.payload.vehicles };
      })
      .addCase(filterVehiclesData.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(filterVehiclesData.fulfilled, (state, action: PayloadAction<VehiclesState>) => {
        state.status = action.payload.status;
        state.filter = action.payload.filter ? { ...action.payload.filter } : null;
        state.vehicles = { ...action.payload.vehicles };
      });
  },
});

export const { enableHideTemplateAnimation, enableHideSubscriptionAnimation, enableHideOfferAnimation } = vehiclesSlice.actions;

export const getVehiclesState = (state: RootState): VehiclesState => state.vehicleReducer;

export const getVehiclesFilterState = (state: RootState): IVehicleFilterParams | null | undefined => state.vehicleReducer.filter;

export default vehiclesSlice.reducer;
