import { IAppointment } from './appointment';
import { PurchaseStatus } from './purchase';
import { VehiclesStatus } from './vehicles';
import { ICondition } from './condition';


export interface IMedia {
  id: number;
  path: string;
  type: string;
}

export interface IEquipment {
  id: number;
  name: string;
}

export enum FuelType {
  PETROL = 'petrol',
  DIESEL = 'diesel',
  ELECTRIC = 'electric',
  NATURAL_GAS = 'natural-gas',
  HYBRID = 'hybrid',
  MILD_HYBRID_GAS = 'mild-hybrid-petrol',
  MILD_HYBRID_DIESEL = 'mild-hybrid-diesel',
  PLUG_IN_HYBRID = 'plug-in-hybrid',
  UNKNOWN = 'unknown',
}

export interface IVehicleDetails {
  idTemplate: number;
  idSubscription: number;
  idPurchase: number;
  number: string;
  description: string | null;
  vin: string;
  orbit: string;
  plateNumber: string;
  manufacturer: string;
  model: string;
  variant: string;
  fuel: FuelType;
  transmission: string;
  status: VehiclesStatus;
  purchaseStatus: PurchaseStatus;
  bodyType: string;
  color: string;
  cubicCapacityLitres: string;
  power: string;
  powerKw: string;
  fuelConsumptionCombined: string;
  fuelConsumptionInTown: string;
  fuelConsumptionOutOfTown: string;
  electricRange: string;
  co2EmissionValue: string;
  co2EmissionClass: string;
  co2EfficiencyClass: string;
  media: IMedia[];
  equipments: IEquipment[];
  appointments: IAppointment[];
}

export interface ISubscriptionVehicleDetails extends IVehicleDetails {
  conditions: ICondition[];
}
