const TypographyTest: React.FC = () => (
  <table>
    <thead>
      <tr>
        <th>Token</th>
        <th>Sample</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <pre>
            <code>caption-01</code>
          </pre>
        </td>
        <td className="fp--caption-01">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>caption-02</code>
          </pre>
        </td>
        <td className="fp--caption-02">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>label-01</code>
          </pre>
        </td>
        <td className="fp--label-01">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>label-02</code>
          </pre>
        </td>
        <td className="fp--label-02">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>helper-text-01</code>
          </pre>
        </td>
        <td className="fp--helper-text-01">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>helper-text-02</code>
          </pre>
        </td>
        <td className="fp--helper-text-02">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>body-short-01</code>
          </pre>
        </td>
        <td className="fp--body-short-01">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>body-long-01</code>
          </pre>
        </td>
        <td className="fp--body-long-01">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>body-short-02</code>
          </pre>
        </td>
        <td className="fp--body-short-02">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>body-long-02</code>
          </pre>
        </td>
        <td className="fp--body-long-02">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>code-01</code>
          </pre>
        </td>
        <td className="fp--code-01">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>code-02</code>
          </pre>
        </td>
        <td className="fp--code-02">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>heading-01</code>
          </pre>
        </td>
        <td className="fp--heading-01">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>productive-heading-01</code>
          </pre>
        </td>
        <td className="fp--productive-heading-01">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>heading-02</code>
          </pre>
        </td>
        <td className="fp--heading-02">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>productive-heading-02</code>
          </pre>
        </td>
        <td className="fp--productive-heading-02">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>productive-heading-03</code>
          </pre>
        </td>
        <td className="fp--productive-heading-03">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>productive-heading-04</code>
          </pre>
        </td>
        <td className="fp--productive-heading-04">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>productive-heading-05</code>
          </pre>
        </td>
        <td className="fp--productive-heading-05">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>productive-heading-06</code>
          </pre>
        </td>
        <td className="fp--productive-heading-06">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>productive-heading-07</code>
          </pre>
        </td>
        <td className="fp--productive-heading-07">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>quotation-01</code>
          </pre>
        </td>
        <td className="fp--quotation-01">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>quotation-02</code>
          </pre>
        </td>
        <td className="fp--quotation-02">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>display-01</code>
          </pre>
        </td>
        <td className="fp--display-01">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>display-02</code>
          </pre>
        </td>
        <td className="fp--display-02">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>display-03</code>
          </pre>
        </td>
        <td className="fp--display-03">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>display-04</code>
          </pre>
        </td>
        <td className="fp--display-04">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>legal-01</code>
          </pre>
        </td>
        <td className="fp--legal-01">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>legal-02</code>
          </pre>
        </td>
        <td className="fp--legal-02">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>body-compact-01</code>
          </pre>
        </td>
        <td className="fp--body-compact-01">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>body-compact-02</code>
          </pre>
        </td>
        <td className="fp--body-compact-02">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>body-01</code>
          </pre>
        </td>
        <td className="fp--body-01">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>body-02</code>
          </pre>
        </td>
        <td className="fp--body-02">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>heading-compact-01</code>
          </pre>
        </td>
        <td className="fp--heading-compact-01">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>heading-compact-02</code>
          </pre>
        </td>
        <td className="fp--heading-compact-02">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>heading-03</code>
          </pre>
        </td>
        <td className="fp--heading-03">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>heading-04</code>
          </pre>
        </td>
        <td className="fp--heading-04">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>heading-05</code>
          </pre>
        </td>
        <td className="fp--heading-05">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>heading-06</code>
          </pre>
        </td>
        <td className="fp--heading-06">
          <span>Text sample</span>
        </td>
      </tr>
      <tr>
        <td>
          <pre>
            <code>heading-07</code>
          </pre>
        </td>
        <td className="fp--heading-07">
          <span>Text sample</span>
        </td>
      </tr>
    </tbody>
  </table>
);

export default TypographyTest;
