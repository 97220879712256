import { Route, Switch } from 'react-router-dom';
import { routerPages } from '../routerPages';

const AppRouter: React.FC = () => {
  return (
    <Switch>
      {routerPages.map((page, index) => (
        <Route key={index} exact path={page.url} component={page.component} />
      ))}
    </Switch>
  );
};

export default AppRouter;
