import Dashboard from '../modules/dashboard/Dashboard';
import Release from '../modules/release/Release';

const ReleasePage: React.FC = () => {
  return (
    <div>
      <Dashboard />
      <div className="release-page-wrapper">
        <Release />
      </div>
    </div>
  );
};

export default ReleasePage;
