import { Loading } from 'carbon-components-react';
import VehicleAppointment from './components/vehicleAppointment/VehicleAppointment';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import React, { useEffect } from 'react';
import { DataStatus } from '../../common/models/dataStatus';
import { getPurchase, getPurchaseState } from '../../redux/reducers/PurchaseSlice';
import DateTimeUtil from '../../utils/DateTimeUtil';
import { IPurchase, PurchaseStatus } from '../../common/models/purchase';

const Appointments: React.FC<{ idPurchase: number }> = props => {
  const { idPurchase } = props;
  const purchaseState = useAppSelector(getPurchaseState);
  const { status, selectedPurchase } = purchaseState;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPurchase(idPurchase)).catch(console.error);
  }, []);

  const getPickupReturnDate = (purchase: IPurchase, type: 'date' | 'time'): string => {
    const formatter = type === 'date' ? DateTimeUtil.dayToStringFormat : DateTimeUtil.timeToStringFormat;
    if ((purchase.status === PurchaseStatus.SCHEDULED_FOR_PICK_UP || purchase.status === PurchaseStatus.READY_FOR_PICK_UP) && purchase.estimatedPickUp) {
      return formatter(purchase.estimatedPickUp);
    }

    if (purchase.status === PurchaseStatus.SCHEDULED_FOR_RETURN && purchase.estimatedReturn) {
      return formatter(purchase.estimatedReturn);
    }

    if (purchase.status === PurchaseStatus.PICKED_UP && purchase.pickedUpdAt) {
      return formatter(purchase.pickedUpdAt);
    }

    if (purchase.status === PurchaseStatus.RETURNED && purchase.returnedAt) {
      return formatter(purchase.returnedAt);
    }

    return '';
  };

  const getEstimatedDate = (purchase: IPurchase): Date | null => {
    if ((purchase.status === PurchaseStatus.SCHEDULED_FOR_PICK_UP || purchase.status === PurchaseStatus.READY_FOR_PICK_UP) && purchase.estimatedPickUp) {
      return purchase.estimatedPickUp;
    }

    if (purchase.status === PurchaseStatus.SCHEDULED_FOR_RETURN && purchase.estimatedReturn) {
      return purchase.estimatedReturn;
    }

    return null;
  };

  const getVehicleTitle = () => {
    if (!selectedPurchase?.subscription?.vehicleTemplate) {
      return '';
    }

    const { vehicleTemplate } = selectedPurchase.subscription;

    return `${vehicleTemplate.manufacturer} ${vehicleTemplate.model} ${vehicleTemplate.variant}`;
  };

  const renderVehicles = () => {
    return (
      selectedPurchase && (
        <VehicleAppointment
          selectedPurchase={selectedPurchase}
          idPurchase={selectedPurchase.id}
          estimatedDate={getEstimatedDate(selectedPurchase)}
          status={selectedPurchase.status}
          date={getPickupReturnDate(selectedPurchase, 'date')}
          customer={selectedPurchase.customer}
          tasks={selectedPurchase.tasks ? selectedPurchase.tasks : []}
          appointments={selectedPurchase.appointments ? selectedPurchase.appointments : []}
          vehicleTitle={getVehicleTitle()}
        />
      )
    );
  };

  const renderError = () => {
    return 'Fehler: Inhalte können nicht geladen werden';
  };

  const renderLoading = () => {
    return (
      <div className="home-list__loading">
        <Loading description="Loading list items" withOverlay={false} />
      </div>
    );
  };

  const render = () => {
    if (status === DataStatus.LOADING) {
      return renderLoading();
    }
    if (status === DataStatus.FAILED) {
      return renderError();
    }

    return renderVehicles();
  };

  return <div>{render()}</div>;
};

export default Appointments;
