import IDataConverter from '../../common/models/IDataConverter';
import { ICustomer } from '../../common/models/purchase';

export interface IRawCustomer {
  contactName: string;
  address: string;
  zipCode: string;
  city: string;
  phone: string;
  email: string;
}

class CustomerConverter implements IDataConverter<IRawCustomer, ICustomer> {
  convert(from: IRawCustomer): ICustomer {
    return {
      contactName: from.contactName || '',
      address: from.address || '',
      zipCode: from.zipCode || '',
      city: from.city || '',
      phone: from.phone || '',
      email: from.email || '',
    };
  }
}

export default CustomerConverter;
