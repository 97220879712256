import ProcessBarItem, { IProcessBarItem } from './ProcessBarItem';
import './ProcessBar.scss';

export interface IProcessBar {
  items: IProcessBarItem[];
}

const ProcessBar: React.FC<IProcessBar> = (props: IProcessBar) => {
  const { items } = props;

  return (
    <div className="process-bar-container">
      <ul className="process-bar">
        {items.map((item, index) => (
          <ProcessBarItem
            key={`${item.status}-process-bar-item-${index}`}
            status={item.status}
            statusName={item.statusName}
            notifications={item.notifications}
            disabled={item.disabled}
          />
        ))}
      </ul>
    </div>
  );
};

export default ProcessBar;
