import { InlineNotification, Loading } from 'carbon-components-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataStatus } from '../../common/models/dataStatus';
import { IVehicle } from '../../common/models/purchase';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getPurchase, getPurchaseState } from '../../redux/reducers/PurchaseSlice';
import DateTimeUtil from '../../utils/DateTimeUtil';
import PriceUtil from '../../utils/PriceUtil';
import { getVehicleTemplate } from '../../redux/reducers/VehicleTemplateSlice';
import LogistikportalLink from '../../common/components/LogistikportalLink/LogistikportalLink';

const PurchaseCondition: React.FC<{ idPurchase: number; idTemplate: number }> = props => {
  const { t } = useTranslation('vehicles');
  const dispatch = useAppDispatch();
  const state = useAppSelector(getPurchaseState);

  const { status, selectedPurchase } = state;
  const { idPurchase, idTemplate } = props;


  useEffect(() => {
    dispatch(getPurchase(idPurchase)).catch(err => console.error(err));
    dispatch(getVehicleTemplate(idTemplate)).catch(err => console.error(err));
  }, []);

  const getNumber = (vehicle: IVehicle | null) => {
    if (!vehicle) return '-';

    if (vehicle.vin) return vehicle.vin;

    if (vehicle.orbit) return vehicle.orbit;

    return '-';
  };

  const render = () => {
    if (status === DataStatus.LOADING) {
      return (
        <div className="accept-offer__loading">
          <Loading description="Loading items" withOverlay={false} />
        </div>
      );
    }
    if (status === DataStatus.FAILED) {
      return (
        <InlineNotification
          iconDescription="close"
          title={t('error_subscription_information') as string}
          notificationType="inline"
          kind="error"
        />
      );
    }

    if (!selectedPurchase) {
      return (
        <InlineNotification
          iconDescription="close"
          title={t('error_subscription_information') as string}
          notificationType="inline"
          kind="error"
        />
      );
    }

    const renderTransportType = () => {
      return selectedPurchase.transportType == 1 ? t('service_transport') : t('on_site_handover');
    };

    const renderMandatoryReport = () => {
      return selectedPurchase?.mandatoryReportableClaim === true ? t('yes') : t('no');
    };

    const { vehicle, number, offerExpiresAt, condition } = selectedPurchase;
    const dateFormatter = DateTimeUtil.toStringFormat;

    return (
      <>
        <div className="accept-offer__details accept-offer__heading">
          <div className="fp--body-long-01 accept-offer__label">{t('order_number')}</div>
          <div className="fp--body-long-01 accept-offer__label">{number}</div>
          <div className="fp--body-long-01 accept-offer__label">{t('offer_received')}</div>
          <div className="fp--body-long-01 accept-offer__label">
            {offerExpiresAt ? dateFormatter(offerExpiresAt) : ''} {t('clock')}
          </div>
          <div className="fp--body-long-01 accept-offer__label"></div>
          <div className="fp--body-long-01 accept-offer__label"></div>

          <div className="fp--body-long-01 accept-offer__label">{t('milage/duration')}</div>
          <div className="fp--body-long-01 accept-offer__label">
            {condition && Math.round(condition.milage) + ' km/' + condition.duration + ' ' + t('months')}
          </div>
          <div className="fp--body-long-01 accept-offer__label">{t('km_per_year')}</div>
          <div className="fp--body-long-01 accept-offer__label">{condition && Math.round((condition.milage * 12) / condition.duration) + ' ' + t('year')}</div>
          <div className="fp--body-long-01 accept-offer__label">{t('vehicle_number')}</div>
          <div className="fp--body-long-01 accept-offer__label">{getNumber(vehicle)}</div>

          <div className="fp--body-long-01 accept-offer__label">{t('selling_price_dealer')}</div>
          <div className="fp--body-long-01 accept-offer__label">{condition && PriceUtil.format(condition.ekp)}</div>

          <div className="fp--body-long-01 accept-offer__label accept-offer__label--last">{t('agreed_residual_value')}</div>
          <div className="fp--body-long-01 accept-offer__label accept-offer__label--last">{condition && PriceUtil.format(condition.rest)}</div>

          <div className="fp--body-long-01 accept-offer__label accept-offer__label--last">{t('typeof_vehicle_transfer')}</div>
          <div className="fp--body-long-01 accept-offer__label accept-offer__label--last">{renderTransportType()}</div>

          <div className="fp--body-long-01 accept-offer__label accept-offer__label--last">{t('mandatory_reportable_damage')}</div>
          <div className="fp--body-long-01 accept-offer__label accept-offer__label--last">{renderMandatoryReport()}</div>
        </div>

        <LogistikportalLink selectedPurchase={selectedPurchase}/>
      </>
    );
  };

  return <div className="accept-offer-template">{render()}</div>;
};

export default PurchaseCondition;
