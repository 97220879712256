import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { getDashboardState } from '../../../redux/reducers/DashboardSlice';
import { getVehiclesState } from '../../../redux/reducers/VehiclesSlice';
import { getVehicleTemplateState } from '../../../redux/reducers/VehicleTemplateSlice';
import { DataStatus } from '../../models/dataStatus';
import './VehicleListHeader.scss';

export type VehicleListHeaderProps = {
  children: undefined | null | string | JSX.IntrinsicElements | JSX.Element | Array<JSX.Element>;
};

const VehicleListHeader: React.FC<VehicleListHeaderProps> = ({ children }: VehicleListHeaderProps): JSX.Element => {
  const [scroll, setScroll] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const headerContainerRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const headerDivRef = useRef<any>(null);

  const dashboardState = useAppSelector(getDashboardState);
  const vehiclesState = useAppSelector(getVehiclesState);
  const vehiclesTemplatesState = useAppSelector(getVehicleTemplateState);
  const { status: vehiclesStatus } = vehiclesState;
  const { status: dashboardStatus } = dashboardState;
  const { status: vehiclesTemplateStatus } = vehiclesTemplatesState;

  useEffect(() => {
    let elementTopPosition = 0;
    let listHeaderContainerHeight = 0;
    if (headerDivRef && headerContainerRef) {
      elementTopPosition = window.scrollY + headerDivRef.current.getBoundingClientRect().top;
      listHeaderContainerHeight = headerDivRef.current.clientHeight - headerContainerRef.current.clientHeight;
    }

    const elementOnTop = elementTopPosition - listHeaderContainerHeight;
    const handleScroll = () => {
      setScroll(window.scrollY >= elementOnTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dashboardStatus == DataStatus.IDLE && vehiclesStatus == DataStatus.IDLE && vehiclesTemplateStatus == DataStatus.IDLE && headerDivRef.current]);
  return (
    <div className="vehicle-list-header-container" ref={headerContainerRef}>
      <div className={`vehicle-list-header ${scroll ? 'fixed' : ''}`} ref={headerDivRef}>
        {children}
      </div>
    </div>
  );
};

export default VehicleListHeader;
