import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { DataStatus } from '../../common/models/dataStatus';
import { ICodeResponse } from '../../common/models/ICodeResponse';
import { ISubscription } from '../../common/models/subscription';
import HttpUtil from '../../utils/HttpUtil';
import { Subscription } from '../reducerNames';
import { RootState } from '../store';
import { INotificationState, showNotification } from './NotificationsSlice';
import { filterVehiclesData, getVehiclesData } from './VehiclesSlice';

export interface SubscriptionState extends ICodeResponse {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
  action: 'none' | 'get' | 'delete';
  subscriptions?: ISubscription[];
  selectedSubscription?: ISubscription | null;
}

const initialState: SubscriptionState = {
  status: DataStatus.IDLE,
  action: 'none',
  code: 0,
  subscriptions: [],
};

export interface IDeleteSubscriptionParams {
  idSubscription: number;
}

export interface IGetSubscriptionParams {
  idSubscription: number;
}

const getNotification = (code: number): INotificationState => {
  if (code === 200) {
    return {
      type: 'success',
      title: i18next.t('success'),
      subtitle: i18next.t('subscription_deleted'),
      caption: '',
    };
  } else if (code === 201) {
    return {
      type: 'warning',
      title: i18next.t('warning'),
      subtitle: i18next.t('subscription_deleted_but_ERP_not_updated'),
      caption: '',
    };
  }

  return {
    type: 'error',
    title: i18next.t('error'),
    subtitle: i18next.t('subscription_could_not_deleted'),
    caption: '',
  };
};

export const getSubscription = createAsyncThunk<SubscriptionState, IGetSubscriptionParams>(
  `${Subscription}/subscriptionGetById`,
  async (params: IGetSubscriptionParams): Promise<SubscriptionState> => {
    try {
      const url = `api/v1/subscription/${params.idSubscription}`;

      const response = await HttpUtil.get<{ subscription: ISubscription }>(url, {});

      return { code: 200, status: DataStatus.IDLE, action: 'none', selectedSubscription: response.data.subscription };
    } catch (err) {
      console.error('Backend not found', err);
      return { code: 0, status: DataStatus.FAILED, action: 'none', selectedSubscription: null };
    }
  },
);

export const deleteSubscription = createAsyncThunk<SubscriptionState, IDeleteSubscriptionParams>(
  `${Subscription}/subscriptionDelete`,
  async (params: IDeleteSubscriptionParams, thunkAPI): Promise<SubscriptionState> => {
    try {
      const url = `api/v1/subscription/${params.idSubscription}`;

      const response = await HttpUtil.delete<ICodeResponse>(url, {});

      const filter = (thunkAPI.getState() as RootState).vehicleReducer.filter;
      if (filter) {
        thunkAPI.dispatch(filterVehiclesData(filter)).catch(console.error);
      } else {
        thunkAPI.dispatch(getVehiclesData()).catch(console.error);
      }
      thunkAPI.dispatch(showNotification(getNotification(response.data.code)));
      return { code: response.data.code, status: DataStatus.IDLE, action: 'none' };
    } catch (err) {
      console.error('Backend not found', err);
      thunkAPI.dispatch(showNotification(getNotification(0)));
      return { code: 0, status: DataStatus.FAILED, action: 'none' };
    }
  },
);

export const SubscriptionSlice = createSlice({
  name: Subscription,
  initialState,
  reducers: {
    // reducers go here
  },
  extraReducers: builder => {
    builder
      .addCase(deleteSubscription.pending, state => {
        state.status = DataStatus.LOADING;
        state.action = 'delete';
      })
      .addCase(deleteSubscription.fulfilled, (state, action: PayloadAction<SubscriptionState>) => {
        state.status = action.payload.status;
        state.code = action.payload.code;
        state.action = action.payload.action;
      })
      .addCase(getSubscription.pending, state => {
        state.status = DataStatus.LOADING;
        state.action = 'get';
      })
      .addCase(getSubscription.fulfilled, (state, action: PayloadAction<SubscriptionState>) => {
        state.status = action.payload.status;
        state.code = action.payload.code;
        state.action = action.payload.action;
        state.selectedSubscription = action.payload.selectedSubscription ? { ...action.payload.selectedSubscription } : null;
      });
  },
});

export default SubscriptionSlice.reducer;
