import React from 'react';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { getNotificationState, hideNotification } from '../../../redux/reducers/NotificationsSlice';
import { ToastNotification } from 'carbon-components-react';
import './AppNotifications.scss';

const AppNotifications: React.FC = () => {
  const state = useAppSelector(getNotificationState);
  const dispatch = useAppDispatch();

  return (
    <div className="fp-notifications-container">
      {state.notifications.map(notification => (
        <div key={notification.id}>
          <ToastNotification
            title={notification.title}
            subtitle={notification.subtitle}
            caption={notification.caption}
            kind={notification.type}
            role={notification.role}
            onClick={() => dispatch(hideNotification(notification.id))}
            {...setTimeout(() => {
              dispatch(hideNotification(notification.id));
            }, notification.timeout ?? 5000)}
          />
        </div>
      ))}
    </div>
  );
};

export default AppNotifications;
