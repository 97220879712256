import { InlineNotification } from 'carbon-components-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppNotifications from '../../common/components/AppNotifications/AppNotifications';
import { DataStatus } from '../../common/models/dataStatus';
import RegisterPage from '../../pages/RegisterPage';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { authenticate, authenticateState } from '../../redux/reducers/AuthenticationSlice';
import { ThemeUtil } from '../../utils/ThemeUtil';

const Authenticate: React.FC = props => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const state = useAppSelector(authenticateState);

  useEffect(() => {
    document.body.classList.add(ThemeUtil.getNamespace());
    dispatch(authenticate()).catch(console.error);
  }, []);

  if (state.status === DataStatus.FAILED) {
    return <InlineNotification iconDescription="describes the close button" title={t('login_failed') as string} notificationType="inline" kind="error" />;
  } else if (!state.isRegistered && state.status === DataStatus.IDLE) {
    return state.dealer ? (
      <main>
        <AppNotifications />
        <RegisterPage />
      </main>
    ) : (
      <InlineNotification iconDescription="describes the close button" title={t('user_not_found') as string} notificationType="inline" kind="error" />
    );
  } else if (state.authenticated) {
    return <div>{props.children}</div>;
  }

  return (
    <InlineNotification iconDescription="describes the close button" title={t('redirect_to_login_page') as string} notificationType="inline" kind="success" />
  );
};

export default Authenticate;
