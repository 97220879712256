import './Help.scss';
import React from 'react';
import { ThemeUtil, useTheme } from '../../utils/ThemeUtil';

const RenderHelp: React.FC = () => {
  const theme = useTheme();

  const renderHelp = () => {
    return (
      <div className="help-settings" >
        <div className="helpcontent" dangerouslySetInnerHTML={{__html: ThemeUtil.decode(theme.t('helpdesk_message'))}}></div>
        <div className="helpcontent" dangerouslySetInnerHTML={{__html: ThemeUtil.decode(theme.t('help_contacts'))}}></div>
      </div>
    );
  };

  return <div className="component-wrapper">{renderHelp()}</div>;
};

export default RenderHelp;
