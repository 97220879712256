import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStatus } from '../../common/models/dataStatus';
import { IVehicleAppointment } from '../../common/models/vehicleAppointment';
import { AppointmentAction, AppointmentStatus } from '../../common/models/appointment';

import MockUtil from '../../utils/MockUtil';
import { VehicleAppointment } from '../reducerNames';
import { RootState } from '../store';
import HttpUtil from '../../utils/HttpUtil';
import { ICodeResponse } from '../../common/models/ICodeResponse';
import { INotificationState, showNotification } from './NotificationsSlice';
import i18next from 'i18next';
import { getPurchase } from './PurchaseSlice';

export interface VehicleAppointmentState extends ICodeResponse {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
  appointmentInfo?: IVehicleAppointment | null;
}

const initialState: VehicleAppointmentState = {
  status: DataStatus.IDLE,
  appointmentInfo: null,
  code: 0,
};

export interface IAppointmentParams {
  idAppointment: number;
}

export const getVehicleAppointment = createAsyncThunk<VehicleAppointmentState, IAppointmentParams>(
  `${VehicleAppointment}/fetchAppointment`,
  async (params: IAppointmentParams): Promise<VehicleAppointmentState> => {
    try {
      const url = `api/v1/appointment/${params.idAppointment}`;

      const response = await MockUtil.get<{ appointment: IVehicleAppointment }>(url);

      return { appointmentInfo: response.data.appointment, status: DataStatus.IDLE, code: 0 };
    } catch (err) {
      console.error('Backend not found', err);
      return { appointmentInfo: initialState.appointmentInfo, status: DataStatus.FAILED, code: 0 };
    }
  },
);

const getAcceptNotification = (code: number, action: 'add' | 'update'): INotificationState => {
  if (code === 200) {
    return {
      type: 'success',
      title: i18next.t('delivery_date_confirmed'),
      subtitle: action === 'add' ? i18next.t(`successfully_specified_and_confirmed_handover_date`) : i18next.t(`appointment_updated`),
      caption: '',
      timeout: action === 'add' ? 8000 : 5000,
    };
  } else if (code === 201) {
    return {
      type: 'warning',
      title: i18next.t('warning'),
      subtitle: action === 'add' ? i18next.t(`appointment_created_but_ERP_system_not_updated`) : i18next.t(`appointment_updated_but_ERP_system_not_updated`),
      caption: '',
    };
  } else if (code === 202) {
    return {
      type: 'warning',
      title: i18next.t('warning'),
      subtitle: action === 'add' ? i18next.t(`appointment_created_but_system_not_updated`) : i18next.t(`appointment_updated_but_system_not_updated`),
      caption: '',
    };
  }

  return {
    type: 'error',
    title: i18next.t('error'),
    subtitle: action === 'add' ? i18next.t(`appointment_could_not_created`) : i18next.t(`appointment_could_not_updated`),
    caption: '',
  };
};

export interface IUpdateAppointmentParams {
  idAppointment: number;
  status: AppointmentStatus.ACCEPTED | AppointmentStatus.DECLINED;
  appointmentAt: Date;
}

export const updateVehicleAppointment = createAsyncThunk<VehicleAppointmentState, IUpdateAppointmentParams>(
  `${VehicleAppointment}/update`,
  async (params: IUpdateAppointmentParams, thunkAPI): Promise<VehicleAppointmentState> => {
    try {
      const url = `api/v1/appointment`;

      const response = await HttpUtil.put<ICodeResponse>(url, params);

      thunkAPI.dispatch(showNotification(getAcceptNotification(response.data.code, 'update')));

      return { code: response.data.code, status: DataStatus.IDLE };
    } catch (err) {
      console.error('Backend not found', err);
      thunkAPI.dispatch(showNotification(getAcceptNotification(0, 'update')));

      return { code: 0, status: DataStatus.IDLE };
    }
  },
);

export interface ICreateAppointmentParams {
  idPurchase: number;
  action: AppointmentAction;
  appointmentAt: Date;
  status: AppointmentStatus;
}

export const createVehicleAppointment = createAsyncThunk<VehicleAppointmentState, ICreateAppointmentParams>(
  `${VehicleAppointment}/create`,
  async (params: ICreateAppointmentParams, thunkAPI): Promise<VehicleAppointmentState> => {
    try {
      const url = `api/v1/appointment`;

      const response = await HttpUtil.post<ICodeResponse>(url, params);

      thunkAPI.dispatch(showNotification(getAcceptNotification(response.data.code, 'add')));

      if (response.data.code === 200) {
        thunkAPI.dispatch(getPurchase(params.idPurchase)).catch(console.error);
      }

      return { code: response.data.code, status: DataStatus.IDLE };
    } catch (err) {
      console.error('Backend not found', err);
      thunkAPI.dispatch(showNotification(getAcceptNotification(0, 'add')));

      return { code: 0, status: DataStatus.IDLE };
    }
  },
);

export const VehicleAppointmentSlice = createSlice({
  name: VehicleAppointment,
  initialState,
  reducers: {
    // reducers go here
  },
  extraReducers: builder => {
    builder
      .addCase(getVehicleAppointment.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(getVehicleAppointment.fulfilled, (state, action: PayloadAction<VehicleAppointmentState>) => {
        state.status = action.payload.status;
        state.appointmentInfo = action.payload.appointmentInfo;
      })
      .addCase(updateVehicleAppointment.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(updateVehicleAppointment.fulfilled, (state, action: PayloadAction<VehicleAppointmentState>) => {
        state.status = action.payload.status;
        state.code = action.payload.code;
      })
      .addCase(createVehicleAppointment.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(createVehicleAppointment.fulfilled, (state, action: PayloadAction<VehicleAppointmentState>) => {
        state.status = action.payload.status;
        state.code = action.payload.code;
      });
  },
});

export const getVehicleAppointmentState = (state: RootState): VehicleAppointmentState => state.appointmentReducer;

export default VehicleAppointmentSlice.reducer;
