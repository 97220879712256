import './PriceCondition.scss';

interface PriceConditionProps {
  description: string;
}

const PriceCondition: React.FC<PriceConditionProps> = props => {
  const { description } = props;

  return (
    <div className="price-condition">
      <div className="price-condition__description">{description}</div>
      {props.children}
    </div>
  );
};

export default PriceCondition;
