import './VehicleDetails.scss';
import Image from '../Image/Image';
import { useTranslation } from 'react-i18next';
import AppTable from '../AppTable/AppTable';
import AppModal from '../AppModal/AppModal';
import { FuelType } from '../../models/vehicleDetails';
import AppButton from '../AppButton/AppButton';
import { PurchaseStatus } from '../../models/purchase';
import { sendPurchaseFinalInvoiced, sendvehicleDeliveryStatus } from '../../../redux/reducers/AcceptOfferSlice';
import { useAppDispatch } from '../../../redux/hooks';
import { useState } from 'react';
import { ICondition } from './../../models/condition'
import MultiPriceConditionInfo from '../../../../src/common/components/PriceCondition/MultiPriceConditionInfo';

type VehicleDetails = {
  conditions: ICondition[];
  purchaseStatus: string;
  idPurchase: number;
  manufacturer: string;
  model: string;
  variant: string;
  transmission: string;
  vin: string;
  image: string;
  bodyType: string;
  color: string;
  cubicCapacityLitres: string;
  power: string;
  powerKw: string;
  fuel: FuelType;
  fuelConsumptionCombined: string;
  fuelConsumptionInTown: string;
  fuelConsumptionOutOfTown: string;
  electricRange: string;
  co2EmissionValue: string;
  co2EmissionClass: string;
  co2EfficiencyClass: string;
  equipments: string[];
  description: string| null;
};

const VehicleDetails: React.FC<VehicleDetails> = (props: VehicleDetails) => {
  const {
    conditions,
    purchaseStatus,
    idPurchase,
    manufacturer,
    model,
    variant,
    transmission,
    vin,
    image,
    power,
    powerKw,
    fuel,
    electricRange,
    description,
  } = props;
 
  const { t } = useTranslation('vehicles');
  const dispatch = useAppDispatch();
  const [showvehicleDeliveryModal, setShowvehicleDeliveryModal] = useState(false);

  const fuelTypeTranslation: { [key: string]: string } = {
    [FuelType.PETROL]: t('petrol'),
    [FuelType.DIESEL]: t('diesel'),
    [FuelType.ELECTRIC]: t('electric'),
    [FuelType.NATURAL_GAS]: t('natural_gas'),
    [FuelType.HYBRID]: t('hybrid'),
    [FuelType.MILD_HYBRID_GAS]: t('mild_hybrid_petrol'),
    [FuelType.MILD_HYBRID_DIESEL]: t('mild_hybrid_diesel'),
    [FuelType.PLUG_IN_HYBRID]: t('plug_in_hybrid'),
    [FuelType.UNKNOWN]: t('unknown'),
  };

  const onSendPurchaseFinalInvoiced = (idPurchase: number) => {
    (async function () {
      await dispatch(sendPurchaseFinalInvoiced({ idPurchase }));
    })();
  };

  const onSendvehicleDeliveryStatus = (idPurchase: number) => {
    (async function () {
      await dispatch(sendvehicleDeliveryStatus({ idPurchase, status: PurchaseStatus.READY_FOR_PICK_UP || PurchaseStatus.SCHEDULED_FOR_PICK_UP }));
    })();
  };

  const onSendvehicleDeliveryModal = () => {
    setShowvehicleDeliveryModal(true);
  };
  const renderVehicleDetailsTable = (fuel: FuelType) => {
    const rows = [
      {
        id: 'model',
        name: t('model_name'),
        value: `${manufacturer} ${model} ${variant}`,
      },
      {
        id: 'fuel',
        name: t('fuel_type'),
        value: fuel in fuelTypeTranslation ? fuelTypeTranslation[fuel] : '',
      },
      {
        id: 'transmission',
        name: t('transmission'),
        value: `${t(transmission)}`,
      },
      {
        id: 'vin',
        name: t('VIN'),
        value: vin,
      },
      {
        id: 'power',
        name: t('performance'),
        value: fuel == FuelType.ELECTRIC ? `${powerKw} kWh` : `${power} PS`,
      },
      {
        id: 'electric-range',
        name: t('range'),
        value: electricRange,
      },
    ];

    return (
      <AppTable
        rows={rows.filter(row => {
          if (fuel === FuelType.ELECTRIC) {
            return !['consumption', 'capacity', 'co2'].includes(row.id);
          }

          if ([FuelType.HYBRID, FuelType.MILD_HYBRID_DIESEL, FuelType.MILD_HYBRID_GAS, FuelType.PLUG_IN_HYBRID].includes(fuel)) {
            return !['consumption'].includes(row.id);
          }

          return !['electric-range'].includes(row.id);
        })}
        headers={[
          {
            key: 'name',
            header: 'Name',
          },
          {
            key: 'value',
            header: 'Data',
          },
        ]}
        size="compact"
      />
    );
  };

  return (
    <div className="vehicle-details">
      <div className="vehicle-details--image">
        <Image link={image} alt="Modelpicture" size="large" />
      </div>
      <div className="vehicle-details--info">
        <div className="vehicle-details--info__title fp--productive-heading-02">{t('vehicle_details')}</div>
        <div className="vehicle-details--info__content fp--body-short-01">{renderVehicleDetailsTable(fuel)}</div>
        <div className="buttonTopspace">
          {purchaseStatus == 'ready-for-pick-up' || purchaseStatus == 'scheduled-for-pick-up' ? (
            <AppButton
              kind="primary"
              onClick={() => {
                onSendvehicleDeliveryModal();
              }}
            >
              {t('vehicle_delivery')}
            </AppButton>
          ) : (
            ''
          )}
        </div>
        <div>
          <AppModal
            open={showvehicleDeliveryModal}
            className={'isSuperregional'}
            onRequestClose={() => {
              setShowvehicleDeliveryModal(false);
            }}
            onRequestSubmit={() => {
              onSendvehicleDeliveryStatus(idPurchase);
              setShowvehicleDeliveryModal(false);
            }}
            onSecondarySubmit={() => {
              setShowvehicleDeliveryModal(false);
            }}
            primaryButtonText={t('confirm')}
            secondaryButtonText={t('abort')}
            modalHeading={''}
            showSecondaryButton={true}
            size="xs"
          >
            <p>{t('vehicle_delivery_confirm_message')}</p>
          </AppModal>
        </div>
        <div className="vehicle-details--options">
          <div className="vehicle-details--options__title fp--productive-heading-02">{t('features')}</div>

          <div>
            {description}
          </div>

          {purchaseStatus == 'invoiced' ? (
            <AppButton
              kind="primary"
              onClick={() => {
                onSendPurchaseFinalInvoiced(idPurchase);
              }}
            >
              {t('request_invoice_via_email')}
            </AppButton>
          ) : (
            ''
          )}
        </div>
         {
            (conditions.length > 0) ? (
              <div className="accept-offer__details accept-offer__heading">
                <div className="vehicle-details--options">
                  <div className="vehicle-details--options__title fp--productive-heading-02 mt-2">{t('conditions')}</div>
                  {conditions && <MultiPriceConditionInfo conditions={conditions} />}
                </div>
              </div>
            ) : ''
          }
      </div>
    </div>
  );
};

export default VehicleDetails;
