import IDataConverter from '../../common/models/IDataConverter';
import { IMarketplace } from '../../common/models/subscription';

export interface IRawMarketplace {
  id: number;
  name: string;
  url: string;
}

class MarketplaceConverter implements IDataConverter<IRawMarketplace, IMarketplace> {
  convert(from: IRawMarketplace): IMarketplace {
    return {
      id: from.id || 0,
      name: from.name || '',
      url: from.url || '',
    };
  }
}

export default MarketplaceConverter;
