import { IAppointment } from './appointment';
import { ICondition } from './condition';
import { IMarketplace } from './dealer';
import { IPurchaseSubscription } from './purchaseSubscription';

export enum PurchaseStatus {
  PENDING = 'pending',
  DECLINED = 'declined',
  ACCEPTED = 'accepted',
  SCHEDULED_FOR_PICK_UP = 'scheduled-for-pick-up',
  READY_FOR_PICK_UP = 'ready-for-pick-up',
  PICKED_UP = 'picked-up',
  SCHEDULED_FOR_RETURN = 'scheduled-for-return',
  RETURNED = 'returned',
  INVOICED = 'invoiced',
  INVOICE_PAID = 'invoicepaid',
  FINISHED = 'finished',
  CANCELED = 'canceled',
  REJECTED = 'rejected',
  SCHEDULE_DECLINED = 'schedule_declined', //used only in front end
  UNKNOWN = 'unknown', //fallback in case erp adds new status
  REGISTERED = 'registered',
}

export interface IVehicle {
  id: number;
  vin: string | null;
  orbit: string | null;
  plateNumber: string | null;
  car_registration_status: string | null;
  registrationDate: Date | null;
}

export enum TaskType {
  CHECK_IN = 'check-in',
  CHECK_OUT = 'check-out',
  CHECK_OUT_TASK = 'checkout-task',
  CHECK_IN_TASK = 'checkin-task',
  CHECK_OUT_HINT = 'checkout-hint',
  CHECK_IN_HINT = 'checkin-hint',
  CHECKIN_APPOINTMENT_TASK = 'checkin-appointment-task',
  CHECKOUT_APPOINTMENT_TASK = 'checkout-appointment-task',
  UNKNOWN = 'unknown',
}

export enum DocumentUsageType {
  ASSESMENT = 'assessment',
  CHECK_IN_PROTOCOL = 'check-in-protocol',
  CHECK_OUT_PROTOCOL = 'check-out-protocol',
  INVOICE = 'invoice',
  REGISTRATION = 'registration',
  UNKNOWN = 'unknown',
}

export interface ITask {
  id: number;
  erpId: string;
  description: string;
  auto_identifier: string | null;
  type: TaskType;
  todo: boolean;
  isFinished: boolean;
  finishedAt: Date | null;
}

export interface ICustomer {
  contactName: string;
  address: string;
  zipCode: string;
  city: string;
  phone: string;
  email: string;
}

export interface IDocument {
  id: number;
  name: string;
  type: string;
  uri: string;
  usage: string;
  createdAt: Date;
}

export interface IPurchase {
  id: number;
  number: string;
  erpId: string;
  status: PurchaseStatus;
  declinedAt: Date | null;
  acceptedAt: Date | null;
  estimatedPickUp: Date | null;
  pickedUpdAt: Date | null;
  estimatedReturn: Date | null;
  returnedAt: Date | null;
  invoicedAt: Date | null;
  invoicePaidAt: Date | null;
  finishedAt: Date | null;
  canceledAt: Date | null;
  cancelReason: string | null;
  subscription: IPurchaseSubscription;
  vehicle: IVehicle | null;
  appointments: IAppointment[];
  tasks: ITask[];
  erpCreatedAt: Date;
  customer: ICustomer;
  marketplace: IMarketplace;
  transportType: number | null;
  condition: ICondition;
  offerExpiresAt: Date | null;
  superregional: boolean;
  rejectedAt: Date | null;
  offeredAt: Date | null;
  isFollowUpContract: boolean;
  mandatoryReportableClaim: boolean;
  checkoutAvailable: boolean;
  checkinAvailable: boolean;
  documents: IDocument[];
}
