import React from 'react';
import ProcessBar from '../../../common/components/ProcessBar/ProcessBar';
import { useAppSelector } from '../../../redux/hooks';
import { getVehiclesState } from '../../../redux/reducers/VehiclesSlice';
import { ITranslatedHeadlines } from '../Vehicles';

const VehiclesProcessBar: React.FC<{
  translatedHeadlines: ITranslatedHeadlines;
}> = props => {
  const state = useAppSelector(getVehiclesState);
  const { vehicles } = state;
  const { translatedHeadlines } = props;
  const ListItemsKeys = Object.keys(vehicles);

  return (
    <ProcessBar
      items={[
        { status: ListItemsKeys[2], statusName: translatedHeadlines.offers, notifications: vehicles.offer.length, disabled: vehicles.offer.length <= 0 },
        {
          status: ListItemsKeys[3],
          statusName: translatedHeadlines.handovers,
          notifications: vehicles.handOver.length,
          disabled: vehicles.handOver.length <= 0,
        },
        {
          status: ListItemsKeys[4],
          statusName: translatedHeadlines.atCustomer,
          notifications: vehicles.atCustomer.length,
          disabled: vehicles.atCustomer.length <= 0,
        },
        { status: ListItemsKeys[5], statusName: translatedHeadlines.return, notifications: vehicles.return.length, disabled: vehicles.return.length <= 0 },
        { status: ListItemsKeys[6], statusName: translatedHeadlines.completed, disabled: vehicles.completed.length <= 0 },
      ]}
    />
  );
};

export default VehiclesProcessBar;
