import IDataConverter from '../../common/models/IDataConverter';
import { IPurchase, PurchaseStatus} from '../../common/models/purchase';
import AppointmentConverter, { IRawAppointment } from './AppointmentConverter';
import ConditionConverter, { IRawCondition } from './ConditionConverter';
import CustomerConverter, { IRawCustomer } from './CustomerConverter';
import { IRawMarketplace } from './DealerConverted';
import PurchaseSubscriptionConverter, { IRawPurchaseSubscription } from './PurchaseSubscriptionConverter';
import TaskConverter, { IRawTask } from './TaskConverter';
import VehicleConverter, { IRawVehicle } from './VehicleConveter';
import DocumentConvertor, { IRawDocument } from './DocumentConvertor';

export interface IRawPurchase {
  id: number;
  number: string;
  erpId: string;
  status: string;
  declinedAt: string | null;
  acceptedAt: string | null;
  estimatedPickUp: string | null;
  pickedUpdAt: string | null;
  estimatedReturn: string | null;
  returnedAt: string | null;
  invoicedAt: string | null;
  invoicePaidAt: string | null;
  finishedAt: string | null;
  erpCreatedAt: string | null;
  canceledAt: string | null;
  marketplace: IRawMarketplace;
  cancelReason: string | null;
  subscription: IRawPurchaseSubscription;
  vehicle: IRawVehicle | null;
  appointments: IRawAppointment[];
  documents: IRawDocument[];
  tasks: IRawTask[];
  customer: IRawCustomer;
  transportType: number | null;
  condition: IRawCondition;
  offerExpiresAt: string | null;
  rejectedAt: string | null;
  superregional: boolean;
  offeredAt: string | null;
  isFollowUpContract: boolean;
  mandatoryReportableClaim: boolean;
  checkoutAvailable: boolean;
  checkinAvailable: boolean;
}

class PurchaseConverter implements IDataConverter<IRawPurchase, IPurchase> {

  toPurchaseStatus = (value: string): PurchaseStatus => {
    switch (value) {
      case PurchaseStatus.PENDING:
        return PurchaseStatus.PENDING;

      case PurchaseStatus.DECLINED:
        return PurchaseStatus.DECLINED;

      case PurchaseStatus.ACCEPTED:
        return PurchaseStatus.ACCEPTED;

      case PurchaseStatus.SCHEDULED_FOR_PICK_UP:
        return PurchaseStatus.SCHEDULED_FOR_PICK_UP;

      case PurchaseStatus.READY_FOR_PICK_UP:
        return PurchaseStatus.READY_FOR_PICK_UP;

      case PurchaseStatus.PICKED_UP:
        return PurchaseStatus.PICKED_UP;

      case PurchaseStatus.SCHEDULED_FOR_RETURN:
        return PurchaseStatus.SCHEDULED_FOR_RETURN;

      case PurchaseStatus.RETURNED:
        return PurchaseStatus.RETURNED;

      case PurchaseStatus.INVOICED:
        return PurchaseStatus.INVOICED;

      case PurchaseStatus.INVOICE_PAID:
        return PurchaseStatus.INVOICE_PAID;

      case PurchaseStatus.FINISHED:
        return PurchaseStatus.FINISHED;

      case PurchaseStatus.CANCELED:
        return PurchaseStatus.CANCELED;

      default:
        return PurchaseStatus.UNKNOWN;
    }
  };

  convert(from: IRawPurchase): IPurchase {
    const subscriptionConverter = new PurchaseSubscriptionConverter();
    const vehicleConverter = new VehicleConverter();
    const appointmentConverter = new AppointmentConverter();
    const taskConverter = new TaskConverter();
    const customerConverter = new CustomerConverter();
    const conditionConverter = new ConditionConverter();
    const documentConverter = new DocumentConvertor();

    return {
      id: from.id || 0,
      number: from.number || '',
      erpId: from.erpId || '',
      status: this.toPurchaseStatus(from.status),
      declinedAt: from.declinedAt ? new Date(from.declinedAt) : null,
      acceptedAt: from.acceptedAt ? new Date(from.acceptedAt) : null,
      estimatedPickUp: from.estimatedPickUp ? new Date(from.estimatedPickUp) : null,
      pickedUpdAt: from.pickedUpdAt ? new Date(from.pickedUpdAt) : null,
      estimatedReturn: from.estimatedReturn ? new Date(from.estimatedReturn) : null,
      returnedAt: from.returnedAt ? new Date(from.returnedAt) : null,
      invoicedAt: from.invoicedAt ? new Date(from.invoicedAt) : null,
      invoicePaidAt: from.invoicePaidAt ? new Date(from.invoicePaidAt) : null,
      finishedAt: from.finishedAt ? new Date(from.finishedAt) : null,
      cancelReason: from.cancelReason,
      canceledAt: from.canceledAt ? new Date(from.canceledAt) : null,
      subscription: subscriptionConverter.convert(from.subscription),
      vehicle: from.vehicle ? vehicleConverter.convert(from.vehicle) : null,
      appointments: from.appointments ? from.appointments.map(appointmentConverter.convert) : [],
      tasks: from.tasks ? from.tasks.map(taskConverter.convert) : [],
      erpCreatedAt: from.erpCreatedAt ? new Date(from.erpCreatedAt) : new Date(),
      customer: customerConverter.convert(from.customer || {}),
      transportType: from.transportType || null,
      condition: conditionConverter.convert(from.condition || {}),
      marketplace: from.marketplace || {},
      offerExpiresAt: from.offerExpiresAt ? new Date(from.offerExpiresAt) : null,
      rejectedAt: from.rejectedAt ? new Date(from.rejectedAt) : null,
      superregional: from.superregional || false,
      offeredAt: from.offeredAt ? new Date(from.offeredAt) : null,
      isFollowUpContract: from.isFollowUpContract || false,
      mandatoryReportableClaim: from.mandatoryReportableClaim || false,
      checkoutAvailable: from.checkoutAvailable || false,
      checkinAvailable: from.checkinAvailable || false,
      documents: from.documents ? from.documents.map(documentConverter.convert) : [],
    };
  }
}

export default PurchaseConverter;
