import './Image.scss';
import ImagePlaceHolder from '../../../assets/icons/Placeholder_Image.svg';

export interface ImageProps {
  link?: string;
  size?: 'small' | 'medium' | 'large';
  alt?: string;
  caption?: string;
}

const Image: React.FC<ImageProps> = (props: ImageProps) => {
  const { link, size, alt, caption } = props;

  return (
    <div className={`image ${size ? size : 'small'} ${link ? '' : 'place-holder'}`}>
      {
        //FPHP-270 show placeholder also for erp mock default url
        link && link !== 'https://files.example.com/some/path/image.jpg' ? (
          <img src={link} alt={alt} loading="lazy" />
        ) : (
          <img src={ImagePlaceHolder} loading="lazy" />
        )
      }
      {caption && <p className="caption">{caption}</p>}
    </div>
  );
};

export default Image;
