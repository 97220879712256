import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IVehiclesDetailsProps } from '../../../modules/vehicleDetails/VehicleDetails';
import AcceptOfferTemplate from '../../../modules/vehicles/components/AcceptOfferTemplate/AcceptOfferTemplate';
import { IAcceptOfferModalProps } from '../../../modules/vehicles/components/VehiclesBody';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getPurchase, getPurchaseState } from '../../../redux/reducers/PurchaseSlice';
import { acceptOffer } from '../../../redux/reducers/AcceptOfferSlice';
import { show } from '../../../redux/reducers/OverlaySlice';
import { enableHideOfferAnimation } from '../../../redux/reducers/VehiclesSlice';
import { IDashboardItem } from '../../models/dashboard';
import AppButton from '../AppButton/AppButton';
import AppModal from '../AppModal/AppModal';
import AppTooltip from '../AppTooltip/AppTooltip';
import Image from '../Image/Image';
import { OverlayPages } from '../Overlay/overlayPages';
import './DashboardItem.scss';

const DashboardItem: React.FC<IDashboardItem> = (props: IDashboardItem) => {
  const { status, medias, manufacturer, model, variant, dueDate, dueTime, idPurchase, idVehicleTemplate, idVehicle, idSubscription } = props;
  const { t } = useTranslation('vehicles');
  const dispatch = useAppDispatch();
  const modelCompleteName = manufacturer + ' ' + model + ' ' + variant;
  const maxNROfCharacters = 28;
  const statePurchase = useAppSelector(getPurchaseState);
  const { selectedPurchase } = statePurchase;
  const [reason, setReason] = useState('');
  const emptyAcceptOfferModal: IAcceptOfferModalProps = {
    isOpen: false,
    title: '',
    idPurchase: 0,
  };
  const [acceptOfferModalProps, showAcceptOfferModal] = useState<IAcceptOfferModalProps>(emptyAcceptOfferModal);
  const onAcceptOffer = (idPurchase: number, status: 'accepted' | 'declined', reason: string | null) => {
    (async function () {
      await dispatch(acceptOffer({ idPurchase, status, reason })).catch(console.error);
    })();
    showAcceptOfferModal(emptyAcceptOfferModal);
    dispatch(enableHideOfferAnimation({ idPurchase }));
  };
  useEffect(() => {
    dispatch(getPurchase(acceptOfferModalProps.idPurchase)).catch(err => console.error(err));
  }, []);
  const [showofferconfirmModal, setShowofferconfirmModal] = useState(false);
  const renderDashboardItemText = () => {
    let text = '';

    if (status == 'pending') {
      text = t('check_and_confirm') + ' ' + dueDate + ', ' + dueTime;
    }
    if (status == 'ready-for-pick-up') {
      text = t('agree_and_confirm') + ' ' + dueDate;
    }
    if (status == 'scheduled-for-pick-up') {
      text = `${t('pickup_on')} ${dueDate}, ${dueTime}`;
    }
    if (status == 'scheduled-for-return') {
      text = `${t('return_on')} ${dueDate}, ${dueTime}`;
    }

    return <span>{text}</span>;
  };

  const renderDashboardItemHeadline = () => {
    let headline = '';
    if (status == 'pending' || status == 'ready-for-pick-up') {
      headline = t('next_task');
    } else {
      headline = t('next_handover');
    }
    return <span>{headline}</span>;
  };

  const onShowDetails = () => {
    const contentProps: IVehiclesDetailsProps = { idVehicleTemplate, idSubscription, idPurchase, idVehicle, selectedTab: 'appointments' };

    const headline = `${manufacturer} ${model} ${variant}`;

    dispatch(show({ headline, content: OverlayPages.VEHICLE_DETAILS, contentProps }));
  };
  const renderDashboardItemCta = () => {
    let ctaText = '';
    let ctaColor = '';
    if (status == 'pending') {
      return (
        <AppButton
          kind="primary"
          onClick={() => {
            showAcceptOfferModal({
              isOpen: true,
              title: `${manufacturer} ${model} ${variant} - ${t('check_offer')}`,
              idPurchase: idPurchase,
            });
          }}
        >
          {t('check_offer')}
        </AppButton>
      );
    } else {
      if (status == 'ready-for-pick-up') {
        ctaText = t('reschedule_appointment');
        ctaColor = 'orange';
      } else if (status == 'scheduled-for-pick-up') {
        ctaText = t('shift_schedule');
        ctaColor = 'orange';
      } else {
        ctaText = t('handover_details');
        ctaColor = 'gray';
      }
      return (
        <AppButton onClick={() => onShowDetails()} kind={ctaColor == 'orange' ? 'primary' : 'secondary'}>
          {ctaText}
        </AppButton>
      );
    }
  };

  const renderModals = () => {
    return (
      <AppModal
        open={acceptOfferModalProps.isOpen}
        className={'isSuperregional'}
        onRequestClose={() => showAcceptOfferModal(emptyAcceptOfferModal)}
        onRequestSubmit={() => onAcceptOffer(acceptOfferModalProps.idPurchase, 'accepted', reason)}
        onSecondarySubmit={() => {
          setShowofferconfirmModal(true);
          showAcceptOfferModal(emptyAcceptOfferModal);
        }}
        primaryButtonText={t('confirm_offer')}
        secondaryButtonText={t('reject_offer')}
        modalHeading={acceptOfferModalProps.title}
        showSecondaryButton={true}
      >
        {acceptOfferModalProps.isOpen && <AcceptOfferTemplate idPurchase={acceptOfferModalProps.idPurchase} />}
      </AppModal>
    );
  };
  const renderDashboardItem = () => {
    return (
      <div className="dashboard-item">
        <div className="dashboard-item-header">
          <p>{renderDashboardItemHeadline()}</p>
          <a className="bx--link" href="/calendar">
            {t('to_the_calendar')}
          </a>
        </div>
        <div className="dashboard-item-body">
          {medias.length ? <Image link={medias[0].path} alt={model + ' ' + variant} size="small" /> : <Image size="small" />}

          <div className="dashboard-model-info">
            <AppTooltip maxNROfCharacters={maxNROfCharacters} tooltipText={modelCompleteName} className="dashboard-model">
              <div className="dashboard-model fp--productive-heading-01">{modelCompleteName}</div>
            </AppTooltip>
            <div>{renderDashboardItemText()}</div>
          </div>
          <div>{renderDashboardItemCta()}</div>
        </div>
      </div>
    );
  };

  return (
    <span>
      {renderModals()}
      {renderDashboardItem()}
      {showofferconfirmModal ? (
        <div className="offerconfirm">
          <AppModal
            open={true}
            className={'isSuperregional'}
            onRequestClose={() => {
              setShowofferconfirmModal(false);
            }}
            onRequestSubmit={() => {
              onAcceptOffer(idPurchase, 'declined', reason);
              setShowofferconfirmModal(false);
            }}
            onSecondarySubmit={() => {
              setShowofferconfirmModal(false);
            }}
            primaryButtonText={t('confirm')}
            secondaryButtonText={t('abort')}
            modalHeading={''}
            showSecondaryButton={true}
            size="xs"
          >
            <p>{t('decline_the_offer')}</p>
            {selectedPurchase?.isFollowUpContract === true ? (
              <div>
                <textarea placeholder="Please enter a cancel reason" onChange={e => setReason(e.target.value)} />
              </div>
            ) : (
              ''
            )}
          </AppModal>
        </div>
      ) : null}
    </span>
  );
};

export default DashboardItem;
