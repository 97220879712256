import { DashboardStatus, IDashboardItem, IDashboardItemMedia } from '../../common/models/dashboard';
import IDataConverter from '../../common/models/IDataConverter';
import AppointmentConverter, { IRawAppointment } from './AppointmentConverter';

interface IRawDashboardItemMedia {
  name: string;
  path: string;
  type: string;
  id: number;
  createdAt: string;
  updatedAt: string;
}

export interface IRawDashboardItem {
  idPurchase: number;
  idSubscription: number;
  idVehicleTemplate: number;
  idVehicle: number;
  status: 'string';
  medias: IRawDashboardItemMedia[];
  manufacturer: string;
  model: string;
  variant: string;
  dueDate: string;
  dueTime: string;
  appointment: IRawAppointment;
}

class DashboardConverter implements IDataConverter<IRawDashboardItem, IDashboardItem> {
  private convertMedia = (from: IRawDashboardItemMedia): IDashboardItemMedia => {
    return {
      name: from.name || '',
      path: from.path || '',
      type: from.type || '',
      id: from.id || 0,
      createdAt: from.createdAt ? new Date(from.createdAt) : new Date(),
      updatedAt: from.updatedAt ? new Date(from.updatedAt) : new Date(),
    };
  };

  private toDashboardStatus = (value: string): DashboardStatus => {
    switch (value) {
      case DashboardStatus.PENDING:
        return DashboardStatus.PENDING;

      case DashboardStatus.SCHEDULED_FOR_PICK_UP:
        return DashboardStatus.SCHEDULED_FOR_PICK_UP;

      case DashboardStatus.SCHEDULED_FOR_RETURN:
        return DashboardStatus.SCHEDULED_FOR_RETURN;

      case DashboardStatus.READY_FOR_PICK_UP:
        return DashboardStatus.READY_FOR_PICK_UP;

      default:
        return DashboardStatus.UNKNOWN;
    }
  };

  convert(from: IRawDashboardItem): IDashboardItem {
    const appointmentConverter = new AppointmentConverter();

    return {
      idPurchase: from.idPurchase || 0,
      idSubscription: from.idSubscription || 0,
      idVehicleTemplate: from.idVehicleTemplate || 0,
      idVehicle: from.idVehicle || 0,
      status: this.toDashboardStatus(from.status || ''),
      medias: from.medias ? from.medias.map(this.convertMedia) : [],
      manufacturer: from.manufacturer || '',
      model: from.model || '',
      variant: from.variant || '',
      dueDate: from.dueDate || '',
      dueTime: from.dueTime || '',
      appointment: from.appointment ? appointmentConverter.convert(from.appointment) : null,
    };
  }
}

export default DashboardConverter;
