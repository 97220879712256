import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { DataStatus } from '../../common/models/dataStatus';
import { ICodeResponse } from '../../common/models/ICodeResponse';
import HttpUtil from '../../utils/HttpUtil';
import { ReleaseVehicleTemplate } from '../reducerNames';
import { RootState } from '../store';
import { INotificationState, showNotification } from './NotificationsSlice';
import { filterVehiclesData, getVehiclesData } from './VehiclesSlice';

export interface ReleaseVehicleTemplateState extends ICodeResponse {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
}

const initialState: ReleaseVehicleTemplateState = {
  status: DataStatus.IDLE,
  code: 0,
};

export interface IReleaseVehicleTemplateParams {
  idVehicleTemplate: number;
  idDealer?: number;
}

const getNotification = (code: number): INotificationState => {
  if (code === 200) {
    return {
      type: 'success',
      title: i18next.t('success'),
      subtitle: i18next.t('vehicle_released'),
      caption: '',
    };
  } else if (code === 201) {
    return {
      type: 'warning',
      title: i18next.t('warning'),
      subtitle: i18next.t('vehicle_released_but_ERP_not_updated'),
      caption: '',
    };
  } else if (code === 202) {
    return {
      type: 'warning',
      title: i18next.t('warning'),
      subtitle: i18next.t('vehicle_released_but_system_not_updated'),
      caption: '',
    };
  }

  return {
    type: 'error',
    title: i18next.t('error'),
    subtitle: i18next.t('vehicle_could_not_released'),
    caption: '',
  };
};

export const releaseVehicleTemplate = createAsyncThunk<ReleaseVehicleTemplateState, IReleaseVehicleTemplateParams>(
  `${ReleaseVehicleTemplate}/releaseVehicleTemplate`,
  async (params: IReleaseVehicleTemplateParams, thunkAPI): Promise<ReleaseVehicleTemplateState> => {
    try {
      const url = `api/v1/subscription`;

      const response = await HttpUtil.post<ICodeResponse>(url, params);

      const filter = (thunkAPI.getState() as RootState).vehicleReducer.filter;
      if (filter) {
        thunkAPI.dispatch(filterVehiclesData(filter)).catch(console.error);
      } else {
        thunkAPI.dispatch(getVehiclesData()).catch(console.error);
      }

      thunkAPI.dispatch(showNotification(getNotification(response.data.code)));
      return { code: response.data.code, status: DataStatus.IDLE };
    } catch (err) {
      console.error('Backend not found', err);
      thunkAPI.dispatch(showNotification(getNotification(0)));
      return { code: 0, status: DataStatus.FAILED };
    }
  },
);

export const ReleaseVehicleTemplateSlice = createSlice({
  name: ReleaseVehicleTemplate,
  initialState,
  reducers: {
    // reducers go here
  },
  extraReducers: builder => {
    builder
      .addCase(releaseVehicleTemplate.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(releaseVehicleTemplate.fulfilled, (state, action: PayloadAction<ReleaseVehicleTemplateState>) => {
        state.status = action.payload.status;
        state.code = action.payload.code;
      });
  },
});

export default ReleaseVehicleTemplateSlice.reducer;
