import moment from 'moment';

const timeFormat = 'DD.MM.YYYY HH:mm';
const dateOnlyFormat = 'DD.MM.YYYY';
const timeOnlyFormat = 'HH:mm';
const monthyearFormat = 'MMMM YY'

class DateTimeUtil {
  static dayToStringFormat(date: Date): string {
    return moment(date).format(dateOnlyFormat);
  }

  static timeToStringFormat(date: Date): string {
    return moment(date).format(timeOnlyFormat);
  }

  static toStringFormat(date: Date): string {
    return moment(date).format(timeFormat);
  }

  static toStandardStringFormat(date: Date): string {
    return moment(date).format();
  }

  static toStandardMonthYearFormat(date: Date): string {
    return moment(date).format(monthyearFormat);
  }
}

export default DateTimeUtil;
