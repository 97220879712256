import { Button, ButtonDefaultProps, ButtonKindProps } from 'carbon-components-react';
import { ForwardRefProps } from 'carbon-components-react/typings/shared';
import React, { useState } from 'react';

type IAppButton = ForwardRefProps<HTMLButtonElement, ButtonDefaultProps & ButtonKindProps>;

const AppButton: React.FC<IAppButton> = (props: IAppButton) => {
  const [isDisabled, setDisabled] = useState(props.disabled);
  const timer = 1000;

  const preventDoubleClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClick) {
      props.onClick(e);
    }
    if (isDisabled !== true) {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, timer);
    }
  };

  return <Button {...props} disabled={isDisabled} onClick={preventDoubleClickHandler} />;
};

export default AppButton;
