import i18n  from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import EnvUtil from '../utils/EnvUtil';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: String(EnvUtil.get<string>('SDM_I18N') || 'en,de')
      .toLowerCase()
      .split(','),
    fallbackLng: 'en',
    keySeparator: false,
    ns: [
      'account-settings',
      'calendar-vehicles',
      'calendar',
      'dashboard',
      'filter-by-date',
      'history',
      'price-condition',
      'register',
      'vehicle-appointments',
      'vehicles',
      'common',
      'theme-ald_at',
      'theme-ald',
      'theme-default',
    ],
    defaultNS: 'common',
    nsSeparator: false,
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  })
  .catch(console.error);

export default i18n;