import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PriceCondition from '../../../../common/components/PriceCondition/PriceCondition';
import { getCondition, getConditionState } from '../../../../redux/reducers/ConditionSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { InlineNotification, Loading } from 'carbon-components-react';
import { DataStatus } from '../../../../common/models/dataStatus';

import './DeleteSubscription.scss';
import MultiPriceConditionInfo from '../../../../common/components/PriceCondition/MultiPriceConditionInfo';

const DeleteSubscription: React.FC<{ idVehicleTemplate: number }> = props => {
  const { t } = useTranslation('vehicles');
  const dispatch = useAppDispatch();
  const state = useAppSelector(getConditionState);
  const { status, conditions } = state;
  const { idVehicleTemplate: idVehicleTemplate } = props;

  useEffect(() => {
    dispatch(getCondition({ idVehicleTemplate: idVehicleTemplate })).catch(console.error);
  }, []);

  const render = () => {
    if (status === DataStatus.LOADING) {
      return (
        <div className="delete-subscription__loading">
          <Loading description="Loading items" withOverlay={false} />
        </div>
      );
    }
    if (status === DataStatus.FAILED) {
      return (
        <InlineNotification
          iconDescription="describes the close button"
          title={t('error_subscription_information') as string}
          notificationType="inline"
          kind="error"
        />
      );
    }

    if (!conditions) {
      return (
        <InlineNotification
          iconDescription="describes the close button"
          title={t('error_subscription_information') as string}
          notificationType="inline"
          kind="error"
        />
      );
    }

    return <PriceCondition description={t('offer_again_in_marketplace')}>{conditions && <MultiPriceConditionInfo conditions={conditions} />}</PriceCondition>;
  };

  return <div className="delete-subscription">{render()}</div>;
};

export default DeleteSubscription;
