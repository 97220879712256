import './ListItem.scss';
import AppIcon from '../AppIcon/AppIcon';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import {
  IAtCustomerVehicle,
  ICompletedVehicle,
  IHandOverVehicle,
  IMarketplaceVehicle,
  IOfferVehicle,
  IReleaseVehicle,
  IReturnVehicle,
  IVehicleAtCustomerDetails,
  IVehicleCompletedDetails,
  IVehicleHandOverDetails,
  IVehicleMarketplaceDetails,
  IVehicleOfferDetails,
  IVehiclePurchaseDetails,
  IVehicleReleaseDetails,
  IVehicleReturnDetails,
  VehiclesStatus,
} from '../../models/vehicles';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';
import Image from '../Image/Image';
import { PurchaseStatus } from '../../models/purchase';
import { HistoryStatus, IHistoryVehicle } from '../../../modules/history/History';
import AppTooltip from '../AppTooltip/AppTooltip';
import { CarbonIconType } from '@carbon/icons-react';
import {
  hasPickupAppointment,
  hasReturnAppointment,
  getPickupAppointments,
  getReturnAppointments,
  formatAppointmentDate,
  formatAppointmentDateTime,
} from '../../../utils/AppointmentUtil';

type ListItemProps = {
  item: IReleaseVehicle | IMarketplaceVehicle | IOfferVehicle | IHandOverVehicle | IAtCustomerVehicle | ICompletedVehicle | IReturnVehicle | IHistoryVehicle;
  button: ReactElement;
  onShowDetails: () => void;
  page?: string;
};

const ListItem: React.FC<ListItemProps> = (props: ListItemProps) => {
  const { t } = useTranslation('vehicles');

  const { image, manufacturer, model, variant, fuel, transmission, details, status, animation } = props.item;
  const { button, onShowDetails, page } = props;
  const completeModelName = manufacturer + ' ' + model + ' ' + variant;

  const getStatusTranslation = (status: VehiclesStatus): string => {
    switch (status) {
      case VehiclesStatus.RELEASE:
        return t('release');
      case VehiclesStatus.MARKETPLACE:
        return t('bookable');
      case VehiclesStatus.OFFER: {
        const customDetails = getCustomDetails<IVehicleOfferDetails>();
        const autoAcceptedStatus = customDetails?.purchase?.cancelReason == 'auto-accepted' ? `( ${t('auto')} )` : '';
        return customDetails.status === PurchaseStatus.ACCEPTED ? `${t('accepted')} ${autoAcceptedStatus}` : t('inquiry');
      }

      case VehiclesStatus.HANDOVER: {
        const customDetails = getCustomDetails<IVehicleHandOverDetails>();
        if (customDetails.status === PurchaseStatus.READY_FOR_PICK_UP) return t('ready');
        else return t('planned');
      }
      case VehiclesStatus.AT_CUSTOMER:
        return t('at_the_customer');
      case VehiclesStatus.RETURN: {
        const customDetails = getCustomDetails<IVehicleReturnDetails>();
        if (customDetails.status === PurchaseStatus.RETURNED) return t('return_back');
        else return t('planned');
      }
      case VehiclesStatus.COMPLETED: {
        const customDetails = getCustomDetails<IVehicleCompletedDetails>();
        if (customDetails.status === PurchaseStatus.INVOICED) return t('invoiced');
        else if (customDetails.status === PurchaseStatus.INVOICE_PAID) return t('bill_paid');
        else return t('at_the_dealer');
      }
      default:
        return '';
    }
  };

  const getStatusTranslationByPurchaseStatus = (status: HistoryStatus): string => {
    switch (status) {
      case PurchaseStatus.ACCEPTED:
        return t('request_accepted');
      case PurchaseStatus.CANCELED:
        return t('cancelled');
      case PurchaseStatus.DECLINED:
        return t('offer_cancelled');
      case PurchaseStatus.FINISHED:
        return t('completed');
      case PurchaseStatus.INVOICED:
        return t('invoiced');
      case PurchaseStatus.INVOICE_PAID:
        return t('bill_paid');
      case PurchaseStatus.PENDING:
        return t('inquiry');
      case PurchaseStatus.PICKED_UP:
        return t('at_the_customer');
      case PurchaseStatus.READY_FOR_PICK_UP:
        return t('pickup_open');
      case PurchaseStatus.RETURNED:
        return t('return');
      case PurchaseStatus.SCHEDULED_FOR_PICK_UP: {
        return t('pickup_scheduled');
      }
      case PurchaseStatus.SCHEDULED_FOR_RETURN:
        return t('return_scheduled');
      case PurchaseStatus.REJECTED:
        return t('rejected');
      case 'released':
        return t('released');
      case 'release-canceled':
        return t('release_cancelled');
      case 'rescheduled':
        return t('new_pickup');
      case 'registered':
        return t('registered');
      default:
        return '';
    }
  };

  const getCustomDetails = <T extends unknown>(): T => {
    return details as T;
  };

  const renderStatusIcon = () => {
    let statusIcon;
    VehiclesStatus.RELEASE === status && (statusIcon = <AppIcon icon="release" size="xs" />);
    VehiclesStatus.MARKETPLACE === status && (statusIcon = <AppIcon icon="bookable" size="xs" />);
    VehiclesStatus.OFFER === status && (statusIcon = <AppIcon icon="file" size="xs" />);
    (VehiclesStatus.HANDOVER === status || VehiclesStatus.RETURN === status) && (statusIcon = <AppIcon icon="handover" size="xs" />);
    VehiclesStatus.AT_CUSTOMER === status && (statusIcon = <AppIcon icon="house" size="xs" />);
    VehiclesStatus.COMPLETED === status && (statusIcon = <AppIcon icon="complete" size="xs" />);

    return statusIcon;
  };
  const renderStatusIconByHistoryStatus = (status: HistoryStatus): ReactElement | string => {
    let iconName: 'file' | 'complete' | 'handover' | 'house' | 'bookable' | 'release' | 'marketEmpty' | 'modelEmpty' | CarbonIconType | '' = '';
    switch (status) {
      case PurchaseStatus.ACCEPTED:
        iconName = 'file';
        break;
      case PurchaseStatus.CANCELED:
        iconName = 'complete';
        break;
      case PurchaseStatus.DECLINED:
        iconName = 'handover';
        break;
      case PurchaseStatus.FINISHED:
        iconName = 'complete';
        break;
      case PurchaseStatus.INVOICED:
        iconName = 'complete';
        break;
      case PurchaseStatus.INVOICE_PAID:
        iconName = 'complete';
        break;
      case PurchaseStatus.PENDING:
        iconName = 'bookable';
        break;
      case PurchaseStatus.PICKED_UP:
        iconName = 'house';
        break;
      case PurchaseStatus.READY_FOR_PICK_UP:
        iconName = 'handover';
        break;
      case PurchaseStatus.RETURNED:
        iconName = 'handover';
        break;
      case PurchaseStatus.SCHEDULED_FOR_PICK_UP:
        iconName = 'handover';
        break;
      case PurchaseStatus.SCHEDULED_FOR_RETURN:
        iconName = 'handover';
        break;
      case 'released':
        iconName = 'release';
        break;
      case 'release-canceled':
        iconName = 'release';
        break;
      case 'rescheduled':
        iconName = 'handover';
        break;
      case 'registered':
        iconName = 'bookable';
        break;
    }
    return iconName != '' ? <AppIcon icon={iconName} size="xs" /> : '';
  };

  const renderIdentification = () => {
    if (status === VehiclesStatus.HANDOVER) {
      const customDetails = getCustomDetails<IVehicleHandOverDetails>();
      return `${customDetails.vin ? customDetails.vin : customDetails.orbit} ${customDetails.plateNumber}`;
    }
    if (status === VehiclesStatus.OFFER) {
      const customDetails = getCustomDetails<IVehicleHandOverDetails>();
      return `${customDetails.purchase.vehicle?.vin ? customDetails.purchase.vehicle?.vin : customDetails.purchase.vehicle?.orbit} ${
        customDetails.purchase.vehicle?.plateNumber ? customDetails.purchase.vehicle?.plateNumber : ''
      }`;
    }
    if (status === VehiclesStatus.AT_CUSTOMER) {
      const customDetails = getCustomDetails<IVehicleAtCustomerDetails>();
      return `${customDetails.vin} ${customDetails.plateNumber}`;
    }
    if (status === VehiclesStatus.RETURN) {
      const customDetails = getCustomDetails<IVehicleReturnDetails>();
      return `${customDetails.vin ? customDetails.vin : customDetails.orbit} ${customDetails.plateNumber}`;
    }
    if (status === VehiclesStatus.COMPLETED) {
      const customDetails = getCustomDetails<IVehicleCompletedDetails>();
      return `${customDetails.vin} ${customDetails.plateNumber}`;
    }
    return t('order_vehicle');
  };

  const renderCustomInfo = () => {
    let translation = '';
    let customContent: string | ReactElement = '';
    if (page === 'HistoryPage') {
      const historyStatus = (props.item as IHistoryVehicle).historyStatus;
      if (historyStatus === 'released' || historyStatus === 'release-canceled') {
        const customDetails = getCustomDetails<IVehicleMarketplaceDetails>();
        translation = t('release_date');
        customContent = <div>{customDetails.releaseDate}</div>;
      }

      if (historyStatus === PurchaseStatus.DECLINED || historyStatus === PurchaseStatus.ACCEPTED || historyStatus === PurchaseStatus.PENDING) {
        const customDetails = getCustomDetails<IVehiclePurchaseDetails>();
        translation = t('order_number');
        customContent = customDetails.purchase.number;
      }

      if (historyStatus === PurchaseStatus.READY_FOR_PICK_UP) {
        translation = t('pickup');
        customContent = t('open');
      }

      if (historyStatus === PurchaseStatus.SCHEDULED_FOR_PICK_UP) {
        translation = t('pickup');
        customContent = (props.item as IHistoryVehicle).date.toLocaleString('de-DE', {
          month: '2-digit',
          year: 'numeric',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
      }

      if (historyStatus === 'rescheduled') {
        translation = t('pickup_changed');
        customContent = (props.item as IHistoryVehicle).date.toLocaleString('de-DE', {
          month: '2-digit',
          year: 'numeric',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
      }

      if (historyStatus === PurchaseStatus.REGISTERED) {
        translation = t('registered');
        customContent = DateTimeUtil.toStringFormat((props.item as IHistoryVehicle).date);
      }

      if (historyStatus === PurchaseStatus.PICKED_UP) {
        const customDetails = getCustomDetails<IVehicleHandOverDetails>();
        translation = t('delivery');
        customContent = (
          <div>
            <div>{t('carried_out')}</div>
            <div>{customDetails.handOverDateTime}</div>
          </div>
        );
      }

      if (historyStatus === PurchaseStatus.REJECTED) {
        const customDetails = getCustomDetails<IVehiclePurchaseDetails>();
        customContent = (
          <div>
            <div>
              {customDetails.purchase.rejectedAt &&
                customDetails.purchase.rejectedAt.toLocaleString('de-DE', {
                  month: '2-digit',
                  year: 'numeric',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
            </div>
          </div>
        );
      }

      if (historyStatus === PurchaseStatus.SCHEDULED_FOR_RETURN) {
        const customDetails = getCustomDetails<IVehicleReturnDetails>();
        translation = t('esteemed');
        customContent = (
          <div>
            <div>{t('return')}</div>
            <div>
              {customDetails.purchase.estimatedReturn &&
                customDetails.purchase.estimatedReturn.toLocaleString('de-DE', {
                  month: '2-digit',
                  year: 'numeric',
                  day: '2-digit',
                })}
            </div>
          </div>
        );
      }

      if (historyStatus === PurchaseStatus.RETURNED) {
        const customDetails = getCustomDetails<IVehicleReturnDetails>();
        translation = t('return');
        customContent = (
          <div>
            <div>{t('carried_out')}</div>
            <div>
              {customDetails.purchase.returnedAt &&
                customDetails.purchase.returnedAt.toLocaleString('de-DE', {
                  month: '2-digit',
                  year: 'numeric',
                  day: '2-digit',
                })}
            </div>
          </div>
        );
      }

      if (historyStatus === PurchaseStatus.INVOICED) {
        const customDetails = getCustomDetails<IVehicleCompletedDetails>();
        translation = t('invoice');
        customContent = (
          <div>
            <div>{t('placed')}</div>
            <div>
              {customDetails.purchase.invoicedAt &&
                customDetails.purchase.invoicedAt.toLocaleString('de-DE', {
                  month: '2-digit',
                  year: 'numeric',
                  day: '2-digit',
                })}
            </div>
          </div>
        );
      }

      if (historyStatus === PurchaseStatus.INVOICE_PAID) {
        const customDetails = getCustomDetails<IVehicleCompletedDetails>();
        translation = t('invoice');
        customContent = (
          <div>
            <div>{t('paid')}</div>
            <div>
              {customDetails.purchase.invoicePaidAt &&
                customDetails.purchase.invoicePaidAt.toLocaleString('de-DE', {
                  month: '2-digit',
                  year: 'numeric',
                  day: '2-digit',
                })}
            </div>
          </div>
        );
      }

      if (historyStatus === PurchaseStatus.FINISHED || historyStatus === PurchaseStatus.CANCELED) {
        const customDetails = getCustomDetails<IVehicleCompletedDetails>();
        translation = t('buying_process');
        customContent = (
          <div>
            <div>{historyStatus === PurchaseStatus.FINISHED ? t('closed') : t('cancelled')}</div>
            <div>
              {customDetails.purchase.finishedAt &&
                customDetails.purchase.finishedAt.toLocaleString('de-DE', {
                  month: '2-digit',
                  year: 'numeric',
                  day: '2-digit',
                })}
            </div>
          </div>
        );
      }
    } else {
      if (status === VehiclesStatus.RELEASE) {
        const customDetails = getCustomDetails<IVehicleReleaseDetails>();
        translation = t('original_price');
        customContent = customDetails.price ? `${customDetails.price} ${customDetails.currency}` : '-';
      }

      if (status === VehiclesStatus.MARKETPLACE) {
        const customDetails = getCustomDetails<IVehicleMarketplaceDetails>();
        translation = t('marketplaces');
        customContent = <a href={customDetails.url}>{customDetails.marketplace}</a>;
      }
      if (status === VehiclesStatus.OFFER) {
        translation = t('an_order');
        const customDetails = getCustomDetails<IVehicleOfferDetails>();
        if (customDetails.status == 'accepted') {
          translation = t('time_of_acceptance');
        }
        if (customDetails.status == 'pending') {
          translation = t('creation_time');
        }
      }
      const timeLabelTranslated = '';

      if (status === VehiclesStatus.HANDOVER) {
        const customDetails = getCustomDetails<IVehicleHandOverDetails>();
        translation = customDetails.appointments.length > 0 ? t('pickup') : t('estimated_handover');
        if (page == 'CalendarPage' && customDetails.status == PurchaseStatus.READY_FOR_PICK_UP) {
          customContent = t('pickup_open');
        }
        if (page == 'CalendarPage' && customDetails.status == PurchaseStatus.SCHEDULED_FOR_PICK_UP) {
          customContent = getPickupAppointments(customDetails).map(item =>
            item.appointmentAt != null && item.status == 'accepted' ? formatAppointmentDateTime(item.appointmentAt, timeLabelTranslated) : '',
          )[0];
        }
        if (!page) {
          if (!hasPickupAppointment(customDetails)) {
            const dateParts = customDetails.handOverDateTime;
            customContent = dateParts;
          } else {
            customContent = getPickupAppointments(customDetails).map(item =>
              item.appointmentAt != null ? formatAppointmentDateTime(item.appointmentAt, timeLabelTranslated) : '',
            )[0];
          }
          if (customDetails.status === PurchaseStatus.READY_FOR_PICK_UP) {
            translation = t('time_of_delivery');
          }
        }
      }

      if (status === VehiclesStatus.AT_CUSTOMER) {
        const customDetails = getCustomDetails<IVehicleAtCustomerDetails>();
        translation = t('vehicle_file_updated');
        customContent = customDetails.returnDate;
      }

      if (status === VehiclesStatus.RETURN) {
        const customDetails = getCustomDetails<IVehicleReturnDetails>();
        translation = hasReturnAppointment(customDetails) ? t('return') : t('estimated_return');
        if (page == 'CalendarPage' && customDetails.status == PurchaseStatus.RETURNED) {
          customContent = getReturnAppointments(customDetails).map(item =>
            item.appointmentAt != null && item.status == 'requested' ? formatAppointmentDate(item.appointmentAt) : '',
          )[0];
          return customContent;
        }
        if (page == 'CalendarPage' && customDetails.status == PurchaseStatus.SCHEDULED_FOR_RETURN) {
          customContent = getReturnAppointments(customDetails).map(item =>
            item.appointmentAt != null && item.status == 'requested' ? formatAppointmentDateTime(item.appointmentAt, timeLabelTranslated) : '',
          )[0];
          return customContent;
        } else {
          if (!hasReturnAppointment(customDetails)) {
            const dateParts = customDetails.returnDateTime;
            customContent = dateParts;
          } else {
            customContent = getReturnAppointments(customDetails).map(item =>
              item.appointmentAt != null ? formatAppointmentDateTime(item.appointmentAt, timeLabelTranslated) : '',
            )[0];
          }
        }
      }

      if (status === VehiclesStatus.COMPLETED) {
        const customDetails = getCustomDetails<IVehicleCompletedDetails>();
        translation = t('transfer_risk');
        customContent = customDetails.updateDate;
      }

      if (customContent == '') {
        customContent = customDate();
      }
    }

    return (
      <div className="list-item__custom">
        <div className="fp--helper-text-01">{translation}</div>
        <span className="fp--body-short-01" style={{ marginTop: '20px', display: 'inline-block' }}>
          {customContent}
        </span>
        <br />
        <span className="fp--body-short-01" style={{ marginTop: '0px', display: 'block' }}>
          {renderTypeOfVehicle()}
        </span>
      </div>
    );
  };
  const customDate = () => {
    const dateFormatter = DateTimeUtil.toStringFormat;
    if (status === VehiclesStatus.OFFER) {
      const customDetails = getCustomDetails<IVehicleOfferDetails>();
      if (customDetails.status == 'pending') {
        const customContentDate = customDetails.createdDateTime;
        return customContentDate;
      }
    }
    const customDetails = getCustomDetails<IVehicleHandOverDetails>();
    if (customDetails.status === PurchaseStatus.READY_FOR_PICK_UP) {
      const customDetails = getCustomDetails<IVehicleHandOverDetails>();
      const customContentDate = getPickupAppointments(customDetails).map(item => (item.appointmentAt != null ? dateFormatter(item.appointmentAt) : ''))[0];
      return customContentDate;
    }

    if (customDetails.status === PurchaseStatus.SCHEDULED_FOR_PICK_UP) {
      const customDetails = getCustomDetails<IVehiclePurchaseDetails>();
      const customContentDate = (
        <span>
          {customDetails.purchase.estimatedPickUp &&
            customDetails.purchase.estimatedPickUp.toLocaleString('de-DE', {
              month: '2-digit',
              year: 'numeric',
              day: '2-digit',
            }) +
              ', ' +
              customDetails.purchase.estimatedPickUp.toLocaleString('de-DE', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              })}
        </span>
      );
      return customContentDate;
    }

    const customContentDate = (
      <span>
        {customDetails.purchase.acceptedAt &&
          customDetails.purchase.acceptedAt.toLocaleString('de-DE', {
            month: '2-digit',
            year: 'numeric',
            day: '2-digit',
          }) +
            ', ' +
            customDetails.purchase.acceptedAt.toLocaleString('de-DE', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })}
      </span>
    );
    return customContentDate;
  };

  const renderTypeOfVehicle = () => {
    const customDetails = getCustomDetails<IVehiclePurchaseDetails>();
    return customDetails.purchase?.transportType == 1 ? t('service_transport') : t('on_site_handover');
  };
  const renderCustomtype = () => {
    if (page !== 'HistoryPage') {
      const followUpContract = props.item.details.purchase.isFollowUpContract;
      return (
        <div className="list-identification">
          <div className="fp--helper-text-01">{t('customer_type')}</div>
          <div className="fp--body-short-01">
            {followUpContract === true ? t('followup') : t('new_contract')}
            <br />
          </div>
        </div>
      );
    }
  };
  const renderTransfertype = () => {
    if (page !== 'HistoryPage') {
      const checksuperregional = props.item.details.purchase.superregional;
      return (
        <div className="list-identification">
          <div className="fp--helper-text-01">{t('customer_type')}</div>
          <div className="fp--body-short-01">
            {checksuperregional === true ? t('supra_regional') : t('regional')}
            <br />
          </div>
        </div>
      );
    }
  };
  return (
    <div>
      <div className={`list-item list-item--${animation}`}>
        {page !== 'HistoryPage' ? (
          <div className="list-item__image smallscreenDisplay">
            <div className="fp--helper-text-01 text-size">{t('aal_number')}</div>
            <div className="fp--body-short-01 tabTopspace">
              <div className="list-item__status--content">{props.item.details.purchase.number}</div>
            </div>
          </div>
        ) : null}

        <div className="list-item__image smallscreenDisplay">
          <Image link={image} alt="Modelpicture" size="small" />
        </div>

        <div className="list-item__model fp--body-short-01">
          <AppTooltip tooltipText={completeModelName} className="fp--productive-heading-01" maxNROfCharacters={28} align="start">
            <div className="list-item__model--headline fp--productive-heading-01">{completeModelName}</div>
          </AppTooltip>
          <div className="list-item__model--description">
            {t(fuel)}, {t(transmission)}
          </div>

          <div className="list-item__model--details fp--body-short-01" onClick={onShowDetails}>
            {t('details')}
          </div>
        </div>
        <div className="list-item__status">
          <div className="fp--helper-text-01">{t('Status')}</div>
          <div className="fp--body-short-01">
            <div className="list-item__status--content">
              {page !== 'HistoryPage' ? getStatusTranslation(status) : getStatusTranslationByPurchaseStatus((props.item as IHistoryVehicle).historyStatus)}
              {page !== 'HistoryPage' ? renderStatusIcon() : renderStatusIconByHistoryStatus((props.item as IHistoryVehicle).historyStatus)}
            </div>
          </div>
        </div>
        <div className="list-identification">
          <div className="fp--helper-text-01">{t('VIN')}</div>
          <div className="fp--body-short-01">
            {renderIdentification()}
            <br />
          </div>
        </div>
        {renderCustomtype()}
        {renderTransfertype()}
        {renderCustomInfo()}
        <div className="list-item__button btn-size">{button}</div>
      </div>
    </div>
  );
};

export default ListItem;
