import { InlineNotification, Loading } from 'carbon-components-react';

import { useAppDispatch } from '../../../redux/hooks';
import { filterVehiclesData, IVehicleFilterParams, getVehiclesData } from '../../../redux/reducers/VehiclesSlice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataStatus } from '../../../common/models/dataStatus';
import FilterByStatus from '../../../common/components/FilterByStatus/FilterByStatus';
import { VehiclesStatus } from '../../../common/models/vehicles';

interface ICalendarVehiclesFilter {
  filterStatusItems: string[];
  status?: DataStatus;
}

const CalendarVehiclesFilter: React.FC<ICalendarVehiclesFilter> = (props: ICalendarVehiclesFilter) => {
  const { filterStatusItems, status } = props;
  const { t } = useTranslation('vehicles');
  const translatedStatus: { key: VehiclesStatus; translation: string }[] = [
    {
      key: VehiclesStatus.OFFER,
      translation: t('inquiry'),
    },
    {
      key: VehiclesStatus.HANDOVER,
      translation: t('pickup'),
    },
    {
      key: VehiclesStatus.RETURN,
      translation: t('return'),
    },
  ];
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterItems = (statusText: any) => {
    const status = translatedStatus.find(val => val.translation.toLowerCase() === statusText.toLowerCase());
    const statusList = [VehiclesStatus.OFFER, VehiclesStatus.HANDOVER, VehiclesStatus.RETURN];

    const search: IVehicleFilterParams = {
      filter: {
        searchByText: !status ? statusText : '',
      },
      statusList: status ? [status.key] : statusList,
    };
    if(statusText != t('show_all')) {
      (async function () {
        await dispatch(filterVehiclesData(search))
      })();
    } else {
      (async function () {
        await dispatch(getVehiclesData())
      })();
    }
  };

  const filterVehiclesModels = filterStatusItems;

  const renderVehiclesFilter = () => {
    return <FilterByStatus filterItems={filterVehiclesModels} onFilterChange={filterItems} label={t('filter_status')} />;
  };

  const renderError = () => {
    return (
      <InlineNotification iconDescription="describes the close button" title={t('error_content_loaded') as string} notificationType="inline" kind="error" />
    );
  };

  const renderLoading = () => {
    return (
      <div className="home-list__loading">
        <Loading description="Loading list items" withOverlay={false} />
      </div>
    );
  };

  const render = () => {
    if (status === DataStatus.LOADING) {
      return renderLoading();
    }
    if (status === DataStatus.FAILED) {
      return renderError();
    }

    return renderVehiclesFilter();
  };

  return <div>{render()}</div>;
};

export default CalendarVehiclesFilter;
