import { Button, InlineNotification, Loading } from 'carbon-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ListItems from '../../../common/components/ListItems/ListItems';
import { OverlayPages } from '../../../common/components/Overlay/overlayPages';
import { DataStatus } from '../../../common/models/dataStatus';
import { PurchaseStatus } from '../../../common/models/purchase';
import { IVehicleListItem, IMarketplaceVehicle, IOfferVehicle, IHandOverVehicle, VehiclesStatus, IReturnVehicle } from '../../../common/models/vehicles';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { show } from '../../../redux/reducers/OverlaySlice';
import { getVehiclesState } from '../../../redux/reducers/VehiclesSlice';
import { DetailsTabKey, IVehiclesDetailsProps } from '../../vehicleDetails/VehicleDetails';
import { ITranslatedHeadlines } from '../../vehicles/Vehicles';
import './CalendarVehiclesBody.scss';

interface CalendarVehiclesBodyProps {
  translatedHeadlines: ITranslatedHeadlines;
}

const CalendarVehiclesBody: React.FC<CalendarVehiclesBodyProps> = () => {
  const { t } = useTranslation('vehicles');
  const state = useAppSelector(getVehiclesState);
  const dispatch = useAppDispatch();
  const { status, vehicles } = state;
  const handOverVehicles = vehicles.handOver;
  const offerVehicles = vehicles.offer;
  const returnVehicles = vehicles.return;
  const onShowDetails = (status: string, item: IVehicleListItem, idVehicle: number, selectedTab: DetailsTabKey) => {
    const contentProps: IVehiclesDetailsProps = { idVehicleTemplate: item.idTemplate, selectedTab };

    if (status !== 'release') {
      contentProps.idSubscription = (item as IMarketplaceVehicle).idSubscription;
    }

    if (status !== 'release' && status !== 'marketplace') {
      contentProps.idPurchase = (item as IOfferVehicle).idPurchase;
    }
    if (idVehicle !== 0) {
      contentProps.idVehicle = idVehicle;
    }

    const headline = `${item.manufacturer} ${item.model} ${item.variant}`;

    dispatch(show({ headline, content: OverlayPages.VEHICLE_DETAILS, contentProps }));
  };

  const datePattern = /(\d{2})\.(\d{2})\.(\d{4})/;

  const vehiclesHandoverDates = handOverVehicles.map(item => item.details.handOverDateTime);
  const vehiclesOfferDates = offerVehicles.map(item => item.details.createdDateTime);
  const vehiclesReturnDates = returnVehicles.map(item => item.details.returnDateTime);

  const allVehiclesDates = [...vehiclesOfferDates, ...vehiclesHandoverDates, ...vehiclesReturnDates].sort((a: string, b: string) => {
    const c = new Date(a.substring(0, a.lastIndexOf(' ')).replace(datePattern, '$3/$2/$1'));
    const d = new Date(b.substring(0, b.lastIndexOf(' ')).replace(datePattern, '$3/$2/$1'));
    return c.getTime() - d.getTime();
  });

  const vehiclesDatesMonthYear = allVehiclesDates.map(item =>
    new Date(item.substring(0, item.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
      .toLocaleString('de-DE', { month: 'long', year: 'numeric' })
      .toLowerCase(),
  );

  const vehiclesDatesDayMonthYear = allVehiclesDates.map(item =>
    new Date(item.substring(0, item.lastIndexOf(' ')).replace(datePattern, '$3/$2/$1')).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }),
  );

  const uniqVehiclesDatesMonthYear = vehiclesDatesMonthYear.reduce(function (a: string[], b: string) {
    if (a.indexOf(b) < 0) a.push(b);
    return a;
  }, []);
  const uniqVehiclesDatesDayMonthYear = vehiclesDatesDayMonthYear.reduce(function (a: string[], b: string) {
    if (a.indexOf(b) < 0) a.push(b);
    return a;
  }, []);

  type IVehiclesHandOverGroupedByDayMonthYear = {
    [key: string]: IHandOverVehicle[];
  };

  const vehiclesHandOverGroupedByDayMonthYear = handOverVehicles.reduce((acc: IVehiclesHandOverGroupedByDayMonthYear, item) => {
    acc[
      new Date(item.details.handOverDateTime.substring(0, item.details.handOverDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
        .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .toLowerCase()
    ] =
      acc[
        new Date(item.details.handOverDateTime.substring(0, item.details.handOverDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
          .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
          .toLowerCase()
      ] || [];
    acc[
      new Date(item.details.handOverDateTime.substring(0, item.details.handOverDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
        .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .toLowerCase()
    ].push(item);
    return acc;
  }, {});

  type IVehiclesOfferGroupedByDayMonthYear = {
    [key: string]: IOfferVehicle[];
  };

  const vehiclesOfferGroupedByDayMonthYear = offerVehicles.reduce((acc: IVehiclesOfferGroupedByDayMonthYear, item) => {
    acc[
      new Date(item.details.createdDateTime.substring(0, item.details.createdDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
        .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .toLowerCase()
    ] =
      acc[
        new Date(item.details.createdDateTime.substring(0, item.details.createdDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
          .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
          .toLowerCase()
      ] || [];
    acc[
      new Date(item.details.createdDateTime.substring(0, item.details.createdDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
        .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .toLowerCase()
    ].push(item);
    return acc;
  }, {});

  type IVehiclesReturnGroupedByDayMonthYear = {
    [key: string]: IReturnVehicle[];
  };

  const vehiclesReturnGroupedByDayMonthYear = returnVehicles.reduce((acc: IVehiclesReturnGroupedByDayMonthYear, item) => {
    acc[
      new Date(item.details.returnDateTime.substring(0, item.details.returnDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
        .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .toLowerCase()
    ] =
      acc[
        new Date(item.details.returnDateTime.substring(0, item.details.returnDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
          .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
          .toLowerCase()
      ] || [];
    acc[
      new Date(item.details.returnDateTime.substring(0, item.details.returnDateTime.lastIndexOf(' ')).replace(datePattern, '$3-$2-$1'))
        .toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .toLowerCase()
    ].push(item);
    return acc;
  }, {});

  const allVehiclesGrouped = Object.assign(vehiclesOfferGroupedByDayMonthYear, vehiclesHandOverGroupedByDayMonthYear, vehiclesReturnGroupedByDayMonthYear);

  const renderCustomButton = (status: VehiclesStatus, detailsStatus: PurchaseStatus, item: IVehicleListItem, idVehicle: number) => {
    let kind = '';
    let text = '';

    let detailTab = 'appointment';
    if (status == VehiclesStatus.OFFER) {
      kind = 'secondary';
      text = t('details'); // 'check_offer'
      detailTab = 'details';
    }
    if (status == VehiclesStatus.HANDOVER && detailsStatus == PurchaseStatus.READY_FOR_PICK_UP) {
      kind = 'primary';
      text = t('make_an_appointment');
    }
    if (status == VehiclesStatus.HANDOVER && detailsStatus == PurchaseStatus.SCHEDULED_FOR_PICK_UP) {
      kind = 'secondary';
      text = t('handover_details');
    }
    if (status == VehiclesStatus.RETURN && detailsStatus == PurchaseStatus.RETURNED) {
      kind = 'primary';
      text = t('make_an_appointment');
    }
    if (status == VehiclesStatus.RETURN && detailsStatus == PurchaseStatus.SCHEDULED_FOR_RETURN) {
      kind = 'secondary';
      text = t('handover_details');
    }

    return (
      <Button onClick={() => onShowDetails('hand-over', item, idVehicle, detailTab as DetailsTabKey)} kind={kind}>
        {text}
      </Button>
    );
  };

  const renderCalendarVehicles = () => {
    return (
      <div className="calendar-body">
        {uniqVehiclesDatesMonthYear.map((monthYear: string, key: number) => (
          <div key={`handover-headline-${key}`}>
            {monthYear != 'invalid date' && <h3 className="month-year-headline">{monthYear}</h3>}
            {uniqVehiclesDatesDayMonthYear.map((date: string, key: number) => {
              if (
                monthYear == new Date(date.replace(datePattern, '$3-$2-$1')).toLocaleString('de-DE', { month: 'long', year: 'numeric' }).toLowerCase() &&
                allVehiclesGrouped[date]
              ) {
                return (
                  <ListItems
                    key={`handover2-${key}`}
                    headline={date}
                    items={allVehiclesGrouped[date].map((item: IHandOverVehicle | IReturnVehicle) => ({
                      page: 'CalendarPage',
                      key: item.idPurchase,
                      button: renderCustomButton(item.status, item.details.status, item, item.details.idVehicle),
                      item: item,
                      onShowDetails: () => onShowDetails('hand-over', item, item.details.idVehicle, 'details'),
                    }))}
                  />
                );
              }
            })}
          </div>
        ))}
      </div>
    );
  };

  const renderError = () => {
    return (
      <InlineNotification iconDescription="describes the close button" title={t('error_content_loaded') as string} notificationType="inline" kind="error" />
    );
  };

  const renderLoading = () => {
    return (
      <div className="home-list__loading">
        <Loading description="Loading list items" withOverlay={false} />
      </div>
    );
  };
  const render = () => {
    if (status === DataStatus.LOADING) {
      return renderLoading();
    }
    if (status === DataStatus.FAILED) {
      return renderError();
    }

    return renderCalendarVehicles();
  };
  return render();
};

export default CalendarVehiclesBody;
