import TimelineItem from '../TimelineItem/TimelineItem';
import { IVehicleFileEventList } from '../../models/vehicleFile';
import './TimelineList.scss';

const TimelineList: React.FC<IVehicleFileEventList> = (props: IVehicleFileEventList) => {
  const { events, year } = props;
  return (
    <div className="timeline-list">
      <h6>{year}</h6>
      <ul className="timeline-list-items">
        {events.length
          ? events.map((item, index) => (
              <TimelineItem
                key={item.idPurchase}
                eventIndex={`list-item-${index}`}
                idPurchase={item.idPurchase}
                idVehicle={item.idVehicle}
                eventDate={item.eventDate}
                event={item.event}
                type={item.type}
                resolutionDate={item.resolutionDate}
                details={item.details}
              />
            ))
          : ''}
      </ul>
    </div>
  );
};

export default TimelineList;
