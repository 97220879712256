/* eslint-disable @typescript-eslint/no-explicit-any */
import DashboardItemList from './Dashboard/DashboardItemList';

const dashboarditemsTestNone: any = [];
const dashboarditemsTestOne: any = [
  {
    idPurchase: 5,
    status: 'pending',
    medias: [
      {
        name: 'FiestaPic',
        path: '/img/mocks/Fiesta_ST-Line.png',
        type: 'photo',
        id: 2,
        createdAt: '2021-10-19T11:19:56.000Z',
        updatedAt: '2021-10-19T11:19:56.000Z',
      },
    ],
    manufacturer: 'Ford',
    model: 'Kuga',
    variant: 'ST-Line X',
    dueDate: '7.09.2022',
    dueTime: '11:19:56',
    appointment: {
      erpid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      appointmentAt: '19:56',
      action: 'hand-over',
      acceptedAt: '2021-10-19T11:19:56.000Z',
      declinedAt: '2021-10-19T11:19:56.000Z',
      status: 'string',
      purchaseId: '5',
    },
  },
];
const dashboarditemsTestTwo: any = [
  {
    idPurchase: 5,
    status: 'ready-for-pick-up',
    medias: [
      {
        name: 'FiestaPic',
        path: '/img/mocks/Kuga_Plug_In_Hybrid.png',
        type: 'photo',
        id: 2,
        createdAt: '2021-10-19T11:19:56.000Z',
        updatedAt: '2021-10-19T11:19:56.000Z',
      },
    ],
    manufacturer: 'Ford',
    model: 'Kuga',
    variant: 'ST-Line X',
    dueDate: '2021-10-19',
    dueTime: '11:19:56',
    appointment: {
      erpid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      appointmentAt: '19:56',
      action: 'hand-over',
      acceptedAt: '2021-10-19T11:19:56.000Z',
      declinedAt: '2021-10-19T11:19:56.000Z',
      status: 'string',
      purchaseId: '5',
    },
  },
  {
    idPurchase: 8,
    status: 'scheduled-for-pick-up',
    medias: [
      {
        name: 'FiestaPic',
        path: '/img/mocks/Fiesta_ST-Line.png',
        type: 'photo',
        id: 2,
        createdAt: '2021-10-19T11:19:56.000Z',
        updatedAt: '2021-10-19T11:19:56.000Z',
      },
    ],
    manufacturer: 'Ford',
    model: 'Kugas',
    variant: 'ST-Line X',
    dueDate: '2021-10-19',
    dueTime: '11:19:56',
    appointment: {
      erpid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      appointmentAt: '19:56',
      action: 'hand-over',
      acceptedAt: '2021-10-19T11:19:56.000Z',
      declinedAt: '2021-10-19T11:19:56.000Z',
      status: 'string',
      purchaseId: '5',
    },
  },
];
const DashboardTest: React.FC = () => (
  <div>
    <h3>Dashboard example with no task</h3>
    <div className="dashboard">
      <div className="container">
        <h3>Hallo Max Müller, willkommen bei Fleetpool!</h3>
        <DashboardItemList dashboardItems={dashboarditemsTestNone} />
      </div>
    </div>
    <h3>Dashboard example with one task</h3>
    <div className="dashboard one-item">
      <div className="container">
        <h3>Hallo Max Müller, willkommen bei Fleetpool!</h3>
        <DashboardItemList dashboardItems={dashboarditemsTestOne} />
      </div>
    </div>
    <h3>Dashboard example with two tasks</h3>
    <div className="dashboard two-items">
      <div className="container">
        <h3>Hallo Max Müller, willkommen bei Fleetpool!</h3>
        <DashboardItemList dashboardItems={dashboarditemsTestTwo} />
      </div>
    </div>
  </div>
);

export default DashboardTest;
