import React, { useEffect } from 'react';
import './ImprintPage.scss';
import { ThemeUtil } from '../utils/ThemeUtil';

const ImprintPage: React.FC = () => {
  const loadImprint = () => {
    (async function () {
      const response = await fetch('/docs/imprint-' + ThemeUtil.getThemeName() + '.html');
      const imprint = document.getElementById('imprint-container');
      if (imprint && response) {
        imprint.innerHTML = await response.text();
      }
    })().catch(console.error);
  }

  useEffect(() => {
    loadImprint();
  }, []);

  return (
    <div>
      <div id="imprint-container" className="imprint-container">
      </div>
    </div>
  );
};

export default ImprintPage;
