import { IAnimation } from '../../redux/reducers/VehiclesSlice';
import { IAppointment } from './appointment';
import { IPurchase, PurchaseStatus } from './purchase';

export enum VehiclesStatus {
  ACCEPTED = 'accepted',
  RELEASE = 'release',
  MARKETPLACE = 'marketplace',
  OFFER = 'offer',
  HANDOVER = 'hand-over',
  RETURN = 'return',
  AT_CUSTOMER = 'at-customer',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
  OTHER = 'other',
  REGISTERED = 'registered',
  UNKNOWN = '',
}

export interface IVehicleListItem {
  idTemplate: number;
  image: string;
  manufacturer: string;
  model: string;
  variant: string;
  fuel: string;
  transmission: string;
}

export interface IVehicleReleaseDetails {
  price: string;
  currency: string;
}

export interface IReleaseVehicle extends IVehicleListItem {
  status: VehiclesStatus.RELEASE;
  details: IVehicleReleaseDetails;
  animation: IAnimation;
}

export interface IVehicleMarketplaceDetails {
  marketplace: string;
  isSdmDealerSubscriptionAllowed: boolean;
  url: string;
  createdAt: Date;
  unsubscribedAt: Date | null;
  releaseDate: string;
  active: boolean;
}

export interface IMarketplaceVehicle extends IVehicleListItem {
  idSubscription: number;
  status: VehiclesStatus.MARKETPLACE;
  animation: IAnimation;
  details: IVehicleMarketplaceDetails;
}

export interface IVehiclePurchaseDetails {
  purchase: IPurchase;
}

export interface IVehicleOfferDetails extends IVehiclePurchaseDetails {
  vin: string;
  orbit: string;
  plateNumber: string;
  offerNumber: string;
  status: PurchaseStatus.PENDING | PurchaseStatus.ACCEPTED | PurchaseStatus.REJECTED;
  unparsedDate: string;
  createdDateTime: string;
}

export interface IOfferVehicle extends IVehicleListItem {
  idSubscription: number;
  idPurchase: number;
  status: VehiclesStatus.OFFER;
  details: IVehicleOfferDetails;
  animation: IAnimation;
}

export interface IVehicleHandOverDetails extends IVehiclePurchaseDetails {
  idVehicle: number;
  vin: string;
  orbit: string;
  plateNumber: string;
  tasks: number;
  handOverDateTime: string;
  status: PurchaseStatus;
  unparsedDate: string;
  appointments: IAppointment[];
}

export interface IHandOverVehicle extends IVehicleListItem {
  idSubscription: number;
  idPurchase: number;
  status: VehiclesStatus.HANDOVER;
  details: IVehicleHandOverDetails;
  animation: IAnimation;
}

export interface IVehicleReturnDetails extends IVehiclePurchaseDetails {
  idVehicle: number;
  vin: string;
  orbit: string;
  plateNumber: string;
  tasks: number;
  returnDateTime: string;
  status: PurchaseStatus;
  unparsedDate: string;
  appointments: IAppointment[];
}

export interface IReturnVehicle extends IVehicleListItem {
  idSubscription: number;
  idPurchase: number;
  status: VehiclesStatus.RETURN;
  details: IVehicleReturnDetails;
  animation: IAnimation;
}

export interface IVehicleAtCustomerDetails extends IVehiclePurchaseDetails {
  idVehicle: number;
  vin: string;
  plateNumber: string;
  returnDate: string;
  status: PurchaseStatus.PICKED_UP;
  unparsedDate: string;
}

export interface IAtCustomerVehicle extends IVehicleListItem {
  idSubscription: number;
  idPurchase: number;
  status: VehiclesStatus.AT_CUSTOMER;
  details: IVehicleAtCustomerDetails;
  animation: IAnimation;
}

export interface IVehicleCompletedDetails extends IVehiclePurchaseDetails {
  idVehicle: number;
  vin: string;
  plateNumber: string;
  updateDate: string;
  status: PurchaseStatus;
  unparsedDate: string;
}

export interface ICompletedVehicle extends IVehicleListItem {
  idSubscription: number;
  idPurchase: number;
  status: VehiclesStatus.COMPLETED;
  details: IVehicleCompletedDetails;
  animation: IAnimation;
}

export interface IOtherVehicle extends IVehicleListItem {
  idSubscription: number;
  idPurchase: number;
  status: VehiclesStatus.OTHER;
  details: IVehiclePurchaseDetails;
  animation: IAnimation;
}
