import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStatus } from '../../common/models/dataStatus';
import { ISubscriptionVehicleDetails } from '../../common/models/vehicleDetails';
import VehiclesDetailsConverter, { IRawSubscriptionVehicleDetails } from '../../utils/converters/VehicleDetailsConverter';
import HttpUtil from '../../utils/HttpUtil';
import { VehicleDetails } from '../reducerNames';
import { RootState } from '../store';

export interface VehicleDetailsState {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
  details: ISubscriptionVehicleDetails | null;
}

const initialState: VehicleDetailsState = {
  status: DataStatus.IDLE,
  details: null,
};

export interface IGetVehicleDetailsParams {
  idVehicleTemplate: number;
  idSubscription?: number;
  idPurchase?: number;
}

export const getVehiclesDetails = createAsyncThunk<VehicleDetailsState, IGetVehicleDetailsParams>(
  `${VehicleDetails}/fetchVehicleDetails`,
  async (params: IGetVehicleDetailsParams): Promise<VehicleDetailsState> => {
    try {
      const converter = new VehiclesDetailsConverter();
      const { idVehicleTemplate, idSubscription, idPurchase } = params;
      let url = `api/v1/vehicle-details/${idVehicleTemplate}`;
      if (idSubscription) {
        url += `/${idSubscription}`;
      }

      if (idPurchase) {
        url += `/${idPurchase}`;
      }
      const response = await HttpUtil.get<IRawSubscriptionVehicleDetails>(url);
      
      return { details: converter.convert(response.data), status: DataStatus.IDLE };
    } catch (err) {
      console.error('Backend not found', err);
      return { details: initialState.details, status: DataStatus.FAILED };
    }
  },
);

export const VehicleDetailsSlice = createSlice({
  name: VehicleDetails,
  initialState,
  reducers: {
    clearVehicleDetails: state => {
      state.details = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getVehiclesDetails.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(getVehiclesDetails.fulfilled, (state, action: PayloadAction<VehicleDetailsState>) => {
        state.status = action.payload.status;
        state.details = action.payload.details;
      });
  },
});

export const getVehicleDetailsState = (state: RootState): VehicleDetailsState => state.detailsReducers;

export const { clearVehicleDetails } = VehicleDetailsSlice.actions;

export default VehicleDetailsSlice.reducer;
