import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { filterVehiclesData, getVehiclesFilterState } from '../../../redux/reducers/VehiclesSlice';
import React from 'react';
import FilterByDate from '../../../common/components/FilterByDate/FilterByDate';

const VehiclesFilterbyDate: React.FC = () => {
  const filterState = useAppSelector(getVehiclesFilterState);
  const dispatch = useAppDispatch();

  const formatDate = (unformattedDate: Date) => {
    const year = unformattedDate.getFullYear();

    const month = `${unformattedDate.getMonth() + 1}`.padStart(2, '0');

    const day = `${unformattedDate.getDate()}`.padStart(2, '0');

    const formattedDate = [year, month, day].join('-');

    return formattedDate;
  };

  const filterItems = (selectedRangeDate: Date[]) => {
    if (selectedRangeDate.length == 2) {
      const params = {
        filter: {
          searchByDateRange: {
            from: formatDate(selectedRangeDate[0]),
            to: formatDate(selectedRangeDate[1]),
          },
        },
        statusList: filterState ? filterState.statusList : [],
      };
      dispatch(filterVehiclesData(params)).catch(console.error);
    }
  };

  const renderVehiclesFilter = () => {
    return <FilterByDate onFilterChange={filterItems} />;
  };

  const render = () => {
    return renderVehiclesFilter();
  };

  return <div>{render()}</div>;
};

export default VehiclesFilterbyDate;
