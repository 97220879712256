import React from 'react';
import ComponentsTestPage from '../pages/ComponentsTestPage';
import ImprintPage from '../pages/ImprintPage';
import HistoryPage from '../pages/HistoryPage';
import VehiclesPage from '../pages/VehiclesPage';
import ReleasePage from '../pages/ReleasePage';
import CalendarPage from '../pages/CalendarPage';
import CalendarTestPage from '../pages/CalendarTestPage';

export interface RouterPage {
  url: string;
  name: string;
  component: React.FC;
  params: Record<string, unknown>;
  type: 'none' | 'header' | 'footer';
}

export const routerPages: RouterPage[] = [
  {
    url: '/',
    name: 'release',
    component: ReleasePage,
    params: {},
    type: 'header',
  },
  {
    url: '/status',
    name: 'status',
    component: VehiclesPage,
    params: {},
    type: 'header',
  },
  {
    url: '/calendar',
    name: 'calendar',
    component: CalendarPage,
    params: {},
    type: 'header',
  },
  {
    url: '/history',
    name: 'history',
    component: HistoryPage,
    params: {},
    type: 'header',
  },
  {
    url: '/imprint',
    name: 'imprint',
    component: ImprintPage,
    params: {},
    type: 'none',
  },
  {
    url: '/test-components',
    name: '',
    component: ComponentsTestPage,
    params: {},
    type: 'none',
  },
  {
    url: '/calendar-test',
    name: '',
    component: CalendarTestPage,
    params: {},
    type: 'none',
  },
];
