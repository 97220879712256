import { AppointmentAction, AppointmentStatus, IAppointment } from '../../common/models/appointment';
import IDataConverter from '../../common/models/IDataConverter';

export interface IRawAppointment {
  id: number;
  erpId: string;
  appointmentAt: string | null;
  action: string;
  acceptedAt: string | null;
  declinedAt: string | null;
  status: string;
}

class AppointmentConverter implements IDataConverter<IRawAppointment, IAppointment> {
  convert(from: IRawAppointment): IAppointment {
    const toAction = (value: string): AppointmentAction => {
      switch (value) {
        case AppointmentAction.HAND_OVER:
          return AppointmentAction.HAND_OVER;
        case AppointmentAction.RETURN:
          return AppointmentAction.RETURN;
        default:
          return AppointmentAction.UNKNOWN;
      }
    };
    const toStatus = (value: string): AppointmentStatus => {
      switch (value) {
        case AppointmentStatus.REQUESTED:
          return AppointmentStatus.REQUESTED;
        case AppointmentStatus.ACCEPTED:
          return AppointmentStatus.ACCEPTED;
        case AppointmentStatus.DECLINED:
          return AppointmentStatus.DECLINED;
        default:
          return AppointmentStatus.UNKNOWN;
      }
    };

    return {
      id: from.id || 0,
      erpId: from.erpId || '',
      appointmentAt: from.appointmentAt ? new Date(from.appointmentAt) : null,
      action: toAction(from.action),
      acceptedAt: from.acceptedAt ? new Date(from.acceptedAt) : null,
      declinedAt: from.declinedAt ? new Date(from.declinedAt) : null,
      status: toStatus(from.status),
    };
  }
}

export default AppointmentConverter;
