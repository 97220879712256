import { InlineNotification, Loading } from 'carbon-components-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataStatus } from '../../../../common/models/dataStatus';
import { IVehicle } from '../../../../common/models/purchase';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { getPurchase, getPurchaseState } from '../../../../redux/reducers/PurchaseSlice';
import DateTimeUtil from '../../../../utils/DateTimeUtil';
import PriceUtil from '../../../../utils/PriceUtil';
import './AcceptOfferTemplate.scss';

const AcceptOfferTemplate: React.FC<{ idPurchase: number }> = props => {
  const { t } = useTranslation('vehicles');
  const dispatch = useAppDispatch();
  const state = useAppSelector(getPurchaseState);
  const { status, selectedPurchase } = state;
  const { idPurchase } = props;

  useEffect(() => {
    (async function () {
      await dispatch(getPurchase(idPurchase));
    })();
  }, []);

  const getNumber = (vehicle: IVehicle | null) => {
    if (!vehicle) return '-';

    if (vehicle.vin) return vehicle.vin;

    if (vehicle.orbit) return vehicle.orbit;

    return '-';
  };

  const render = () => {
    const superRegionalflagset = selectedPurchase?.superregional.toString();
    if (superRegionalflagset == 'true') {
      document.getElementsByClassName('isSuperregional');
      document.body.classList.add('isSuperregionalParent');
    }

    if (status === DataStatus.LOADING) {
      return (
        <div className="accept-offer__loading">
          <Loading description="Loading items" withOverlay={false} />
        </div>
      );
    }
    if (status === DataStatus.FAILED) {
      return (
        <InlineNotification
          iconDescription="describes the close button"
          title={t('error_subscription_information') as string}
          notificationType="inline"
          kind="error"
        />
      );
    }

    if (!selectedPurchase) {
      return (
        <InlineNotification
          iconDescription="describes the close button"
          title={t('error_subscription_information') as string}
          notificationType="inline"
          kind="error"
        />
      );
    }

    const { vehicle, number, offerExpiresAt, condition, offeredAt } = selectedPurchase;
    const dateFormatter = DateTimeUtil.toStringFormat;

    return (
      <div className="accept-offer__details accept-offer__heading">
        <div className="fp--heading-02">{t('contract_details')}</div>
        <div></div>

        <div className="fp--body-long-01 accept-offer__label">{t('order_number')}</div>
        <div className="fp--body-long-01 accept-offer__label">{number}</div>

        <div className="fp--body-long-01 accept-offer__label">{t('offer_received')}</div>
        <div className="fp--body-long-01 accept-offer__label">
          {offeredAt ? dateFormatter(offeredAt) : ''} {t('Uhr')}
        </div>

        <div className="fp--body-long-01 accept-offer__label">{t('offer_expired')}</div>
        <div className="fp--body-long-01 accept-offer__label">
          {offerExpiresAt ? dateFormatter(offerExpiresAt) : ''} {t('clock')}
        </div>
        <div className="fp--body-long-01 accept-offer__label">{t('milage/duration')}</div>
        <div className="fp--body-long-01 accept-offer__label">
          {condition && Math.round(condition.milage) + ' km/' + condition.duration + ' ' + t('months')}
        </div>
        <div className="fp--body-long-01 accept-offer__label">{t('km_per_year')}</div>
        <div className="fp--body-long-01 accept-offer__label">{condition && Math.round((condition.milage * 12) / condition.duration) + ' ' + t('year')}</div>

        <div className="fp--body-long-01 accept-offer__label">{t('vehicle_number')}</div>
        <div className="fp--body-long-01 accept-offer__label">{getNumber(vehicle)}</div>

        <div className="fp--body-long-01 accept-offer__label">{t('upe')}</div>
        <div className="fp--body-long-01 accept-offer__label">{condition && PriceUtil.format(condition.upe)}</div>

        <div className="fp--body-long-01 accept-offer__label">{t('selling_price_dealer')}</div>
        <div className="fp--body-long-01 accept-offer__label">{condition && PriceUtil.format(condition.ekp)}</div>

        <div className="fp--body-long-01 accept-offer__label accept-offer__label--last">{t('agreed_residual_value')}</div>
        <div className="fp--body-long-01 accept-offer__label accept-offer__label--last">{condition && PriceUtil.format(condition.rest)}</div>
      </div>
    );
  };

  return (
    <div className="accept-offer-template">
      <div className="accept-offer__subtitle">{t('accept_offer_subtitle_msg')}</div>
      {render()}
    </div>
  );
};

export default AcceptOfferTemplate;
