import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { DataStatus } from '../../common/models/dataStatus';
import { ICodeResponse } from '../../common/models/ICodeResponse';
import HttpUtil from '../../utils/HttpUtil';
import { SendPurchaseFinalInvoiced, SendvehicleDeliveryStatus, AcceptOffer, SendPurchaseDocumentRequest } from '../reducerNames';
import { RootState } from '../store';
import { getDashboardData } from './DashboardSlice';
import { INotificationState, showNotification } from './NotificationsSlice';
import { filterVehiclesData, getVehiclesData } from './VehiclesSlice';

export interface AcceptOfferState extends ICodeResponse {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
}

const initialState: AcceptOfferState = {
  status: DataStatus.IDLE,
  code: 0,
};

export interface IAcceptOfferParams {
  idPurchase: number;
  status: 'accepted' | 'declined';
  reason: string | null;
}
export interface ISendFinalInvoiceParams {
  idPurchase: number;
}

export interface ISendDocumentParams {
  idPurchase: number;
  type: string;
}

export interface ISendvehicleDeliveryParams {
  idPurchase: number;
  status: 'scheduled-for-pick-up' | 'ready-for-pick-up';
}

interface ISendFinalInvoicedPayload {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
}

interface ISendPurchaseDocumentPayload {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
}

interface IsendvehicleDeliveryPayload {
  status: DataStatus.IDLE | DataStatus.LOADING | DataStatus.FAILED;
}

interface ISendDocumentResponse {
  data: {
    contentType: string;
    image: string | null;
  };
  message: string;
}

export interface IPurchaseDocumentRequestResponse {
  code: 200 | 201 | 0;
  response: ISendDocumentResponse;
}

const getNotificationInvoice = (code: number): INotificationState => {
  if (code === 200) {
    return {
      type: 'success',
      title: i18next.t('success'),
      subtitle: i18next.t('Final_invoice_sent_successfully'),
      caption: '',
    };
  } else if (code === 201) {
    return {
      type: 'warning',
      title: i18next.t('warning'),
      subtitle: i18next.t('Final_invoice_sent_successfully_warning'),
      caption: '',
    };
  }

  return {
    type: 'error',
    title: i18next.t('error'),
    subtitle: i18next.t('Final_invoice_sent_successfully_error'),
    caption: '',
  };
};

const getNotificationDelivery = (code: number): INotificationState => {
  if (code === 200) {
    return {
      type: 'success',
      title: i18next.t('success'),
      subtitle: i18next.t('Final_delivery_sent_successfully'),
      caption: '',
    };
  } else if (code === 201) {
    return {
      type: 'warning',
      title: i18next.t('warning'),
      subtitle: i18next.t('Final_delivery_sent_successfully_warning'),
      caption: '',
    };
  }

  return {
    type: 'error',
    title: i18next.t('error'),
    subtitle: i18next.t('Final_delivery_sent_successfully_error'),
    caption: '',
  };
};

const getNotificationDocument = (code: number): INotificationState => {
  if (code === 200) {
    return {
      type: 'success',
      title: i18next.t('success'),
      subtitle: i18next.t('document_download_successfully'),
      caption: '',
    };
  } else if (code === 201) {
    return {
      type: 'warning',
      title: i18next.t('warning'),
      subtitle: i18next.t('document_download_warning'),
      caption: '',
    };
  }

  return {
    type: 'error',
    title: i18next.t('error'),
    subtitle: i18next.t('Final_delivery_sent_successfully_error'),
    caption: '',
  };
};

const getNotification = (code: number, status: 'accepted' | 'declined'): INotificationState => {
  if (code === 200) {
    return {
      type: 'success',
      title: i18next.t('done'),
      subtitle: status === 'accepted' ? i18next.t('successfully_accepted_the_offer') : i18next.t('offer_rejected'),
      caption: '',
    };
  } else if (code === 201) {
    return {
      type: 'warning',
      title: i18next.t('warning'),
      subtitle: status === 'accepted' ? i18next.t('accepted_ERP_system_but_not_updated') : i18next.t('rejected_ERP_system_but_not_updated'),
      caption: '',
    };
  } else if (code === 202) {
    return {
      type: 'warning',
      title: i18next.t('warning'),
      subtitle: status === 'accepted' ? i18next.t('accepted_but_system_not_updated') : i18next.t('rejected_but_system_not_updated'),
      caption: '',
    };
  }

  return {
    type: 'error',
    title: i18next.t('error'),
    subtitle: status === 'accepted' ? i18next.t('offer_could_not_accepted') : i18next.t('0ffer_could_not_declined'),
    caption: '',
  };
};

export const sendPurchaseFinalInvoiced = createAsyncThunk<ISendFinalInvoicedPayload, ISendFinalInvoiceParams>(
  `${SendPurchaseFinalInvoiced}/sendPurchaseFinalInvoiced`,

  async (params: ISendFinalInvoiceParams, thunkAPI): Promise<ISendFinalInvoicedPayload> => {
    try {
      const url = `api/v1/purchase/send-final-invoice`;

      const response = await HttpUtil.post<ICodeResponse>(url, params);

      const filter = (thunkAPI.getState() as RootState).vehicleReducer.filter;
      if (filter) {
        thunkAPI.dispatch(filterVehiclesData(filter)).catch(console.error);
      } else {
        thunkAPI.dispatch(getVehiclesData()).catch(console.error);
      }

      thunkAPI.dispatch(getDashboardData()).catch(console.error);
      thunkAPI.dispatch(showNotification(getNotificationInvoice(response.data.code)));
      return { status: DataStatus.IDLE };
    } catch (err) {
      console.error('Backend not found', err);
      thunkAPI.dispatch(showNotification(getNotificationInvoice(0)));
      return { status: DataStatus.IDLE };
    }
  },
);

export const sendPurchaseDocumentRequest = createAsyncThunk<ISendPurchaseDocumentPayload, ISendDocumentParams>(
  `${SendPurchaseDocumentRequest}/sendPurchaseDocumentRequest`,

  async (params: ISendDocumentParams, thunkAPI): Promise<ISendPurchaseDocumentPayload> => {
    try {
      const url = `api/v1/purchase/send-purchase-document-request`;
      const returnResponse = await HttpUtil.post<IPurchaseDocumentRequestResponse>(url, params);
      const dataResponse: ISendDocumentResponse = returnResponse.data.response;
      console.log('dataResponse', dataResponse);
      if (dataResponse.data.image != null) {
        const base64Data = dataResponse.data.image;
        const contentType = dataResponse.data.contentType;
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = params.type;
        downloadLink.click();
      }
      thunkAPI.dispatch(showNotification(getNotificationDocument(returnResponse.data.code)));
      return { status: DataStatus.IDLE };
    } catch (err) {
      console.error('Backend not found', err);
      thunkAPI.dispatch(showNotification(getNotificationDocument(0)));
      return { status: DataStatus.IDLE };
    }
  },
);

export const sendvehicleDeliveryStatus = createAsyncThunk<IsendvehicleDeliveryPayload, ISendvehicleDeliveryParams>(
  `${SendvehicleDeliveryStatus}/sendvehicleDeliveryStatus`,

  async (params: ISendvehicleDeliveryParams, thunkAPI): Promise<IsendvehicleDeliveryPayload> => {
    try {
      const url = `api/v1/purchase/send-vehicle-delivery`;

      const response = await HttpUtil.post<ICodeResponse>(url, params);

      const filter = (thunkAPI.getState() as RootState).vehicleReducer.filter;
      if (filter) {
        thunkAPI.dispatch(filterVehiclesData(filter)).catch(console.error);
      } else {
        thunkAPI.dispatch(getVehiclesData()).catch(console.error);
      }

      thunkAPI.dispatch(getDashboardData()).catch(console.error);
      thunkAPI.dispatch(showNotification(getNotificationDelivery(response.data.code)));
      return { status: DataStatus.IDLE };
    } catch (err) {
      console.error('Backend not found', err);
      thunkAPI.dispatch(showNotification(getNotificationDelivery(0)));
      return { status: DataStatus.IDLE };
    }
  },
);

export const acceptOffer = createAsyncThunk<AcceptOfferState, IAcceptOfferParams>(
  `${AcceptOffer}/acceptOffer`,
  async (params: IAcceptOfferParams, thunkAPI): Promise<AcceptOfferState> => {
    try {
      const url = `api/v1/purchase/accept`;

      const response = await HttpUtil.post<ICodeResponse>(url, params);

      const filter = (thunkAPI.getState() as RootState).vehicleReducer.filter;
      if (filter) {
        thunkAPI.dispatch(filterVehiclesData(filter)).catch(console.error);
      } else {
        thunkAPI.dispatch(getVehiclesData()).catch(console.error);
      }

      thunkAPI.dispatch(getDashboardData()).catch(console.error);
      thunkAPI.dispatch(showNotification(getNotification(response.data.code, params.status)));
      return { code: response.data.code, status: DataStatus.IDLE };
    } catch (err) {
      console.error('Backend not found', err);
      thunkAPI.dispatch(showNotification(getNotification(0, params.status)));
      return { code: 0, status: DataStatus.FAILED };
    }
  },
);

export const AcceptOfferSlice = createSlice({
  name: AcceptOffer,
  initialState,
  reducers: {
    // reducers go here
  },
  extraReducers: builder => {
    builder
      .addCase(acceptOffer.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(acceptOffer.fulfilled, (state, action: PayloadAction<AcceptOfferState>) => {
        state.status = action.payload.status;
        state.code = action.payload.code;
      })
      .addCase(sendPurchaseFinalInvoiced.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(sendPurchaseFinalInvoiced.fulfilled, (state, action: PayloadAction<ISendFinalInvoicedPayload>) => {
        state.status = action.payload.status;
      })
      .addCase(sendPurchaseDocumentRequest.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(sendPurchaseDocumentRequest.fulfilled, (state, action: PayloadAction<ISendPurchaseDocumentPayload>) => {
        state.status = action.payload.status;
      })
      .addCase(sendvehicleDeliveryStatus.pending, state => {
        state.status = DataStatus.LOADING;
      })
      .addCase(sendvehicleDeliveryStatus.fulfilled, (state, action: PayloadAction<IsendvehicleDeliveryPayload>) => {
        state.status = action.payload.status;
      });
  },
});

export default AcceptOfferSlice.reducer;
