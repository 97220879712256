const Authentication = 'authentication';
const Vehicles = 'vehicles';
const VehicleDetails = 'vehicleDetails';
const Overlay = 'overlay';
const Notification = 'notification';
const VehicleFile = 'vehicleFile';
const VehicleTemplate = 'vehicleTemplate';
const AccountSettings = 'accountSettings';
const ReleaseVehicleTemplate = 'releaseVehicleTemplate';
const Subscription = 'subscription';
const Condition = 'condition';
const VehicleAppointment = 'vehicleAppointment';
const Dashboard = 'dashboard';
const Purchase = 'purchase';
const AcceptOffer = 'acceptOffer';
const SendPurchaseFinalInvoiced = 'sendPurchaseFinalInvoiced';
const SendPurchaseDocumentRequest = 'sendPurchaseDocumentRequest';
const SendvehicleDeliveryStatus = 'sendvehicleDeliveryStatus';
const TimeSlots = 'timeSlots';

export {
  Authentication,
  Vehicles,
  VehicleDetails,
  Overlay,
  Notification,
  VehicleFile,
  VehicleTemplate,
  AccountSettings,
  ReleaseVehicleTemplate,
  Subscription,
  Condition,
  VehicleAppointment,
  Dashboard,
  Purchase,
  AcceptOffer,
  TimeSlots,
  SendPurchaseFinalInvoiced,
  SendPurchaseDocumentRequest,
  SendvehicleDeliveryStatus
};
