import Keycloak from 'keycloak-js';

const SECONDS_UNTIL_EXPIRE = 300;
const keycloak = Keycloak('/keycloak.json');

interface IDealerTokenInfo {
  sub: string;
  name: string;
  email: string;
}

class AuthenticationUtil {
  static async authenticate(): Promise<boolean> {
    const authenticated = await keycloak.init({ onLoad: 'login-required', checkLoginIframe: false });
    return authenticated;
  }

  static async getUserInfo(): Promise<IDealerTokenInfo> {
    const info = (await keycloak.loadUserInfo()) as IDealerTokenInfo;
    return { sub: info.sub, name: info.name, email: info.email };
  }

  static getToken(): string {
    return keycloak && keycloak.token ? keycloak.token : '';
  }

  static refreshToken(): Promise<boolean> {
    return keycloak.updateToken(SECONDS_UNTIL_EXPIRE);
  }
  static getKeycloackConfigUrl(): string {
    return keycloak && keycloak.authServerUrl ? keycloak.authServerUrl : '';
  }
  static getKeycloackConfigRealm(): string {
    return keycloak && keycloak.realm ? keycloak.realm : '';
  }

  static logout(): Promise<void> {
    return keycloak.logout();
  }
}

export default AuthenticationUtil;
