import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../utils/ThemeUtil';
import './AppFooter.scss';
import { routerPages } from '../../routerPages';
import { useAppDispatch } from '../../../redux/hooks';
import { OverlayPages } from '../Overlay/overlayPages';
import { show } from '../../../redux/reducers/OverlaySlice';

const AppFooter: React.FC = () => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const getCurrentYear = () => new Date().getFullYear();
  const dispatch = useAppDispatch();
  const translatedButtons: { [key: string]: string } = {
    imprint: t('imprint'),
    data_protection: t('privacy'),
  };

  return (
    <footer aria-label="Footer" className="footer container">
      <div>
        <p>
          {t('copyright') + ' '}
          {getCurrentYear()} {theme.t('brand_name_full')}
        </p>
      </div>
      <div className="footer_links">
        <a onClick={() => dispatch(show({ content: OverlayPages.IMPRINT_PAGE, headline: t('imprint'), contentProps: {} }))} className="bx--link">
          {t('imprint')}
        </a>
        {routerPages
          .filter(page => page.type === 'footer')
          .map((page, index) => (
            <Link key={index} to={page.url} className="bx--link">
              {page.name in translatedButtons ? translatedButtons[page.name] : 'not translated'}
            </Link>
          ))}
      </div>
    </footer>
  );
};

export default AppFooter;
