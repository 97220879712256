import './DealerProfile.scss';
import React from 'react';
import AppIcon from '../AppIcon/AppIcon';
import { useTranslation } from 'react-i18next';
import AuthenticationUtil from '../../../utils/AuthenticationUtil';
import { Checkmark16, Package16, Checkbox16, CheckboxCheckedFilled16 } from '@carbon/icons-react';
import { setAutoAcceptRegionalOfferStatus, getAccountSettings } from '../../../redux/reducers/AccountSettingsSlice';
import { useAppDispatch } from '../../../redux/hooks';

type IManufacturer = {
  erpId: string;
  name: string;
};
type IMarketplace = {
  id: string | number;
  erpId: string;
  name: string;
  supportEmail: string;
  supportPhone: string;
  slaUrl: string;
  logoUrl: string;
  shopUrl: string;
  logistikportalAvailable: boolean;
  allowAcceptingOffersSdm: boolean;
  manufacturers: IManufacturer[];
  regionalMaxDistance: string | number;
  superregionalMaxistance: string | number;
  purchaseOffeDealerTimeout: string;
  deliveryTimeMin: string;
  deliveryTimeMax: string;
  deliveryTimePreparations: string;
  allowTimeslotsInSdm: boolean;
};
type IMarketplaceSetting = {
  id: string | number;
  marketplaceErpId: string;
  manufacturerId: string | number;
  manufacturerName: string;
  dealerCode: string;
  isActive: boolean;
  isRegional: boolean;
  autoAcceptRegionalOffer: boolean;
  isSuperregional: boolean;
};
type IDealerProfile = {
  companyName: string;
  contact: string;
  address: string;
  zipcode: string;
  city: string;
  phone: string;
  mail: string;
  manufacturerCode: string;
  slaUrl: string;
  marketplaces: IMarketplace[];
  marketplaceSettings: IMarketplaceSetting[];
};

const DealerProfile: React.FC<IDealerProfile> = (props: IDealerProfile) => {
  const { t } = useTranslation('account-settings');
  const dispatch = useAppDispatch();
  const { companyName, contact, address, zipcode, city, phone, mail, marketplaces, marketplaceSettings } = props;
  const redirectUrl = AuthenticationUtil.getKeycloackConfigUrl() + 'realms/' + AuthenticationUtil.getKeycloackConfigRealm() + '/account/#/security/signingin';

  const renderCheckbox = (value: boolean) => {
    const unchecked = <Checkbox16 />;
    const checked = <CheckboxCheckedFilled16 />;
    return value ? checked : unchecked;
  };

  const disableTaskCheckBox = (allowAcceptingOffersSdm: boolean) => {
    if (!allowAcceptingOffersSdm) {
      return 'checkboxDisable';
    }
    return '';
  };

  const checkboxHandler = (data: IMarketplace) => {
    if (data.allowAcceptingOffersSdm) {
      (async function () {
        const param = {
          marketplaceId: data.erpId,
        };
        await dispatch(setAutoAcceptRegionalOfferStatus(param)).catch(console.error);
        await dispatch(getAccountSettings()).catch(err => console.error(err));
      })();
    }
  };

  return (
    <div className="dealer--profile">
      <div className="options--container">
        <div className="options--container__password">
          <div className="options--container__password--title fp--label-01">{t('password')}</div>
          <div className="options--container__password--link fp--body-short-01 ">
            <a href={redirectUrl} target="_blank">
              {t('change_password')}
            </a>
          </div>
        </div>
      </div>
      <div className="profile--title fp--productive-heading-02">{t('your_profile')}</div>
      <div className="profile--text fp--body-short-01">{t('contact_details_incorrect_contact_customer_service')}</div>
      {marketplaces.map((marketplace: IMarketplace) =>
        marketplaceSettings.map((settings: IMarketplaceSetting) =>
          settings.marketplaceErpId == marketplace.erpId ? (
            <div key={`${marketplace.id}_${settings.id}`} className="personal--details">
              <div className="personal--details__heading row-2">
                <div className="personal--details__title fp--productive-heading-01">{t('your_marketplaces')}</div>
              </div>

              <div className="profile--contact fp--body-short-01 panel--grey marketplace--logo">
                <div className="profile--contact__labels">
                  <div>
                    <img src={marketplace.logoUrl} alt={marketplace.name} />
                  </div>
                </div>
              </div>
              <div className="profile--contact personal--details__aal fp--body-short-01 margin-bottom panel--grey">
                <div className="profile--contact__labels">
                  <div>{t('documents')}:</div>
                </div>
                <div className="profile--contact__data">
                  <div className="options--container__documents--link">
                    <a href={marketplace.slaUrl} target="_blank" className="fp--body-short-01">
                      {t('service_level_agreement')} <AppIcon icon={Package16} size="xs" color="interactive01" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="profile--contact personal--details__aal fp--body-short-01 margin-bottom panel--grey">
                <div className="profile--contact__labels">
                  <div>{marketplace.logistikportalAvailable ? t('logistikportal_available') : t('logistikportal_not_available')}</div>
                </div>
              </div>

              <div className="col-2 full-width margin-bottom">
                <label className="fp--label-01" htmlFor="dealerCode">
                  {t('manufacturer_dealer_code')}
                </label>
                <input type="text" id="cname" name="cname" value={settings.dealerCode} disabled />
              </div>
              <div className="col-2 full-width">
                <label className="fp--label-01" htmlFor="aal">
                  {t('marketplace_settings')}
                </label>

                <div className="personal--details__aal panel--grey">
                  {settings.isSuperregional && (
                    <span>
                      <div className="personal--details__dealer fp--body-short-01">
                        <AppIcon icon={Checkmark16} size="xs" color="interactive01" />
                        {t('superregional_dealer')}
                      </div>
                    </span>
                  )}
                  <span>
                    <AppIcon icon={Checkmark16} size="xs" color="interactive01" />
                    {t('regional_dealer')}
                  </span>
                </div>
              </div>

              <div className="col-4 full-width margin-bottom checkbox-hand">
                <div className="personal--details__aal panel--grey">
                  <div className={`fp--body-short-01 ${disableTaskCheckBox(marketplace.allowAcceptingOffersSdm)}`}>
                    <span className="custom-checkbox" onClick={() => checkboxHandler(marketplace)}>
                      {renderCheckbox(settings.autoAcceptRegionalOffer)}
                    </span>
                    {t('always_accept_regional_offers')}
                  </div>
                </div>
              </div>
            </div>
          ) : null,
        ),
      )}

      <div className="personal--details">
        <div className="personal--details__heading row-2">
          <div className="personal--details__title fp--productive-heading-01">{t('personal_details')}</div>
        </div>
        <div className="col-2 full-width">
          <label className="fp--label-01" htmlFor="cname">
            {t('company_name')}
          </label>
          <input type="text" id="cname" name="cname" value={companyName} disabled />
        </div>
        <div className="row-2"></div>
        <div className="dealer-code col-2">
          <label className="fp--label-01" htmlFor="name">
            {t('firstName_lastName')}
          </label>
          <input type="text" id="name" name="name" value={contact} disabled />
        </div>
      </div>

      <div className="personal--address">
        <div className="personal--address__heading row-2">
          <div className="personal--address__title fp--productive-heading-01">{t('address')}</div>
        </div>

        <div className="personal--address__street col-2">
          <label className="fp--label-01" htmlFor="street">
            {t('street_house_number')}
          </label>
          <input type="text" id="street" name="street" value={address} disabled />
        </div>

        <div className="row-2">
          <div className="col-2">
            <label className="fp--label-01" htmlFor="postal">
              {t('postal_code')}
            </label>
            <input type="text" id="postal" name="postal" value={zipcode} disabled />
          </div>
          <div className="col-2">
            <label className="fp--label-01" htmlFor="location">
              {t('location')}
            </label>
            <input type="text" id="location" name="location" value={city} disabled />
          </div>
        </div>
      </div>

      <div className="personal--contact">
        <div className="personal--contact__heading row-2">
          <div className="personal--contact__title fp--productive-heading-01">{t('contact_details')}</div>
        </div>

        <div className="row-2">
          <div className="col-2">
            <label className="fp--label-01" htmlFor="phone">
              {t('phone')}
            </label>
            <input type="text" id="phone" name="phone" value={phone} disabled />
          </div>
          <div className="col-2">
            <label className="fp--label-01" htmlFor="email">
              {t('email_address')}
            </label>
            <input type="text" id="email" name="email" value={mail} disabled />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerProfile;
