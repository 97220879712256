import { InlineNotification, Loading } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppButton from '../../common/components/AppButton/AppButton';
import FilterByStatus from '../../common/components/FilterByStatus/FilterByStatus';
import ListItems from '../../common/components/ListItems/ListItems';
import { OverlayPages } from '../../common/components/Overlay/overlayPages';
import { AppointmentStatus } from '../../common/models/appointment';
import { DataStatus } from '../../common/models/dataStatus';
import { PurchaseStatus } from '../../common/models/purchase';
import { IMarketplaceVehicle, IOfferVehicle, IVehicleListItem, VehiclesStatus } from '../../common/models/vehicles';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { show } from '../../redux/reducers/OverlaySlice';
import { filterVehiclesData, getVehiclesState, IAnimation, IVehicleFilterParams } from '../../redux/reducers/VehiclesSlice';
import { DetailsTabKey, IVehiclesDetailsProps } from '../vehicleDetails/VehicleDetails';
import DateTimeUtil from '../../utils/DateTimeUtil';
import './History.scss';

export type HistoryStatus = PurchaseStatus | 'released' | 'release-canceled' | 'rescheduled' | 'registered';

export interface IHistoryVehicle {
  date: Date;
  idTemplate: number;
  idSubscription: number;
  idPurchase: number;
  image: string;
  manufacturer: string;
  model: string;
  variant: string;
  fuel: string;
  transmission: string;
  status: VehiclesStatus;
  historyStatus: HistoryStatus;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: any;
  animation?: IAnimation;
}

const History: React.FC = () => {
  const { t } = useTranslation('history');
  const state = useAppSelector(getVehiclesState);

  const [selectedFilter, setSelectedFilter] = useState<HistoryStatus | null>(null);

  const dispatch = useAppDispatch();
  const { vehicles, status } = state;

  const params: IVehicleFilterParams = {
    filter: {},
    statusList: [
      VehiclesStatus.MARKETPLACE,
      VehiclesStatus.OFFER,
      VehiclesStatus.HANDOVER,
      VehiclesStatus.AT_CUSTOMER,
      VehiclesStatus.RETURN,
      VehiclesStatus.COMPLETED,
      VehiclesStatus.OTHER,
      VehiclesStatus.REJECTED,
    ],
  };

  useEffect(() => {
    (async function () {
      await dispatch(filterVehiclesData(params));
    })();
  }, []);

  const translatedStatus: { key: HistoryStatus; translation: string }[] = [
    {
      key: PurchaseStatus.CANCELED,
      translation: t('cancelled'),
    },
    {
      key: PurchaseStatus.SCHEDULED_FOR_PICK_UP,
      translation: t('pickup_scheduled'),
    },
    {
      key: PurchaseStatus.READY_FOR_PICK_UP,
      translation: t('pickup_open'),
    },
    {
      key: PurchaseStatus.PENDING,
      translation: t('inquiry'),
    },
    {
      key: PurchaseStatus.ACCEPTED,
      translation: t('request_accepted'),
    },
    {
      key: PurchaseStatus.DECLINED,
      translation: t('offer_cancelled'),
    },
    {
      key: PurchaseStatus.FINISHED,
      translation: t('completed'),
    },
    {
      key: PurchaseStatus.PICKED_UP,
      translation: t('at_the_customer'),
    },
    {
      key: PurchaseStatus.REJECTED,
      translation: t('rejected'),
    },
    {
      key: 'released',
      translation: t('released'),
    },
    {
      key: 'release-canceled',
      translation: t('release_cancelled'),
    },
    {
      key: 'rescheduled',
      translation: t('new_pickup'),
    },
    {
      key: PurchaseStatus.INVOICE_PAID,
      translation: t('bill_paid'),
    },
    {
      key: PurchaseStatus.INVOICED,
      translation: t('invoiced'),
    },
    {
      key: PurchaseStatus.RETURNED,
      translation: t('return'),
    },
    {
      key: PurchaseStatus.SCHEDULED_FOR_RETURN,
      translation: t('return_scheduled'),
    },
    {
      key: 'registered',
      translation: t('registered'),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterItems = (statusText: any) => {
    const status = translatedStatus.find(val => val.translation.toLowerCase() === statusText.toLowerCase());

    const getStatusList = (selectedStatus: HistoryStatus) => {
      switch (selectedStatus) {
        case PurchaseStatus.PENDING:
        case PurchaseStatus.ACCEPTED:
          return [
            VehiclesStatus.OFFER,
            VehiclesStatus.HANDOVER,
            VehiclesStatus.RETURN,
            VehiclesStatus.AT_CUSTOMER,
            VehiclesStatus.COMPLETED,
            VehiclesStatus.OTHER,
          ];
        case PurchaseStatus.READY_FOR_PICK_UP:
          return [VehiclesStatus.RETURN, VehiclesStatus.AT_CUSTOMER, VehiclesStatus.COMPLETED, VehiclesStatus.OTHER];
        case PurchaseStatus.SCHEDULED_FOR_PICK_UP:
          return [VehiclesStatus.RETURN, VehiclesStatus.AT_CUSTOMER, VehiclesStatus.COMPLETED, VehiclesStatus.HANDOVER, VehiclesStatus.OTHER];
        case PurchaseStatus.SCHEDULED_FOR_RETURN:
          return [VehiclesStatus.RETURN, VehiclesStatus.AT_CUSTOMER, VehiclesStatus.COMPLETED, VehiclesStatus.OTHER];
        case PurchaseStatus.RETURNED:
          return [VehiclesStatus.RETURN, VehiclesStatus.COMPLETED, VehiclesStatus.OTHER];
        case PurchaseStatus.PICKED_UP:
          return [VehiclesStatus.AT_CUSTOMER, VehiclesStatus.COMPLETED, VehiclesStatus.OTHER];
        case PurchaseStatus.INVOICED:
          return [VehiclesStatus.COMPLETED, VehiclesStatus.OTHER];
        case PurchaseStatus.INVOICE_PAID:
          return [VehiclesStatus.COMPLETED, VehiclesStatus.OTHER];
        case PurchaseStatus.REJECTED:
          return [VehiclesStatus.REJECTED];
        case 'released':
          return [VehiclesStatus.MARKETPLACE, VehiclesStatus.OTHER];
        case 'release-canceled':
          return [VehiclesStatus.MARKETPLACE, VehiclesStatus.OTHER];
        case 'rescheduled':
          return [VehiclesStatus.RETURN, VehiclesStatus.AT_CUSTOMER, VehiclesStatus.COMPLETED, VehiclesStatus.OTHER];
        case 'registered':
          return [
            VehiclesStatus.OFFER,
            VehiclesStatus.HANDOVER,
            VehiclesStatus.RETURN,
            VehiclesStatus.AT_CUSTOMER,
            VehiclesStatus.COMPLETED,
            VehiclesStatus.OTHER,
          ];
        case PurchaseStatus.CANCELED:
          return [VehiclesStatus.OTHER];
        case PurchaseStatus.DECLINED:
          return [VehiclesStatus.OTHER];
        case PurchaseStatus.FINISHED:
          return [VehiclesStatus.OTHER];
        default:
          return [
            VehiclesStatus.OFFER,
            VehiclesStatus.HANDOVER,
            VehiclesStatus.RETURN,
            VehiclesStatus.AT_CUSTOMER,
            VehiclesStatus.COMPLETED,
            VehiclesStatus.MARKETPLACE,
            VehiclesStatus.RELEASE,
            VehiclesStatus.REJECTED,
            VehiclesStatus.OTHER,
          ];
      }
    };

    const statusList = status
      ? getStatusList(status.key)
      : [
          VehiclesStatus.OFFER,
          VehiclesStatus.HANDOVER,
          VehiclesStatus.RETURN,
          VehiclesStatus.AT_CUSTOMER,
          VehiclesStatus.COMPLETED,
          VehiclesStatus.MARKETPLACE,
          VehiclesStatus.RELEASE,
          VehiclesStatus.REJECTED,
          VehiclesStatus.OTHER,
        ];

    const search: IVehicleFilterParams = {
      filter: {},
      statusList: statusList,
    };

    // If 'all statuses is selected' show all vehicles history list.
    if (statusText != t('show_all')) {
      (async function () {
        await dispatch(filterVehiclesData(search));
      })();
    } else {
      (async function () {
        await dispatch(filterVehiclesData(params));
      })();
    }

    if (status && statusText != t('show_all')) {
      setSelectedFilter(status.key);
    } else {
      setSelectedFilter(null);
    }
    return selectedFilter;
  };

  const filterStatusItems = [
    ...[t('show_all')],
    ...[
      t('cancelled'),
      t('pickup_scheduled'),
      t('request_accepted'),
      t('offer_cancelled'),
      t('completed'),
      t('at_the_customer'),
      t('pickup_open'),
      t('inquiry'),
      t('return'),
      t('return_scheduled'),
      t('new_pickup'),
      t('bill_paid'),
      t('invoiced'),
      t('released'),
      t('release_cancelled'),
      t('rejected'),
      t('registered'),
      t('not_registered'),
    ].sort(),
  ];
  const subscriptionHistory: IHistoryVehicle[] = [...vehicles.marketplace].flatMap(vehicle => {
    const dateKeys = [
      { key: 'createdAt', status: 'released' },
      { key: 'unsubscribedAt', status: 'release-canceled' },
    ] as const;

    return dateKeys
      .filter(date => vehicle.details[date.key] !== null)
      .map(date => ({
        date: vehicle.details[date.key] as Date,
        idTemplate: vehicle.idTemplate,
        idSubscription: vehicle.idSubscription,
        idPurchase: 0,
        image: vehicle.image,
        manufacturer: vehicle.manufacturer,
        model: vehicle.model,
        variant: vehicle.variant,
        fuel: vehicle.fuel,
        transmission: vehicle.transmission,
        status: vehicle.status,
        historyStatus: date.status,
        details: vehicle.details,
      }));
  });

  const vehiclesWithAppointments = [...vehicles.handOver, ...vehicles.return];
  const appointmentsHistory: IHistoryVehicle[] = vehiclesWithAppointments.flatMap(vehicle => {
    return vehicle.details.appointments
      .filter(appointment => appointment.appointmentAt !== null)
      .map(appointment => ({
        date: appointment.appointmentAt as Date,
        idTemplate: vehicle.idTemplate,
        idSubscription: vehicle.idSubscription,
        idPurchase: vehicle.idPurchase,
        image: vehicle.image,
        manufacturer: vehicle.manufacturer,
        model: vehicle.model,
        variant: vehicle.variant,
        fuel: vehicle.fuel,
        transmission: vehicle.transmission,
        status: vehicle.status,
        historyStatus:
          vehicle.details.appointments.length > 1 && appointment.status !== AppointmentStatus.DECLINED ? 'rescheduled' : PurchaseStatus.SCHEDULED_FOR_PICK_UP,
        details: vehicle.details,
      }));
  });

  const purchasesHistory: IHistoryVehicle[] = [
    ...vehicles.offer,
    ...vehicles.atCustomer,
    ...vehicles.completed,
    ...vehicles.other,
    ...vehiclesWithAppointments,
  ].flatMap(vehicle => {
    const dateKeys = [
      { key: 'acceptedAt', status: PurchaseStatus.ACCEPTED },
      { key: 'declinedAt', status: PurchaseStatus.DECLINED },
      { key: 'estimatedPickUp', status: PurchaseStatus.SCHEDULED_FOR_PICK_UP },
      { key: 'pickedUpdAt', status: PurchaseStatus.PICKED_UP },
      { key: 'estimatedReturn', status: PurchaseStatus.SCHEDULED_FOR_RETURN },
      { key: 'returnedAt', status: PurchaseStatus.RETURNED },
      { key: 'invoicedAt', status: PurchaseStatus.INVOICED },
      { key: 'invoicePaidAt', status: PurchaseStatus.INVOICE_PAID },
      { key: 'finishedAt', status: PurchaseStatus.FINISHED },
      { key: 'erpCreatedAt', status: PurchaseStatus.PENDING },
      { key: 'canceledAt', status: PurchaseStatus.CANCELED },
      { key: 'rejectedAt', status: PurchaseStatus.REJECTED },
      { key: 'registrationDate', status: PurchaseStatus.REGISTERED },
    ] as const;

    return dateKeys
      .filter(date => {
        if (date.key == 'registrationDate') {
          if (vehicle.details.purchase.vehicle?.registrationDate !== null) {
            return vehicle;
          }
        } else {
          if (vehicle.details.purchase[date.key] !== null) {
            return vehicle;
          }
        }
      })
      .map(date => ({
        date: date.key != 'registrationDate' ? (vehicle.details.purchase[date.key] as Date) : (vehicle.details?.purchase?.vehicle?.registrationDate as Date),
        idTemplate: vehicle.idTemplate,
        idSubscription: vehicle.idSubscription,
        idPurchase: vehicle.idPurchase,
        image: vehicle.image,
        manufacturer: vehicle.manufacturer,
        model: vehicle.model,
        variant: vehicle.variant,
        fuel: vehicle.fuel,
        transmission: vehicle.transmission,
        status: vehicle.status,
        historyStatus: date.status,
        details: vehicle.details,
      }));
  });

  const allCombinedHistory = purchasesHistory
    .concat(appointmentsHistory)
    .concat(subscriptionHistory)
    .filter(item => !selectedFilter || item.historyStatus === selectedFilter);

  const cancelledHistory = allCombinedHistory
    .filter(items => items.historyStatus == 'canceled')
    .map(history => ({
      idPurchase: history.idPurchase,
      date: history.date,
      historyStatus: history.historyStatus,
    }));

  let removeHistory;
  const allHistory = allCombinedHistory.filter(item => {
    removeHistory = cancelledHistory.find(cHistory => {
      if (cHistory.idPurchase == item.idPurchase && item.date > cHistory.date) {
        return cHistory;
      }
    });
    if (!removeHistory) {
      return item;
    }
  });

  const parseData = () => {
    return (
      allHistory
        .map(item => ({
          ...item,
          yearMonth: DateTimeUtil.toStandardMonthYearFormat(item.date),
          weekDay: item.date.toLocaleString('de-DE', { weekday: 'long' }),
          day: DateTimeUtil.dayToStringFormat(item.date),
          hour: DateTimeUtil.timeToStringFormat(item.date),
          hour12: false,
        }))
        .sort((a, b) => b.date.getTime() - a.date.getTime())
        //group by year
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .reduce((grouped: { yearMonth: string; values: any[] }[], currentItem) => {
          const existing = grouped.find(item => item.yearMonth === currentItem.yearMonth);

          if (existing) {
            existing.values.push(currentItem);
          } else {
            grouped.push({ yearMonth: currentItem.yearMonth, values: [currentItem] });
          }

          return grouped;
        }, [])
        //group by day
        .map(yearItem => ({
          ...yearItem,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          values: yearItem.values.reduce((grouped: { day: string; values: any[] }[], currentItem) => {
            const existing = grouped.find(item => item.day === currentItem.day);

            if (existing) {
              existing.values.push(currentItem);
            } else {
              grouped.push({ day: currentItem.day, values: [currentItem] });
            }

            return grouped;
          }, []),
        }))
        //group by hour
        .map(yearItem => ({
          ...yearItem,
          values: yearItem.values.map(dayItem => ({
            ...dayItem,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            values: dayItem.values.reduce((grouped: { hour: string; values: any[] }[], currentItem) => {
              const existing = grouped.find(item => item.hour === currentItem.hour);

              if (existing) {
                existing.values.push(currentItem);
              } else {
                grouped.push({ hour: currentItem.hour, values: [currentItem] });
              }

              return grouped;
            }, []),
          })),
        }))
    );
  };

  const onShowDetails = (status: string, item: IVehicleListItem, idVehicle: number, selectedTab: DetailsTabKey) => {
    const contentProps: IVehiclesDetailsProps = { idVehicleTemplate: item.idTemplate, selectedTab };
    contentProps.idSubscription = (item as IMarketplaceVehicle).idSubscription;

    if (status !== 'release' && status !== 'marketplace') {
      contentProps.idPurchase = (item as IOfferVehicle).idPurchase;
    }
    if (idVehicle !== 0) {
      contentProps.idVehicle = idVehicle;
    }

    const headline = `${item.manufacturer} ${item.model} ${item.variant}`;

    dispatch(show({ headline, content: OverlayPages.VEHICLE_DETAILS, contentProps }));
  };

  const renderError = () => {
    return (
      <InlineNotification iconDescription="describes the close button" title={t('error_content_loaded') as string} notificationType="inline" kind="error" />
    );
  };

  const renderLoading = () => {
    return (
      <div className="home-list__loading">
        <Loading description="Loading list items" withOverlay={false} />
      </div>
    );
  };

  const isDisabled = (item: IHistoryVehicle): boolean => {
    return item.historyStatus === 'release-canceled' || item.historyStatus === 'released';
  };

  const renderVehiclesHistory = () => {
    const parsedValues = parseData();
    if (parsedValues.length > 0) {
      return parsedValues.map(yearItem => (
        <div key={yearItem.yearMonth}>
          <h3 className="month-year__headline">{yearItem.yearMonth}</h3>
          <div className="month-year__values">
            {yearItem.values.map(dayItem => (
              <div key={dayItem.day}>
                <div className="day__headline fp--productive-heading-01">{dayItem.day}</div>
                <div className="day__values">
                  {dayItem.values.map(hourItem => (
                    <ListItems
                      key={`list-${hourItem.hour}`}
                      headline={hourItem.hour}
                      items={hourItem.values.map(item => ({
                        page: 'HistoryPage',
                        key: item.idPurchase,
                        button: (
                          <AppButton
                            disabled={isDisabled(item)}
                            onClick={() => onShowDetails(item.details.status, item, item.details.idVehicle, 'vehicle-file')}
                            kind="secondary"
                          >
                            {t('to_the_vehicle_file')}
                          </AppButton>
                        ),
                        item: item,
                        onShowDetails: () => onShowDetails(item.details.status, item, item.details.idVehicle, 'details'),
                      }))}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ));
    } else {
      return <div className={`list-items__children--empty`}>{t('no_vehicles_available')}</div>;
    }
  };

  const renderHistoryFilter = () => {
    return <FilterByStatus filterItems={filterStatusItems} onFilterChange={filterItems} label={t('filter_status')} />;
  };

  const render = () => {
    if (status === DataStatus.LOADING) {
      return renderLoading();
    }
    if (status === DataStatus.FAILED) {
      return renderError();
    }

    return renderVehiclesHistory();
  };

  return (
    <div className="history-wrapper">
      <div className="history-filter">{renderHistoryFilter()}</div>
      {render()}
    </div>
  );
};

export default History;
