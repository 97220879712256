import { useTranslation } from 'react-i18next';
import TopPageHeadline from '../common/components/TopPageHeadline/TopPageHeadline';
import CalendarVehicles from '../modules/calendarVehicles/CalendarVehicles';
import './CalendarPage.scss';

const KalenderPage: React.FC = () => {
  const { t } = useTranslation('calendar');

  return (
    <div className="components-vehicles-page">
      <TopPageHeadline text={t('calendar')} />
      <div className="container">
        <CalendarVehicles />
      </div>
    </div>
  );
};

export default KalenderPage;
