import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DealerProfile from '../../common/components/DealerProfile/DealerProfile';
import RegisterTimeSlots from '../../common/components/RegisterTimeSlots/RegisterTimeSlots';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getDealerInfo, validateDealer } from '../../redux/reducers/AuthenticationSlice';

import Unavailable from '../../assets/icons/unavailable.svg';

import './Register.scss';
import { DealerStatus } from '../../common/models/dealer';
import { getTimeSlots } from '../../redux/reducers/TimeSlotsSlice';
import AppButton from '../../common/components/AppButton/AppButton';
import AuthenticationUtil from '../../utils/AuthenticationUtil';
import { ThemeUtil, useTheme } from '../../utils/ThemeUtil';

const Register: React.FC = () => {
  const { t } = useTranslation('register');
  const theme = useTheme();
  const dealer = useAppSelector(getDealerInfo);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState('');

  useEffect(() => {
    (async function () {
      await dispatch(getTimeSlots());
    })();
      
    if (dealer && dealer.status === DealerStatus.ACTIVE) {
      setPage('profile');
    } else {
      setPage('unavailable');
    }
  }, []);

  const onChangePage = (page: string) => {
    setPage(page);
  };

  const onValidateDealer = () => {
    (async function () {
      await dispatch(validateDealer());
    })();
  };

  const onRelogin = () => {
    (async () => {
    await AuthenticationUtil.logout();
  })();
    
  };

  const renderUnavailable = () => {
    return (
      <div className="register__unavailable">
        <img src={Unavailable} alt="Unavailable" className="register__unavailable-img" />
        <h2>{t('registration_currently_not_possible')}</h2>
        <div className="register__unavailable-description fp--body-long-02">{t('customer_care_service')}</div>
        <div className="register__unavailable-contacts fp--body-long-02" dangerouslySetInnerHTML={{__html: ThemeUtil.decode(theme.t('help_contacts'))}}></div>
        <div className="register__buttons">
          <AppButton onClick={onRelogin}>{t('continue')}</AppButton>
        </div>
      </div>
    );
  };

  const renderProfile = () => {
    return (
      <div className="register__profile">
        <h5>{t('company_profile')}</h5>
        {dealer && (
          <DealerProfile
            companyName={dealer.companyName}
            contact={dealer.contact}
            address={dealer.address}
            zipcode={dealer.zipCode}
            city={dealer.city}
            phone={dealer.phone}
            mail={dealer.mail}
            manufacturerCode={dealer.manufacturerCode}
            slaUrl={dealer.slaUrl}
            marketplaces={dealer.marketplaces}
            marketplaceSettings={dealer.marketplaceSettings}
          />
        )}
        <div className="register__buttons">
          <AppButton onClick={() => onChangePage('time-slots')}>{t('continue')}</AppButton>
        </div>
      </div>
    );
  };

  const renderTimeSlots = () => {
    return (
      <div className="register__time-slots">
        <h5>{t('your_pickup_times')}</h5>
        {dealer && <RegisterTimeSlots idDealer={dealer.id} marketplaces={dealer.marketplaces} />}
        <div className="register__buttons">
          <AppButton kind="secondary" onClick={() => onChangePage('profile')}>
            {t('return')}
          </AppButton>

          <AppButton onClick={onValidateDealer}>{t('complete_onboarding')}</AppButton>
        </div>
      </div>
    );
  };

  const renderPage = () => {
    switch (page) {
      case 'profile':
        return renderProfile();
      case 'time-slots':
        return renderTimeSlots();
      case 'unavailable':
        return renderUnavailable();
      default:
        return '';
    }
  };

  return <div className="register">{renderPage()}</div>;
};

export default Register;
