/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import AuthenticationUtil from './AuthenticationUtil';
import EnvUtil from './EnvUtil';

type RequestMethod = 'get' | 'post' | 'put' | 'delete';

const getDefaultConfig = (): AxiosRequestConfig => ({
  responseType: 'json',
  headers: {
    Authorization: `Bearer ${AuthenticationUtil.getToken()}`,
  },
});

function getApiUrl(): string {
  const apiUrl = EnvUtil.get<string>('FP_API_URL');
  if (apiUrl) {
    return apiUrl;
  }

  return EnvUtil.get<number>('FP_API_PORT')
    ? `${EnvUtil.get<string>('FP_PROTOCOL')}://${EnvUtil.get<string>('FP_API_HOSTNAME')}:${EnvUtil.get<number>('FP_API_PORT')}/`.replace('://:/', '/')
    : `${EnvUtil.get<string>('FP_PROTOCOL')}://${EnvUtil.get<string>('FP_API_HOSTNAME')}/`.replace(':///', '/');
}

const API_URL = getApiUrl();
class HttpUtil {
  static request<T>(method: RequestMethod, url: string, params: AxiosRequestConfig): AxiosPromise<T> {
    const updatedParams = {
      ...getDefaultConfig(),
      ...params,
      method,
      url: `${API_URL}${url}`,
    };

    return AuthenticationUtil.refreshToken()
      .then(() => {
        return axios(updatedParams);
      })
      .catch(err => {
        console.error(err);
        if (err && err.response && err.status && err.response.status === 403) {
          window.location.replace('/');
        }
        throw new Error('Token has expired!');
      });
  }

  static get<T>(url: string, params: Record<string, unknown> = {}): AxiosPromise<T> {
    return HttpUtil.request('get', url, { params });
  }

  static post<T>(url: string, data: any = {}): AxiosPromise<T> {
    return HttpUtil.request('post', url, { data });
  }

  static put<T>(url: string, data: any = {}): AxiosPromise<T> {
    return HttpUtil.request('put', url, { data });
  }

  static delete<T>(url: string, data: any = {}): AxiosPromise<T> {
    return HttpUtil.request('delete', url, { data });
  }
}

export default HttpUtil;
