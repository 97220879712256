import TopPageHeadline from '../common/components/TopPageHeadline/TopPageHeadline';
import CalendarVehicles from '../common/components/TestCalendar/CalendarVehicles';
import './CalendarTestPage.scss';

const KalenderTestPage: React.FC = () => {
  return (
    <div className="components-vehicles-page">
      <TopPageHeadline text="Kalender" />
      <CalendarVehicles />
    </div>
  );
};

export default KalenderTestPage;
