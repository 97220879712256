import { InlineNotification, Loading } from 'carbon-components-react';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getVehiclesData, filterVehiclesData, getVehiclesFilterState } from '../../../redux/reducers/VehiclesSlice';
import { getVehicleTemplateState } from '../../../redux/reducers/VehicleTemplateSlice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataStatus } from '../../../common/models/dataStatus';
import Filter from '../../../common/components/Filter/Filter';
import { IMarketplaceVehicle } from '../../../common/models/vehicles';

interface MarketplaceItemsFilterProps {
  items: IMarketplaceVehicle[];
}

const VehiclesFilter: React.FC<MarketplaceItemsFilterProps> = (props: MarketplaceItemsFilterProps) => {
  const { t } = useTranslation('vehicles');
  const { items } = props;
  const state = useAppSelector(getVehicleTemplateState);
  const filterState = useAppSelector(getVehiclesFilterState);
  const { status } = state;
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterItems = (selectedCar: any) => {
    const params = {
      filter: {
        searchByTemplateId: selectedCar.id,
      },
      statusList: filterState ? filterState.statusList : [],
    };
    params.filter.searchByTemplateId !== -1 ? dispatch(filterVehiclesData(params)).catch(console.error) : dispatch(getVehiclesData()).catch(console.error);
  };

  const selectAllModels = [
    {
      manufacturer: '',
      model: t('all_types'),
      variant: '',
      id: -1,
    },
  ];

  const filterVehiclesModels = items
    ? selectAllModels.concat(
        items.filter(v => v.details.active).map(t => ({ manufacturer: t.manufacturer, model: t.model, variant: t.variant, id: t.idTemplate })),
      )
    : selectAllModels;

  const renderVehiclesFilter = () => {
    return <Filter filterItems={filterVehiclesModels} onFilterChange={filterItems} label={t('select_model')} />;
  };

  const renderError = () => {
    return (
      <InlineNotification iconDescription="describes the close button" title={t('error_content_loaded') as string} notificationType="inline" kind="error" />
    );
  };

  const renderLoading = () => {
    return (
      <div className="home-list__loading">
        <Loading description="Loading list items" withOverlay={false} />
      </div>
    );
  };

  const render = () => {
    if (status === DataStatus.LOADING) {
      return renderLoading();
    }
    if (status === DataStatus.FAILED) {
      return renderError();
    }

    return renderVehiclesFilter();
  };

  return <div className="component-wrapper">{render()}</div>;
};

export default VehiclesFilter;
