import './MarketplaceItem.scss';

import { IMarketplaceVehicle } from '../../models/vehicles';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';
import Image from '../Image/Image';
import { TrashCan16 } from '@carbon/icons-react';
import { IDeleteSubscriptionModalProps } from '../MarketplaceItems/MarketplaceItems';
import AppTooltip from '../AppTooltip/AppTooltip';

type MarketplaceItemProps = {
  item: IMarketplaceVehicle;
  button: ReactElement;
  onShowDetails: () => void;
  showDeleteSubscriptionModal: (x: IDeleteSubscriptionModalProps) => void;
};

const MarketplaceItem: React.FC<MarketplaceItemProps> = (props: MarketplaceItemProps) => {
  const { t } = useTranslation('vehicles');
  const { image, manufacturer, model, variant, fuel, transmission, idTemplate, idSubscription, animation } = props.item;
  const modelCompleteName = manufacturer + ' ' + model + ' ' + variant;
  const { onShowDetails, showDeleteSubscriptionModal } = props;
  const maxNROfCharacters = 28;

  const disableDealersubscription = (getTimeslotval: boolean) => {
    if (!getTimeslotval) {
      return 'dealerSubscriptionDisable';
    }
    return '';
  };

  return (
    <div className={`marketplace-item marketplace-item--${animation}`}>
      <div className="marketplace-item--image">
        <Image link={image} alt="Modelpicture" size="small" />
      </div>
      <div className="marketplace-item--info">
        <div className="marketplace-item--info__content fp--body-short-01">
          <AppTooltip maxNROfCharacters={maxNROfCharacters} tooltipText={modelCompleteName} className="marketplace-item--model">
            <div className="marketplace-item--model fp--productive-heading-01">{modelCompleteName}</div>
          </AppTooltip>
          <div className="marketplace-item--description">
            {t(fuel)}, {t(transmission)}
          </div>
          <div className="marketplace-item--details" onClick={onShowDetails}>
            {t('details')}
          </div>
        </div>

        <div
          className={`marketplace-item--delete ${disableDealersubscription(props.item.details.isSdmDealerSubscriptionAllowed)}`}
          onClick={() =>
            showDeleteSubscriptionModal({
              isOpen: true,
              title: `${manufacturer} ${model} ${variant}`,
              idVehicleTemplate: idTemplate,
              idSubscription: idSubscription,
            })
          }
        >
          <TrashCan16></TrashCan16>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceItem;
