import './VehicleAppointment.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppIcon from '../../../../common/components/AppIcon/AppIcon';
import { Calendar16, Checkbox16, CheckboxCheckedFilled16 } from '@carbon/icons-react';
import { AppointmentAction, AppointmentStatus, IAppointment } from '../../../../common/models/appointment';
import AppModal from '../../../../common/components/AppModal/AppModal';
import PickUpReturnAppointment from '../PickUpReturnAppointment/PickUpReturnAppointment';
import {
  updateVehicleAppointment,
  IUpdateAppointmentParams,
  ICreateAppointmentParams,
  createVehicleAppointment,
} from '../../../../redux/reducers/VehicleAppointmentSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { getDealerInfo } from '../../../../redux/reducers/AuthenticationSlice';
import { addTaskDone } from '../../../../redux/reducers/PurchaseSlice';
import DateTimeUtil from '../../../../utils/DateTimeUtil';
import { ICustomer, ITask, TaskType, IPurchase } from '../../../../common/models/purchase';
import { filterVehiclesData, IVehicleFilterParams } from '../../../../redux/reducers/VehiclesSlice';
import { VehiclesStatus } from '../../../../common/models/vehicles';
import { getDashboardData } from '../../../../redux/reducers/DashboardSlice';
import { Loading } from 'carbon-components-react';

type IVehicleAppointmentProps = {
  selectedPurchase: IPurchase | null;
  idPurchase: number;
  estimatedDate: Date | null;
  status: string;
  date: string;
  customer: ICustomer;
  tasks: ITask[];
  appointments: IAppointment[];
  vehicleTitle: string;
};

interface IPickUpReturnCalendarModalProps {
  isOpen: boolean;
  title: string;
  idPurchase: number;
  action: 'pick-up' | 'return';
}

const emptyPickUpReturnCalendarModal: IPickUpReturnCalendarModalProps = {
  isOpen: false,
  title: '',
  idPurchase: 0,
  action: 'pick-up',
};

export interface IDateTime {
  date: string;
  time: string | undefined;
}

const renderCheckbox = (value: boolean) => {
  const unchecked = <Checkbox16 />;
  const checked = <CheckboxCheckedFilled16 />;
  return value ? checked : unchecked;
};

const VehicleAppointment: React.FC<IVehicleAppointmentProps> = (props: IVehicleAppointmentProps) => {
  const { t } = useTranslation('vehicle-appointments');
  const dispatch = useAppDispatch();
  const dealerInfo = useAppSelector(getDealerInfo);

  const timeSlots = dealerInfo ? dealerInfo.timeSlots : [];
  const { selectedPurchase, idPurchase, status, date, customer, tasks, estimatedDate, appointments, vehicleTitle } = props;
  const location = dealerInfo ? dealerInfo.address : '';

  const [pickUpCalendarModalProps, showPickUpReturnCalendarModal] = useState<IPickUpReturnCalendarModalProps>(emptyPickUpReturnCalendarModal);

  const PICK_UP = status === 'ready-for-pick-up' || status === 'scheduled-for-pick-up';
  const TO_RETURN = status === 'scheduled-for-return';
  const AT_CUSTOMER = status === 'picked-up';
  const AT_DEALER = !PICK_UP && !TO_RETURN && !AT_CUSTOMER;
  const existingAppointment: IAppointment | null =
    (() =>
      appointments.find(a => {
        const action = PICK_UP ? AppointmentAction.HAND_OVER : AppointmentAction.RETURN;

        return a.action === action && (a.status === AppointmentStatus.REQUESTED || a.status === AppointmentStatus.ACCEPTED);
      }))() || null;

  const emptyDateTime = {
    date,
    time: t('open'),
  };

  const getDateTime = (): IDateTime => {
    const loadedDateTime = emptyDateTime;
    if (existingAppointment?.appointmentAt) {
      loadedDateTime.date = DateTimeUtil.dayToStringFormat(existingAppointment.appointmentAt);
      loadedDateTime.time = DateTimeUtil.timeToStringFormat(existingAppointment.appointmentAt);
    }

    return loadedDateTime;
  };
  const [selectedDateTime, setSelectedDateTime] = useState<IDateTime>(emptyDateTime);
  const [dateTime, setDateTime] = useState<IDateTime>(getDateTime());
  const [primaryButtonDisabled, updatePrimaryButtonDisabled] = useState<boolean>(true);

  const getHeaderClass = () => {
    let headerClass = 'fp--body-short-01 ';
    if (PICK_UP || TO_RETURN) {
      headerClass += 'vehicle-appointment--header__right';
    }
    if (AT_CUSTOMER) {
      headerClass += 'vehicle-appointment--header__right--disabled';
    }
    if (AT_DEALER) {
      headerClass += 'vehicle-appointment--header__right--hidden';
    }

    return headerClass;
  };

  const disableTaskCheckBox = (todo: boolean, identifier: string | null) => {
    if (!todo) {
      return 'checkboxDisable';
    }
    if (identifier != 'no_automation') {
      return 'checkboxDisable';
    }
    
    return '';
  };

  const getDateTitle = () => {
    let dateTitle;
    PICK_UP && (dateTitle = t('pickup_date'));
    AT_CUSTOMER && (dateTitle = t('return_date'));
    TO_RETURN && (dateTitle = t('return_date'));
    AT_DEALER && (dateTitle = t('transfer_risk'));
    return dateTitle;
  };

  const getTimeTitle = () => {
    let timeTitle;
    PICK_UP && (timeTitle = t('pickup_time'));
    AT_CUSTOMER && (timeTitle = t('return_time'));
    TO_RETURN && (timeTitle = t('return_time'));
    AT_DEALER && (timeTitle = t(''));
    return timeTitle;
  };

  const onUpdateDateTime = (dateTime: IDateTime) => {
    updatePrimaryButtonDisabled(!dateTime.date || !dateTime.time);
    setSelectedDateTime(dateTime);
  };

  const checkboxHandler = (task: ITask) => {
    if (task) {
      const selectedTask = tasks.find(element => element.id == task.id);
      if (selectedTask && !selectedTask.isFinished) {
        (async function () {
          await dispatch(
            addTaskDone({
              idPurchase: idPurchase,
              idTask: task.id,
              isFinished: true,
            }),
          );
        })();
      }
    }
  };

  const updateAppointmentTask = () => {
  
    const filteredTasks = tasks.filter(taskItem => {
      if (PICK_UP && (taskItem.type == TaskType.CHECK_OUT_TASK) && (taskItem.auto_identifier == 'checkout_automation')) {
        return taskItem;
      } else if (TO_RETURN && (taskItem.type == TaskType.CHECK_IN_TASK) && (taskItem.auto_identifier == 'checkin_automation')) {
        return taskItem;
      } else {
        return null;
      }
    });

    filteredTasks.forEach(filterTask => {
      (async function () {
        await dispatch(
          addTaskDone({
            idPurchase: idPurchase,
            idTask: filterTask.id,
            isFinished: true,
          }),
        );
      })();
    });
  };

  const filterTaskForAppointment = async () => {
    if ((selectedPurchase?.vehicle?.registrationDate && PICK_UP) || TO_RETURN) {
      updateAppointmentTask();
    }
  };

  const onCreateUpdateAppointment = async (
    idPurchase: number,
    appointment: IAppointment | null,
    status: AppointmentStatus.ACCEPTED | AppointmentStatus.DECLINED,
    action: AppointmentAction,
  ) => {
    if (selectedDateTime.date && selectedDateTime.time) {
      const dateParts = selectedDateTime.date.split('.');
      const timeParts = selectedDateTime.time.split(':');

      timeParts[0] = String(timeParts[0]).padStart(2, '0');
      const appointmentAt = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${timeParts[0]}:${timeParts[1]}:00Z`);

      if (appointment) {
        const params: IUpdateAppointmentParams = {
          idAppointment: appointment.id,
          status,
          appointmentAt,
        };

        await dispatch(updateVehicleAppointment(params));
        await filterTaskForAppointment();
      } else {
        const params: ICreateAppointmentParams = {
          idPurchase,
          action,
          appointmentAt,
          status: AppointmentStatus.ACCEPTED,
        };
        await dispatch(createVehicleAppointment(params));
        await filterTaskForAppointment();
      }

      const paramsVehicleUpdate: IVehicleFilterParams = {
        filter: {},
        statusList: [VehiclesStatus.OFFER, VehiclesStatus.HANDOVER, VehiclesStatus.RETURN, VehiclesStatus.AT_CUSTOMER, VehiclesStatus.COMPLETED],
      };
      dispatch(filterVehiclesData(paramsVehicleUpdate)).catch(console.error);
      dispatch(getDashboardData()).catch(console.error);
    }
    showPickUpReturnCalendarModal(emptyPickUpReturnCalendarModal);
  };

  const renderModals = () => {
    return (
      <AppModal
        open={pickUpCalendarModalProps.isOpen}
        className={'isSuperregional'}
        onRequestClose={() => {
          showPickUpReturnCalendarModal(emptyPickUpReturnCalendarModal);
        }}
        onRequestSubmit={() => {
          setDateTime(selectedDateTime);
          onCreateUpdateAppointment(
            pickUpCalendarModalProps.idPurchase,
            existingAppointment,
            AppointmentStatus.ACCEPTED,
            PICK_UP ? AppointmentAction.HAND_OVER : AppointmentAction.RETURN,
          ).catch(console.error);
        }}
        onSecondarySubmit={() => showPickUpReturnCalendarModal(emptyPickUpReturnCalendarModal)}
        primaryButtonText={t('confirming_appointment')}
        secondaryButtonText={t('abort')}
        modalHeading={pickUpCalendarModalProps.title}
        showSecondaryButton={true}
        primaryButtonDisabled={primaryButtonDisabled}
      >
        {pickUpCalendarModalProps.isOpen && (
          <PickUpReturnAppointment
            date={dateTime.date}
            time={dateTime.time}
            customer={customer}
            location={location}
            action={pickUpCalendarModalProps.action}
            timeSlots={timeSlots}
            estimatedDate={estimatedDate}
            onUpdateDateTime={onUpdateDateTime}
            updatePrimaryButtonDisabled={updatePrimaryButtonDisabled}
          />
        )}
      </AppModal>
    );
  };

  const renderlist = () => {
    return (
      <ul>
        {tasks
          .filter(
            taskfilter =>
              (PICK_UP && taskfilter.type === TaskType.CHECK_OUT) ||
              (PICK_UP && taskfilter.type === TaskType.CHECK_OUT_TASK) ||
              (TO_RETURN && taskfilter.type === TaskType.CHECK_IN) ||
              (TO_RETURN && taskfilter.type === TaskType.CHECK_IN_TASK),
          )
          .map((task) => {
            if (task.isFinished) {
              return (
                <li key={`task-${task.id}`} className="fp--body-short-01">
                  {task.description}
                </li>
              );
            } else {
              return (
                <li key={`task-${task.id}`} className={`fp--body-short-01 ${disableTaskCheckBox(task.todo, task.auto_identifier)}`}>
                  <span className="custom-checkbox" onClick={() => checkboxHandler(task)}>
                    {renderCheckbox(task.isFinished)}
                  </span>
                  {task.description}
                </li>
              );
            }
          })}
      </ul>
    );
  };

  const renderTasklist = () => {
    if (tasks.length === 0 || tasks == null) {
      return (
        <div className="tasklistMessage">
          <div className="appointment--tasks__title fp--productive-heading-01">{t('tasks')}</div>
          <div className="text">Task list are Updating, Please wait ....</div>
          <div className="vehicle-details__loading">
            <Loading description={t('tasks')} withOverlay={false} />
          </div>
        </div>
      );
    }
    if ((PICK_UP || TO_RETURN) && tasks && tasks.length > 0) {
      return (
        <div className="appointment--tasks">
          <div className="appointment--tasks__title fp--productive-heading-01">{t('tasks')}</div>
          {renderlist()}
        </div>
      );
    }
  };

  const renderAppionmentButton = () => {
    const Appaction = existingAppointment?.action;
    if (PICK_UP || TO_RETURN) {
      if (Appaction == AppointmentAction.HAND_OVER || Appaction == AppointmentAction.RETURN) {
        return t('shift_schedule');
      } else {
        return t('make_an_appointment');
      }
    } else {
      if (Appaction == AppointmentAction.HAND_OVER || Appaction == AppointmentAction.RETURN) {
        return t('shift_schedule');
      }
    }
  };

  const renderNotification = () => {
    if ((PICK_UP || TO_RETURN) && tasks && tasks.length > 0) {
      return (
        <div className="appointment--tasks">
          <div className="appointment--tasks__title fp--productive-heading-01">{t('hints')}</div>
          <ul>
            {tasks
              .filter(
                taskfilter =>
                  (PICK_UP && taskfilter.type === TaskType.CHECK_OUT) ||
                  (PICK_UP && taskfilter.type === TaskType.CHECK_OUT_HINT) ||
                  (TO_RETURN && taskfilter.type === TaskType.CHECK_IN) ||
                  (TO_RETURN && taskfilter.type === TaskType.CHECK_IN_HINT),
              )
              .map(task => {
                return (
                  <li key={task.id} className="fp--body-short-01">
                    {task.description}
                  </li>
                );
              })}
          </ul>
        </div>
      );
    }
  };

  return (
    <div className="vehicle-appointment">
      {renderModals()}
      <div className="vehicle-appointment--header">
        <div className="vehicle-appointment--header__left fp--productive-heading-01">{AT_DEALER ? t('completed_appointment') : t('upcoming_appointment')}</div>
        <div
          className={getHeaderClass()}
          onClick={() =>
            showPickUpReturnCalendarModal({
              isOpen: true,
              title: `${vehicleTitle} - ${t('edit_appointment')}`,
              idPurchase: idPurchase,
              action: PICK_UP ? 'pick-up' : 'return',
            })
          }
        >
          {renderAppionmentButton()}
          <AppIcon icon={Calendar16} size="xs" color={AT_CUSTOMER ? 'default' : 'interactive01'} />
        </div>
      </div>
      <div className="appointment">
        <div className={`${AT_DEALER ? 'appointment--date__alone' : 'appointment--date'}`}>
          <div className="appointment--date__title fp--label-01">{getDateTitle()}</div>
          <div className="appointment--date__info fp--body-short-01 ">{dateTime.date}</div>
        </div>
        <div className={`${AT_DEALER ? 'appointment--time__hidden' : 'appointment--time'}`}>
          <div className="appointment--time__title fp--label-01">{getTimeTitle()}</div>
          <div className="appointment--time__info fp--body-short-01 ">{dateTime.time}</div>
        </div>
      </div>
      <div className="appointment--info">
        <div className="client--label">{t('customer')}</div>
        <div className="client--name">{customer.contactName}</div>

        <div className="phone--label">{t('phone')}</div>
        <div className="phone--name">{customer.phone}</div>

        <div className="location--label">{PICK_UP ? t('pickup_location') : t('return_location')}</div>
        <div className="location--name">{location}</div>
      </div>
      {renderTasklist()}
      {renderNotification()}
    </div>
  );
};

export default VehicleAppointment;
