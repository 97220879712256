import { ReactElement, useState } from 'react';
import MarketplaceItem from '../MarketplaceItem/MarketplaceItem';
import { IMarketplaceVehicle } from '../../models/vehicles';
import './MarketplaceItems.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/hooks';
import { deleteSubscription } from '../../../redux/reducers/SubscriptionSlice';
import AppModal from '../AppModal/AppModal';
import DeleteSubscription from '../../../modules/vehicles/components/DeleteSubscription/DeletSubscription';
import AppIcon from '../AppIcon/AppIcon';
import { enableHideSubscriptionAnimation } from '../../../redux/reducers/VehiclesSlice';
import { DataStatus } from '../../models/dataStatus';
import { Loading } from 'carbon-components-react';

interface MarketplaceItem {
  item: IMarketplaceVehicle;
  button: ReactElement;
  onShowDetails: () => void;
}

interface MarketplaceItemsProps {
  status: DataStatus;
  items: MarketplaceItem[];
}

export interface IDeleteSubscriptionModalProps {
  isOpen: boolean;
  title: string;
  idVehicleTemplate: number;
  idSubscription: number;
}

const emptyDeleteSubscriptionModal: IDeleteSubscriptionModalProps = {
  isOpen: false,
  title: '',
  idVehicleTemplate: 0,
  idSubscription: 0,
};

const MarketplaceItems: React.FC<MarketplaceItemsProps> = (props: MarketplaceItemsProps) => {
  const { t } = useTranslation('vehicles');
  const { items, status } = props;

  const dispatch = useAppDispatch();
  const [deleteSubscriptionModalPros, showDeleteSubscriptionModal] = useState<IDeleteSubscriptionModalProps>(emptyDeleteSubscriptionModal);

  const onDeleteSubscription = (idSubscription: number) => {
    (async function () {
      await dispatch(deleteSubscription({ idSubscription }));
    })();
    showDeleteSubscriptionModal(emptyDeleteSubscriptionModal);
    dispatch(enableHideSubscriptionAnimation({ idSubscription }));
  };

  const renderModal = () => {
    return (
      <AppModal
        className={'isSuperregional'}
        open={deleteSubscriptionModalPros.isOpen}
        onRequestClose={() => showDeleteSubscriptionModal(emptyDeleteSubscriptionModal)}
        onRequestSubmit={() => onDeleteSubscription(deleteSubscriptionModalPros.idSubscription)}
        primaryButtonText={t('cancel_release')}
        secondaryButtonText={t('abort')}
        modalHeading={deleteSubscriptionModalPros.title}
        showSecondaryButton={true}
        size="sm"
      >
        {deleteSubscriptionModalPros.isOpen && <DeleteSubscription idVehicleTemplate={deleteSubscriptionModalPros.idVehicleTemplate} />}
      </AppModal>
    );
  };

  return (
    <div className="market-items">
      {renderModal()}
      {items.length ? <div className="release-page--market__description fp--body-long-01">{t('marketplace_vehicle_offers')}</div> : ''}
      {items.length ? (
        items.map(item => (
          <MarketplaceItem
            onShowDetails={item.onShowDetails}
            key={item.item.idTemplate}
            item={item.item}
            button={item.button}
            showDeleteSubscriptionModal={showDeleteSubscriptionModal}
          />
        ))
      ) : (
        <div className="market-items--empty">
          <div className="market-items--empty__icon">
            <AppIcon icon="marketEmpty" size="xl" color="disabled" />
          </div>
          <div className="market-items--empty__text">{t('no_models_released')}</div>
        </div>
      )}
      {status === DataStatus.LOADING && <Loading description="Loading list items" withOverlay={false} />}
    </div>
  );
};

export default MarketplaceItems;
