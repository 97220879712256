import AppTable from './AppTable/AppTable';

const TableTest: React.FC = () => (
  <div>
    <AppTable
      rows={[
        {
          id: 'a',
          name: 'cell text',
        },
        {
          id: 'b',
          name: 'cell text',
        },
        {
          id: 'c',
          name: 'cell text',
        },
      ]}
      headers={[
        {
          key: 'name',
          header: 'Column header - 24 compact',
        },
      ]}
      size="compact"
    />
    <AppTable
      rows={[
        {
          id: 'a',
          name: 'cell text',
        },
        {
          id: 'b',
          name: 'cell text',
        },
        {
          id: 'c',
          name: 'cell text',
        },
      ]}
      headers={[
        {
          key: 'name',
          header: 'Column header - 32 short',
        },
      ]}
      size="short"
    />
    <AppTable
      rows={[
        {
          id: 'a',
          name: 'cell text',
        },
        {
          id: 'b',
          name: 'cell text',
        },
        {
          id: 'c',
          name: 'cell text',
        },
      ]}
      headers={[
        {
          key: 'name',
          header: 'Column header - 48 default',
        },
      ]}
      size="normal"
    />
    <AppTable
      rows={[
        {
          id: 'a',
          name: 'cell text',
        },
        {
          id: 'b',
          name: 'cell text',
        },
        {
          id: 'c',
          name: 'cell text',
        },
      ]}
      headers={[
        {
          key: 'name',
          header: 'Column header - 64 tall',
        },
      ]}
      size="tall"
    />
    <AppTable
      rows={[
        {
          id: 'a',
          name: 'Load balancer 1',
          status: 'Disabled',
        },
        {
          id: 'b',
          name: 'Load balancer 2',
          status: 'Starting',
        },
        {
          id: 'c',
          name: 'Load balancer 3',
          status: 'Active',
        },
      ]}
      headers={[
        {
          key: 'name',
          header: 'Name',
        },
        {
          key: 'status2',
          header: 'Status',
        },
        {
          key: 'status3',
          header: 'Status',
        },
        {
          key: 'status4',
          header: 'Status',
        },
        {
          key: 'status5',
          header: 'Status',
        },
      ]}
      size="normal"
    />
  </div>
);

export default TableTest;
