import './Vehicles.scss';

import VehicleListHeader from '../../common/components/VehicleListHeader/VehicleListHeader';
import VehiclesFilter from './components/VehiclesFilter';
import VehiclesProcessBar from './components/VehiclesProcessBar';
import VehiclesBody from './components/VehiclesBody';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { filterVehiclesData, IVehicleFilterParams, getVehiclesState } from '../../redux/reducers/VehiclesSlice';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import VehiclesSearch from './components/VehiclesSearch';
import { getVehiclesTemplates } from '../../redux/reducers/VehicleTemplateSlice';
import { VehiclesStatus } from '../../common/models/vehicles';
import EnvUtil from '../../utils/EnvUtil';
import { IRefresh } from '../../utils/RefreshUtil';

export interface ITranslatedHeadlines {
  offers: string;
  handovers: string;
  atCustomer: string;
  return: string;
  completed: string;
}

const Vehicles: React.FC = () => {
  const { t } = useTranslation('vehicles');
  const dispatch = useAppDispatch();
  const state = useAppSelector(getVehiclesState);
  const { vehicles } = state;
  const translatedHeadlines: ITranslatedHeadlines = {
    offers: t('inquiry'),
    handovers: t('pickup'),
    atCustomer: t('at_the_customer'),
    return: t('return'),
    completed: t('at_the_dealer'),
  };
  const refresh: IRefresh = {
    intervalId: undefined,
    time: (EnvUtil.get('REFRESH_INTERVAL') as unknown as number) || 0,
  };

  useEffect(() => {
    const params: IVehicleFilterParams = {
      filter: {},
      statusList: [
        VehiclesStatus.OFFER,
        VehiclesStatus.HANDOVER,
        VehiclesStatus.AT_CUSTOMER,
        VehiclesStatus.RETURN,
        VehiclesStatus.COMPLETED,
        VehiclesStatus.MARKETPLACE,
      ],
    };
    dispatch(filterVehiclesData(params)).catch(console.error);
    dispatch(getVehiclesTemplates()).catch(console.error);

    if (refresh.time > 0 && refresh.intervalId === undefined) {
      refresh.intervalId = setInterval(() => {
        dispatch(filterVehiclesData(params)).catch(console.error);
        dispatch(getVehiclesTemplates()).catch(console.error);
      }, refresh.time);
    }

    return () => {
      if (refresh.time > 0 && refresh.intervalId !== undefined) {
        clearInterval(refresh.intervalId as unknown as number);
      }
    };
  }, []);

  const renderHeader = () => {
    return (
      <VehicleListHeader>
        <VehiclesProcessBar translatedHeadlines={translatedHeadlines} />
        <div className="vehicle-list--header-right">
          <VehiclesFilter items={vehicles.marketplace} />
          <VehiclesSearch />
        </div>
      </VehicleListHeader>
    );
  };

  const renderVehicles = () => {
    return <VehiclesBody translatedHeadlines={translatedHeadlines} />;
  };

  return (
    <div className="component-wrapper">
      {renderHeader()}
      {renderVehicles()}
    </div>
  );
};

export default Vehicles;
